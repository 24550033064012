import { SupportedChainId } from "constants/chains";
import { NATIVE_ASSET, SupportedAssets } from "types/fusionx";
import { AddressDictionary } from "./addresses";
import { Currency } from "@fusionx-finance/sdk";
import { NATIVE_SYMBOL } from "constants/fusionx";

export const addressesTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: "0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9",
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: "0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE",
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: "0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2",
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111",
  },
  [SupportedAssets.WSTETH]: {
    [SupportedChainId.MANTLE]: "0x636D4073738C071326Aa70c9e5DB7C334bEb87bE",
  },
  // Wrapped mantle
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
  },
  // Mantle staked Ether
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: "0xcda86a272531e8640cd7f1a92c01839911b90bb0",
  },
};
/**
 * Converts an asset enum to its address
 * @param asset asset enum
 * @param chainId network
 * @returns address or native symbol
 */
export const assetToAddress = (asset: SupportedAssets, chainId: number) => {
  if (asset === NATIVE_ASSET[chainId]) return NATIVE_SYMBOL[chainId];
  return addressesTokens[asset]?.[chainId];
};

export const ADDRESS_TO_CHAINID_TO_ASSET = {
  [SupportedChainId.MANTLE]: {
    "0x09bc4e0d864854c6afb6eb9a9cdf58ac190d0df9": SupportedAssets.USDCE,
    "0x201eba5cc46d216ce6dc03f6a759e8e766e956ae": SupportedAssets.USDT,
    "0xcabae6f6ea1ecab08ad02fe02ce9a44f09aebfa2": SupportedAssets.WBTC,
    "0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111": SupportedAssets.WETH,
    "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8": SupportedAssets.WMNT,
    "0xcda86a272531e8640cd7f1a92c01839911b90bb0": SupportedAssets.METH,
  },
};

/**
 * get asset enum from address
 * @param chainId chainId of token address
 * @param address address
 * @returns asset enum or undefined
 */
export const addressToAsset = (
  chainId: number,
  address: string
): SupportedAssets => {
  if (address?.toUpperCase() === NATIVE_SYMBOL[chainId])
    return NATIVE_ASSET[chainId];
  return ADDRESS_TO_CHAINID_TO_ASSET[chainId]?.[address?.toLowerCase()];
};

/**
 * get native address from chainId
 * @param chainId chainId of token address
 * @returns native address
 */
export const getNativeAddress = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000";
    default: // otherwise, we do not expect native to be in a list
      return "";
  }
};

/**
 * Converts a token to a SupportedAssets enum
 * @param token input token
 * @returns the corresponding asset / WETH if none is found
 */
export const tokenToAsset = (
  token?: Currency | null | undefined
): SupportedAssets => {
  if (!token) return SupportedAssets.WETH;
  if (token.isNative) {
    switch (token.chainId) {
      case SupportedChainId.MANTLE:
        return SupportedAssets.MNT;
      default:
        return SupportedAssets.ETH;
    }
  }
  const _asset = addressToAsset(token.chainId, token.address);
  if (!_asset) return SupportedAssets.WETH;
  return _asset;
};

/**
 * Converts a token to a SupportedAssets enum if possible
 * @param token the currency to convert
 * @returns the corresponding enum if found, undefined otherwise
 */
export const tokenToAssetUnsafe = (
  token?: Currency | null | undefined
): SupportedAssets | undefined => {
  if (!token) return SupportedAssets.WETH;
  if (token.isNative) {
    switch (token.chainId) {
      case SupportedChainId.MANTLE:
        return SupportedAssets.MNT;
      default:
        return SupportedAssets.ETH;
    }
  }
  const _asset = addressToAsset(token.chainId, token.address);
  if (!_asset) return undefined;
  return _asset;
};
