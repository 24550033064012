import {
  formatAaveYieldToNumber,
  formatAbbreviatedNumber,
} from "utils/tableUtils/format";
import { Currency, Percent, TradeType } from "@fusionx-finance/sdk";
import _ from "lodash";
import { TEN } from "constants/fusionx";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { ReactNode } from "react";
import { ClassicTrade, TradeState } from "state/routing/types";
import { LenderConfigMap } from "types/lenderData/base";

export function getIsValidSwapQuote(
  trade: ClassicTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && tradeState === TradeState.VALID;
}

export function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b;
  } else if (a) {
    return a;
  } else if (b) {
    return b;
  }
  return undefined;
}

/**
 * Formats e.g. 0.04 to 4%
 * @param n ratio
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatRatioToPercent = (n: number, decs: number) => {
  return `${roundNumber(n * 100, decs)}%`;
};

/**
 * Formats e.g. 0.04 to 4%, adds a + if positive
 * @param n ratio
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatSignedRatioToPercent = (n: number, decs: number) => {
  if (n > 1e7) return ">100K%"; // we ceil percentages at 100k and abbreviate
  return `${n > 0 ? "+" : ""}${roundNumber(n * 100, decs)}%`; // we assume no lower values that -100%
};

/**
 * Formats e.g. 4 to 4%
 * @param n percent number
 * @param decs decimals to show
 * @returns percent as string
 */
export const formatNumberToPercent = (n: number | undefined, decs: number) => {
  return `${roundNumber(Number(n ?? 0), decs)}%`;
};

export const formatPercentagePoints = (n: number, decs: number) => {
  return `${roundNumber(n * 100, decs)}pp`;
};

export const formatNumber = (n: number, decs: number) => {
  if (n === 0) return "0";
  return roundNumber(n, decs).toLocaleString();
};

/**
 * Round using lodash
 * @param n number to round
 * @param decs decimals
 * @returns number
 */
export const roundNumber = (n: number, decs: number) => {
  return _.round(n, decs);
};

export const ltvDataToNumber = (hf?: BigNumber) => {
  if (!hf) return 0;
  return Number(formatEther(hf));
};

export const hfToNumber = (hf?: BigNumber) => {
  if (!hf) return 0;
  return Number(formatEther(hf));
};

export const healthFactorToNumber = (hf?: number) => {
  if (hf === undefined || isNaN(hf) || hf === 0) return "-";
  if (hf === Infinity || hf > 99999999999) return "\u221e";

  return `${formatAbbreviatedNumber(hf)}`;
};

export const healthFactorFusionxToNumber = (hf?: number) => {
  if (hf === undefined || isNaN(hf) || hf === 0) return "-";
  if (hf > 0) return `+${healthFactorToNumber(hf)}`;

  return `-${healthFactorToNumber(-hf)}`;
};

export function bigNumberToDecimal(
  bn?: BigNumberish,
  decimals?: number,
  displayDecimals = 4
): number {
  if (!bn || !decimals) return 0;
  return roundNumber(
    Number(
      ethers.utils.formatEther(BigNumber.from(bn).mul(TEN.pow(18 - decimals)))
    ),
    displayDecimals
  );
}

/**
 * Converts a colateral factor e.g. 0.8 to 1/(1-0.8) = 5
 * @param n amount
 * @param decs decimals to show
 * @returns leverage as string
 */
export const convertCollateralFactorToLeverage = (n: number, decs: number) => {
  return `${roundNumber(1 / (1 - n), decs)}×`;
};

/**
 * Get apr from intinsic interest and reward
 * @param apr apr number
 * @param reward reward [per year] number
 * @returns number, validated for NaN
 */
export const getApr = (apr: number, reward: number, staking: number) => {
  const aprClean = !isNaN(apr) ? apr : 0;
  const rewardClean = !isNaN(reward) ? reward : 0;
  const stakingClean = !isNaN(staking) ? staking : 0;
  if (
    aprClean + rewardClean + stakingClean < 0.01 &&
    aprClean + rewardClean + stakingClean > -0.01
  )
    return "-";
  return `${(aprClean + rewardClean + stakingClean).toFixed(2)}%`;
};

// Converters from apy to apr and reversed

const SECONDS_PER_YEAR = 31536000;

export const aprToApy = (apr: number) => {
  return (1 + apr / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1;
};

export const apyToApr = (apy: number) => {
  return ((apy + 1) ** (1 / SECONDS_PER_YEAR) - 1) * SECONDS_PER_YEAR;
};

export const formatAaveRawApyToApr = (raw: string | undefined) => {
  const apy = formatAaveYieldToNumber(raw);
  return apyToApr(apy / 100) * 100;
};

const parseToTimes = (n: number) => `${n}×`;

export const parseLeverageRange = (config: LenderConfigMap) => {
  const levs = _.uniq(
    Object.values(config).map((cfg) =>
      convertCollateralFactorToLeverage(cfg.borrowCollateralFactor, 2)
    )
  );
  if (!levs) return "";
  if (levs.length === 1) return parseToTimes(levs[0]);
  return `${_.min(levs)}-${parseToTimes(Number(_.max(levs)))}`;
};
