import { Web3ReactHooks } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { SupportedChainId } from "constants/chains";

export const COINBASE_WALLET_DEEPLINK =
  "https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fapp.fusionx.io";

export enum ConnectionType {
  UNISWAP_WALLET_V2 = "UNISWAP_WALLET_V2",
  INJECTED = "INJECTED",
  COINBASE_WALLET = "COINBASE_WALLET",
  WALLET_CONNECT = "WALLET_CONNECT",
  NETWORK = "NETWORK",
  GNOSIS_SAFE = "GNOSIS_SAFE",
}

export interface Connection {
  getName(): string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  getIcon?(isDarkMode: boolean): string;
  shouldDisplay(): boolean;
  overrideActivate?: (chainId?: SupportedChainId) => boolean;
  isNew?: boolean;
}
