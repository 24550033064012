import { useState } from "react";
import { ImageProps } from "rebass";
import styled from "styled-components";

const BAD_SRCS: { [tokenAddress: string]: true } = {};

interface LogoProps extends Pick<ImageProps, "style" | "alt" | "className"> {
  srcs: string[];
  symbol?: string;
  size?: string;
}

const MissingImageLogo = styled.div<{ size?: string }>`
  --size: ${({ size }) => size};
  border-radius: 100px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ theme }) => theme.backgroundInteractive};
  font-size: calc(var(--size) / 3);
  font-weight: 500;
  height: ${({ size }) => size ?? "24px"};
  line-height: ${({ size }) => size ?? "24px"};
  text-align: center;
  width: ${({ size }) => size ?? "24px"};
`;

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
export default function Logo({
  srcs,
  alt,
  style,
  size,
  symbol,
  ...rest
}: LogoProps) {
  const [, refresh] = useState<number>(0);

  const src: string | undefined = srcs.find((src) => !BAD_SRCS[src]);

  if (src) {
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        style={style}
        onError={() => {
          if (src) BAD_SRCS[src] = true;
          refresh((i) => i + 1);
        }}
      />
    );
  }

  return (
    <MissingImageLogo size={size}>
      {/* use only first 3 characters of Symbol for design reasons */}
      {symbol?.toUpperCase().replace("$", "").replace(/\s+/g, "").slice(0, 3)}
    </MissingImageLogo>
  );
}

export { default as FusionXLogo } from "./AppLogo";
export { default as FusionXLogoWithText } from "./AppLogoWithText";
