import { Interface } from "@ethersproject/abi";
import {
  Currency,
  CurrencyAmount,
  NativeCurrency,
  Token,
} from "@fusionx-finance/sdk";
import ERC20ABI from "abis/erc20.json";
import { Erc20Interface } from "abis/types/Erc20";
import { useMultipleContractSingleData } from "lib/hooks/multicall";
import { useMemo } from "react";
import { isAddress } from "utils";
import { useNativeBalance } from "state/globalNetwork/hooks";
import useNativeCurrency from "./useNativeCurrency";

const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface;
const tokenBalancesGasRequirement = { gasRequired: 185_000 };

const CACHED_BALANCES: { [address: string]: bigint } = {};

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () =>
      tokens?.filter(
        (t?: Token): t is Token => isAddress(t?.address) !== false
      ) ?? [],
    [tokens]
  );
  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map((vt) => vt.address),
    [validatedTokens]
  );

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20Interface,
    "balanceOf",
    useMemo(() => [address], [address]),
    tokenBalancesGasRequirement
  );

  const anyLoading: boolean = useMemo(
    () => balances.some((callState) => callState.loading),
    [balances]
  );

  return useMemo(
    () => [
      address && validatedTokens.length > 0
        ? validatedTokens.reduce<{
            [tokenAddress: string]: CurrencyAmount<Token> | undefined;
          }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0];
            const amount = value ? value.toString() : undefined;
            if (amount) {
              CACHED_BALANCES[token.address] = amount;
              memo[token.address] = CurrencyAmount.fromRawAmount(token, amount);
            } else {
              if (CACHED_BALANCES[token.address])
                memo[token.address] = CurrencyAmount.fromRawAmount(
                  token,
                  CACHED_BALANCES[token.address]
                );
            }
            return memo;
          }, {})
        : {},
      anyLoading,
    ],
    [address, validatedTokens, anyLoading, balances]
  );
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0];
}

// get the balance for a single token/account combo
export function useTokenBalance(
  account?: string,
  token?: Token
): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(
    account,
    useMemo(() => [token], [token])
  );
  if (!token) return undefined;
  return tokenBalances[token.address];
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount<Currency> | undefined)[] {
  const tokens = useMemo(
    () =>
      currencies?.filter(
        (currency): currency is Token => currency?.isToken ?? false
      ) ?? [],
    [currencies]
  );

  const tokenBalances = useTokenBalances(account, tokens);
  const rawNativeBalance = useNativeBalance();
  const eth = useNativeCurrency();
  const ethBalance =
    rawNativeBalance === undefined
      ? undefined
      : CurrencyAmount.fromRawAmount(eth, rawNativeBalance); // useNativeCurrencyBalances(useMemo(() => (containsETH ? [account] : []), [containsETH, account]))

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined;
        if (currency.isToken) return tokenBalances[currency.address];
        if (currency.isNative) return ethBalance; // ethBalance[account]
        return undefined;
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  );
}

export default function useCurrencyBalance(
  account?: string,
  currency?: Currency
): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(
    account,
    useMemo(() => [currency], [currency])
  )[0];
}

export function useCurrencyBalanceString(account: string): string {
  return useGetNativeBalance()?.toSignificant(3) ?? "";
}

export function useGetNativeBalance():
  | CurrencyAmount<NativeCurrency | Token>
  | undefined {
  const rawNativeBalance = useNativeBalance();
  const eth = useNativeCurrency();
  return rawNativeBalance === undefined
    ? undefined
    : CurrencyAmount.fromRawAmount(eth, rawNativeBalance);
}
