import { Route as OnChainRoute } from "@fusionx-finance/v3-sdk";
import {
  Currency,
  CurrencyAmount,
  Token,
  TradeType,
} from "@fusionx-finance/sdk";
import { SupportedChainId } from "constants/chains";
import { InterfaceTrade } from "state/routing/types";

export type SerializedBigNumber = string;
export type SerializedNumber = string;

export interface TokenMeta {
  symbol: string;
  decimals: number;
  name: string;
}

export enum SupportedAssets {
  UNI = "UNI",
  WETH = "WETH",
  DAI = "DAI",
  LINK = "LINK",
  USDCE = "USDC.E",
  WBTC = "WBTC",
  USDT = "USDT",
  AAVE = "AAVE",
  EURS = "EURS",
  WMATIC = "WMATIC",
  AGEUR = "AGEUR",
  BAL = "BAL",
  CRV = "CRV",
  DPI = "DPI",
  GHST = "GHST",
  JEUR = "JEUR",
  SUSHI = "SUSHI",
  ETH = "ETH",
  MATIC = "MATIC",
  COMP = "COMP",
  BAT = "BAT",
  FEI = "FEI",
  MKR = "MKR",
  ZRX = "ZRX",
  YFI = "YFI",
  WBTC2 = "WBTC2",
  USDP = "USDP",
  TUSD = "TUSD",
  SAI = "SAI",
  REP = "REP",
  MATICX = "MATICX",
  MAI = "MAI",
  STMATIC = "STMATIC",
  WSTETH = "WSTETH",
  GDAI = "GDAI",
  VGHST = "VGHST",
  GHO = "GHO",
  USD = "USD",
  EUR = "EUR",
  // venus
  ADA = "ADA",
  BCH = "BCH",
  BETH = "BETH",
  BTCB = "BTCB",
  BUSD = "BUSD",
  CAKE = "CAKE",
  CAN = "CAN",
  DOGE = "DOGE",
  DOT = "DOT",
  FIL = "FIL",
  LTC = "LTC",
  LUNA = "LUNA",
  SXP = "SXP",
  TRX = "TRX",
  TRXOLD = "TRXOLD",
  TUSDOLD = "TUSDOLD",
  UST = "UST",
  VAI = "VAI",
  VRT = "VRT",
  WBETH = "WBETH",
  XRP = "XRP",
  XVS = "XVS",
  BNB = "BNB",
  WBNB = "WBNB",
  // the binance pegged stables have 18 decimals and are therefore treated differently
  BUSDC = "BUSDC",
  BUSDT = "BUSDT",
  WMNT = "WMNT",
  MNT = "MNT",
  USDC = "USDC",
  // mantle
  METH = "METH",
  USDY = "USDY",
}

export const toErc20Asset = (
  asset?: SupportedAssets | string | undefined
): SupportedAssets => {
  if (!asset) return SupportedAssets.USDCE;
  if (asset === SupportedAssets.MATIC) return SupportedAssets.WMATIC;
  if (asset === SupportedAssets.ETH) return SupportedAssets.WETH;
  if (asset === SupportedAssets.MNT) return SupportedAssets.WMNT;
  return asset.toUpperCase() as SupportedAssets;
};

export const hasChainLinkFeed = [
  SupportedAssets.WBTC,
  SupportedAssets.WBTC2,
  SupportedAssets.WETH,
  SupportedAssets.ETH,
  SupportedAssets.DAI,
  SupportedAssets.MATIC,
  SupportedAssets.LINK,
  SupportedAssets.USDCE,
];

export const compoundAssets = [
  SupportedAssets.ETH,
  SupportedAssets.WBTC,
  SupportedAssets.USDCE,
  SupportedAssets.USDT,
  SupportedAssets.DAI,
  SupportedAssets.UNI,
  SupportedAssets.COMP,
];

export const aaveAssets = [
  SupportedAssets.WETH,
  SupportedAssets.DAI,
  SupportedAssets.LINK,
  SupportedAssets.USDCE,
  SupportedAssets.AAVE,
  SupportedAssets.GHO,
];

export enum Field {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export enum PositionSides {
  Collateral = "Collateral",
  Debt = "Borrow",
}

export enum MarginTradeType {
  Open = "Open",
  Close = "Close",
  CollateralSwap = "CollateralSwap",
  DebtSwap = "DebtSwap",
}

export enum QuickActionType {
  Deposit = "Deposit",
  Withdraw = "Withdraw",
  Borrow = "Borrow",
  Repay = "Repay",
  Close = "close",
}

export enum AaveInterestMode {
  NONE = 0,
  STABLE = 1,
  VARIABLE = 2,
}

export interface MarginTradeState {
  [Field.INPUT]: { currencyId: string | undefined };
  [Field.OUTPUT]: { currencyId: string | undefined };
  typedValue: string;
  independentField: Field;
  marginTradeType: MarginTradeType;
  quickActionType: QuickActionType;
  baseCurrency: SupportedAssets;
  recipient?: string | null;
}

export enum FeeAmount {
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
}

const FEE_SIZE = 3;

export function encodePath(path: string[], fees: number[]): string {
  if (path.length !== fees.length + 1) {
    throw new Error("path/fee lengths do not match");
  }

  let encoded = "0x";
  for (let i = 0; i < fees.length; i++) {
    // 20 byte encoding of the address
    encoded += path[i].slice(2);
    // 3 byte encoding of the fee
    encoded += fees[i].toString(16).padStart(2 * FEE_SIZE, "0");
  }
  // encode the final token
  encoded += path[path.length - 1].slice(2);

  return encoded.toLowerCase();
}

export type MappedCurrencyAmounts = {
  [field in Field]: CurrencyAmount<Currency> | undefined | null;
};

export type MappedCurrencies = {
  [field in Field]: Currency | undefined | null;
};

export enum TradeAggregator {
  Fusionx = "fusionx",
  OneInch = "1inch",
  Paraswap = "Paraswap",
}

export const EXTERNAL_TRADE_AGGREGATORS_PER_CHAIN = {
  [SupportedChainId.MANTLE]: [],
};

export interface GenericTrade {
  tradeType: TradeType;
  aggregator: TradeAggregator;
  inputAmount: CurrencyAmount<Currency>;
  outputAmount: CurrencyAmount<Currency>;
  uniswapRoute?: OnChainRoute<Currency, Currency>;
  uniswapTrade?: InterfaceTrade;
  calldata?: string;
  target?: string;
  stringified: string;
}

export interface MappedSwapAmounts {
  [asset: string]: { amount: number; type: AaveInterestMode };
}

export interface DirectionedSwapAmounts {
  [field: string]: { asset: string; amount: number; type: AaveInterestMode };
}

export const STAKER_TOKENS = [
  SupportedAssets.WSTETH,
  SupportedAssets.STMATIC,
  SupportedAssets.MATICX,
];
export const STABLECOINS_USD = [
  SupportedAssets.USDCE,
  SupportedAssets.USDC,
  SupportedAssets.USDT,
  SupportedAssets.DAI,
  SupportedAssets.USDP,
];
export const STABLECOINS_EUR = [
  SupportedAssets.AGEUR,
  SupportedAssets.EURS,
  SupportedAssets.JEUR,
];
export const BLUE_CHIP = [
  SupportedAssets.WBTC,
  SupportedAssets.WBTC2,
  SupportedAssets.WETH,
  SupportedAssets.ETH,
  SupportedAssets.MATIC,
  SupportedAssets.WMATIC,
  SupportedAssets.USDCE,
  SupportedAssets.USDC,
  SupportedAssets.USDT,
];

export const E_MODE_ACTIVE = true;

/** Native asset symbols per chain */
export const NATIVE_ASSET: { [chainId: number]: SupportedAssets } = {
  [SupportedChainId.MANTLE]: SupportedAssets.MNT,
};

/** Native asset symbols per chain */
export const WRAPPED_NATIVE_ASSET: { [chainId: number]: SupportedAssets } = {
  [SupportedChainId.MANTLE]: SupportedAssets.WMNT,
};

/**
 * Convert a string (asset symbol) to a Supported asset, considering also naming conflicts
 * @param a string
 * @returns SupportedAsset
 */
export const getFallbackAsset = (a: string): SupportedAssets => {
  const upperA = a?.toUpperCase();
  if (upperA === "MIMATIC") return SupportedAssets.MAI;
  if (upperA === "ETH") return SupportedAssets.WETH;
  if (upperA === "MNT") return SupportedAssets.WMNT;
  return upperA as SupportedAssets;
};

/**
 * Convert a string (asset symbol) to a Supported asset, considering also naming conflicts
 * @param a string
 * @returns SupportedAsset
 */
export const toOracleKey = (a: string): SupportedAssets => {
  const upperA = a?.toUpperCase();
  if (upperA === "MIMATIC") return SupportedAssets.MAI;
  if (upperA === "ETH") return SupportedAssets.WETH;
  if (upperA === "MNT") return SupportedAssets.WMNT;
  if (upperA === "MATIC") return SupportedAssets.WMATIC;
  if (upperA === "USDC.E") return SupportedAssets.USDC;
  if (upperA === "BUSDC") return SupportedAssets.USDC;
  if (upperA === "BTCB") return SupportedAssets.WBTC;
  if (upperA === "BUSDT") return SupportedAssets.USDT;
  return upperA as SupportedAssets;
};

export const DEX_PROTOCOL_TO_NAME: { [aggregator: string]: string } = {
  // [DexProtocol.UNISWAP_V3]: "Uniswap V3",
  // [DexProtocol.QUICKSWAP_V3]: "Quickswap V3",
  // [DexProtocol.RETRO]: "Retro",
  // [DexProtocol.QUICKSWAP_V2]: "Quickswap V2",
  // [DexProtocol.SUSHISWAP_V3]: "Sushiswap V3",
  // [DexProtocol.SUSHISWAP_V2]: "Sushiswap V2",
  // [DexProtocol.DFYN]: "Dfyn",
  // [DexProtocol.FUSIONX_V2]: "FusionX V2",
  // [DexProtocol.MERCHANT_MOE]: "Merchant Moe",
  // [DexProtocol.FUSIONX_V3]: "FusionX V3",
  // [DexProtocol.IZUMI]: "Izumi",
  // [DexProtocol.AGNI]: "Agni",
  // [DexProtocol.WOO_FI]: "WOOFi",
  // [DexProtocol.SWAPSICLE]: "Swapsicle",
  // [DexProtocol.BUTTER]: "Butter",
  // [DexProtocol.CLEOPATRA]: "Cleopatra",
  // [DexProtocol.VELOCIMETER_STABLE]: "Velocimeter Stable",
  // [DexProtocol.VELOCIMETER_VOLATILE]: "Velocimeter Volatile",
  // [DexProtocol.CLEOPATRA_V1_STABLE]: "Cleopatra V1 Stable",
  // [DexProtocol.CLEOPATRA_V1_VOLATILE]: "Cleopatra V1 Volatile",
  // [DexProtocol.STRATUM_STABLE]: "Stratum Stable",
  // [DexProtocol.STRATUM_VOLATILE]: "Stratum Volatile",
};

// map aggregator to its pool id
export const DEX_PROTOCOL_TO_POOL_ID: {
  [aggregator: string]: { [chainId: number]: number };
} = {
  // [DexProtocol.FUSIONX_V2]: {
  //   [SupportedChainId.MANTLE]: 50,
  // },
  // [DexProtocol.FUSIONX_V3]: {
  //   [SupportedChainId.MANTLE]: 0,
  // },
};

export interface DexscreenerPair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: {
    address: string;
    name: string;
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceNative: string;
  priceUsd?: string;
  txns: {
    m5: {
      buys: number;
      sells: number;
    };
    h1: {
      buys: number;
      sells: number;
    };
    h6: {
      buys: number;
      sells: number;
    };
    h24: {
      buys: number;
      sells: number;
    };
  };
  volume: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  priceChange: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  liquidity?: {
    usd?: number;
    base: number;
    quote: number;
  };
  fdv?: number;
  pairCreatedAt?: number;
}

export interface DexscreenerPairReduced {
  tokenAddress: string;
  pairAddress: string;
  priceUsd?: number;
  userBalance?: number;
  userBalanceUsd?: number;
  assetId: string;
  assetName: string;
}

export const chainIdToDexscreenerChainId = {
  [SupportedChainId.MANTLE]: "mantle",
};

export const defaultDexscreenerPairAddress = {
  [SupportedChainId.MANTLE]: "0x54169896d28dec0FFABE3B16f90f71323774949f",
};

export enum Quotability {
  OFF_CHAIN = "OFF_CHAIN",
  ON_CHAIN = "ON_CHAIN",
}
