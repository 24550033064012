import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SupportedAssets } from "types/fusionx";
import { apyToApr } from "utils/fusionx/generalFormatters";

const FeedData = {
  [SupportedAssets.WSTETH]:
    "https://api.thegraph.com/subgraphs/name/lidofinance/lido",
  [SupportedAssets.STMATIC]: "https://polygon.lido.fi/api/stats",
  [SupportedAssets.MATICX]: "https://universe.staderlabs.com/polygon/apy",
  [SupportedAssets.METH]:
    "https://meth.mantle.xyz/api/stats/apy?start_dt=2023-12-01&end_dt=2023-12-24",
};

const WSTATH_SUBGRAPH_QUERY = `
  query WSTETH_REWARDS($first: Int) {
    totalRewards(
      first: $first
      orderBy: blockTime orderDirection: desc
    ) {
      apr
    }
  }
`;

interface ApiResponseData {
  data: {};
}

interface EmptyQueryParams {}

const WORKER_ENDPOINT = "https://staking.fusionx.io/get-staking-yields";

export const fetchStakingYields: AsyncThunk<
  ApiResponseData,
  EmptyQueryParams,
  any
> = createAsyncThunk<ApiResponseData, EmptyQueryParams>(
  "oracles/fetchStakingYields",
  async () => {
    try {
      const response = await fetch(WORKER_ENDPOINT);
      // should return the correct mapping with asset keys
      const data = await response.json();
      return { data };
    } catch (e: any) {
      console.log("eror fetching staking data from worker", e);
    }
    // If we cannot fetch the data from our endpoint, we try it here
    const requestBody = {
      query: WSTATH_SUBGRAPH_QUERY,
      variables: { first: 1 },
    };

    let wstethData: any = undefined;
    let stmaticData: any = undefined;
    let maticXdata: any = undefined;
    let methData: any = undefined;
    try {
      const response = await fetch(FeedData[SupportedAssets.WSTETH], {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const responseJson = await response.json();
      wstethData = Number(responseJson.data.totalRewards[0].apr) * 100;
    } catch (e) {
      console.log("Error fetching wsteth staking yield:", e);
    }
    try {
      stmaticData = 4.33;
      // await fetch(FeedData[SupportedAssets.STMATIC], {
      //   method: 'GET',
      // })
    } catch (e) {
      console.log("Error fetching stmatic staking yield:", e);
    }
    try {
      methData = apyToApr(0.072) * 100;
    } catch (e) {
      console.log("Error fetching stmatic staking yield:", e);
    }
    try {
      const response = await axios.get(FeedData[SupportedAssets.MATICX]);
      maticXdata = Number(response.data.value) * 0.9; // apply 10% fee
    } catch (e) {
      console.log("Error fetching maticx staking yield:", e);
    }

    return {
      data: {
        [SupportedAssets.STMATIC]: stmaticData,
        [SupportedAssets.WSTETH]: wstethData,
        [SupportedAssets.MATICX]: maticXdata,
        [SupportedAssets.METH]: methData,
      },
    };
  }
);
