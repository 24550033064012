import { createAction } from "@reduxjs/toolkit";

export const resetState = createAction<void>("fusionx/resetState");
export const setFusionxAccount = createAction<{
  chainId: number;
  index: number;
}>("fusionx/setFusionxAccount");
export const setFusionxAccountMetaLoading = createAction<{
  chainId: number;
  state: boolean;
}>("fusionx/setFusionxAccountMetaLoading");
export const setToLoading = createAction("fusionx/setToLoading");

export const flushAccount = createAction("fusionx/flushAccount");
