import { createReducer } from "@reduxjs/toolkit";
import { Field, SupportedAssets } from "types/fusionx";
import {
  replaceMoneyMarketState,
  selectMoneyMarketCurrency,
  setLenderAsset,
  setPositionType,
  setRecipient,
  setTxHash,
  switchCurrencies,
  typeInput,
} from "./actions";
import { PositionType } from "constants/fusionx";

export interface MoneyMarketState {
  readonly independentField: Field;
  readonly typedValue: string;
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined | null;
  };
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined | null;
  };
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null;
  lenderAsset: SupportedAssets | null;
  positionType: PositionType | null;
  txHash: string;
}

const initialState: MoneyMarketState = {
  independentField: Field.OUTPUT,
  typedValue: "",
  [Field.INPUT]: {
    currencyId: undefined,
  },
  [Field.OUTPUT]: {
    currencyId: undefined,
  },
  recipient: null,
  lenderAsset: null,
  positionType: null,
  txHash: "",
};

export default createReducer<MoneyMarketState>(initialState, (builder) =>
  builder
    .addCase(
      replaceMoneyMarketState,
      (
        state,
        {
          payload: {
            typedValue,
            recipient,
            field,
            inputCurrencyId,
            outputCurrencyId,
            lenderAsset,
            positionType,
            txHash,
          },
        }
      ) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId ?? null,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId ?? null,
          },
          independentField: field,
          typedValue,
          recipient,
          lenderAsset,
          positionType,
          txHash,
        };
      }
    )
    .addCase(
      selectMoneyMarketCurrency,
      (state, { payload: { currencyId, field } }) => {
        const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
        if (currencyId === state[otherField].currencyId) {
          // the case where we have to swap the order
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [field]: { currencyId },
            [otherField]: { currencyId: state[field].currencyId },
          };
        } else {
          // the normal case
          return {
            ...state,
            [field]: { currencyId },
          };
        }
      }
    )
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      };
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient;
    })
    .addCase(setLenderAsset, (state, { payload: { lenderAsset } }) => {
      state.lenderAsset = lenderAsset;
    })
    .addCase(setTxHash, (state, { payload: { txHash } }) => {
      state.txHash = txHash;
    })
    .addCase(setPositionType, (state, { payload: { positionType } }) => {
      state.positionType = positionType;
    })
);
