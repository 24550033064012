import { createMulticall, ListenerOptions } from '@uniswap/redux-multicall'
import { useInterfaceMulticall } from 'hooks/useContract'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useMemo } from 'react'
import { combineReducers, createStore } from 'redux'
import { useChainId } from 'state/globalNetwork/hooks'
import { getBlocksPerFetchForChainId } from './utils'

const multicallAlt = createMulticall()
const reducer = combineReducers({ [multicallAlt.reducerPath]: multicallAlt.reducer })
export const store = createStore(reducer)

export default multicallAlt

export function MulticallAltUpdater() {
  const chainId = useChainId()
  const latestBlockNumber = useBlockNumber()
  const contract = useInterfaceMulticall(3)
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  )

  return (
    <multicallAlt.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
    />
  )
}
