import {
  Currency,
  Ether,
  NativeCurrency,
  Token,
  WETH9,
} from "@fusionx-finance/sdk";
import invariant from "tiny-invariant";

import { UNI_ADDRESS } from "./addresses";
import { SupportedChainId } from "./chains";
import { addressesTokens } from "hooks/fusionx/addressesTokens";
import { SupportedAssets } from "types/fusionx";

export const NATIVE_CHAIN_ID = "NATIVE";

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18;

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  ...(WETH9 as { [chainId: number]: Token }),
  [SupportedChainId.MANTLE]: new Token(
    SupportedChainId.MANTLE,
    "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
    18,
    "WMNT",
    "Wrapped MNT"
  ),
};

function isMantle(chainId: number): chainId is SupportedChainId.MANTLE {
  return chainId === SupportedChainId.MANTLE;
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    if (wrapped) return wrapped;
    throw new Error("Unsupported chain ID");
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } =
    {};

  public static onChain(chainId: number): ExtendedEther {
    return (
      this._cachedExtendedEther[chainId] ??
      (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
    );
  }
}

class MantleNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  get wrapped(): Token {
    if (!isMantle(this.chainId)) throw new Error("Not mnt");
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    invariant(wrapped instanceof Token);
    return wrapped;
  }

  public constructor(chainId: number) {
    if (!isMantle(chainId)) throw new Error("Not mnt");
    super(chainId, 18, "MNT", "Mantle");
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {};
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId];
  let nativeCurrency: NativeCurrency | Token;
  if (chainId === SupportedChainId.MANTLE) {
    nativeCurrency = new MantleNativeCurrency(chainId);
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId);
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency);
}

export const USDC_ON = (chainId: number) => {
  return new Token(
    chainId,
    addressesTokens[SupportedAssets.USDCE][chainId],
    6,
    "USDC.e",
    "USD Coin"
  );
};

export const USDT_ON = (chainId: number) => {
  return new Token(
    chainId,
    addressesTokens[SupportedAssets.USDT][chainId],
    6,
    "USDT",
    "USD Tether"
  );
};
