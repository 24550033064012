// This file is lazy-loaded, so the import of ChainId is intentional.
// eslint-disable-next-line no-restricted-imports
import { ChainId } from "@fusionx-finance/sdk";
import { SupportedChainId } from "constants/chains";

export function toSupportedChainId(
  chainId: ChainId
): SupportedChainId | undefined {
  const numericChainId: number = chainId;
  if (SupportedChainId[numericChainId]) return numericChainId;
  return undefined;
}
export function isSupportedChainId(chainId: ChainId | undefined): boolean {
  if (chainId === undefined) return false;
  return toSupportedChainId(chainId) !== undefined;
}
