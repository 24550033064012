import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import multicall, { Call, multicallSecondary } from "utils/multicall";
import UniswapInterfaceMulticallJson from "abis/uniswap/interface-multicall.json";
import ERC20 from "abis/erc20.json";
import { MULTICALL_ADDRESS } from "constants/addresses";
import { getMulticallV2Address } from "hooks/fusionx/addresses";
import { getAllLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";

export interface TimestampResponse {
  timestamp: string;
  chainId: number;
}

export interface TimstampQueryParams {
  chainId: number;
}

const MultiABI = [
  {
    inputs: [],
    name: "getCurrentBlockTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "getEthBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getBlockNumber",
    outputs: [
      {
        internalType: "uint256",
        name: "blockNumber",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const fetchTimestamp: AsyncThunk<
  TimestampResponse,
  TimstampQueryParams,
  any
> = createAsyncThunk<TimestampResponse, TimstampQueryParams>(
  "globalNetwork/fetchTimestamp",

  async ({ chainId }) => {
    const calls: Call[] = [
      {
        address: MULTICALL_ADDRESS[chainId],
        name: "getCurrentBlockTimestamp",
        params: [],
      },
    ];

    const multicallResult = await multicall(
      chainId,
      UniswapInterfaceMulticallJson,
      calls
    );
    const result = multicallResult[0][0].toString();
    return {
      timestamp: result,
      chainId,
    };
  }
);

export interface TimestampAndBalanceResponse {
  timestamp: string;
  nativeBalance: string | undefined;
  balances: { [asset: string]: string };
  blockNumber: number;
  chainId: number;
}

export interface TimestampAndBalanceQueryParams {
  chainId: number;
  account?: string;
}

export const fetchBlockDataAndBalances: AsyncThunk<
  TimestampAndBalanceResponse,
  TimestampAndBalanceQueryParams,
  any
> = createAsyncThunk<
  TimestampAndBalanceResponse,
  TimestampAndBalanceQueryParams
>(
  "globalNetwork/fetchBlockDataAndBalances",

  async ({ chainId, account }) => {
    if (account) {
      const callsBase: Call[] = [
        {
          address: MULTICALL_ADDRESS[chainId],
          name: "getCurrentBlockTimestamp",
          params: [],
        },
        {
          address: MULTICALL_ADDRESS[chainId],
          name: "getEthBalance",
          params: [account],
        },
        {
          address: getMulticallV2Address(chainId),
          name: "getBlockNumber",
          params: [],
        },
      ];

      const tokenAddresses = getAllLenderTokenAddresses(chainId);

      const names = Object.keys(tokenAddresses);
      const calls: Call[] = names.map((tk) => {
        return {
          address: tokenAddresses[tk],
          name: "balanceOf",
          params: [account],
        };
      });

      const multicallResult = await multicall(
        chainId,
        [...MultiABI, ...ERC20],
        [...calls, ...callsBase]
      );

      const base = multicallResult.slice(calls.length, multicallResult.length);
      const balances = multicallResult.slice(0, calls.length);
      const result: any = Object.assign(
        {},
        ...balances.map((entry: any, index) => {
          return {
            [names[index]]: entry.toString(),
          };
        })
      );
      return {
        timestamp: base[0][0].toString(),
        nativeBalance: base[1].balance.toString(),
        blockNumber: Number(base[2].blockNumber.toString()),
        balances: result,
        chainId,
      };
    }

    const calls: Call[] = [
      {
        address: MULTICALL_ADDRESS[chainId],
        name: "getCurrentBlockTimestamp",
        params: [],
      },
      {
        address: getMulticallV2Address(chainId),
        name: "getBlockNumber",
        params: [],
      },
    ];

    const multicallResult = await multicallSecondary(chainId, MultiABI, calls);

    return {
      timestamp: multicallResult[0][0].toString(),
      blockNumber: Number(multicallResult[1].blockNumber.toString()),
      nativeBalance: undefined,
      balances: {},
      chainId,
    };
  }
);
