import { deepCopy } from "@ethersproject/properties";
// This is the only file which should instantiate new Providers.
// eslint-disable-next-line no-restricted-imports
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { isPlain } from "@reduxjs/toolkit";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "./chains";
import { FALLBACK_URLS, RPC_URLS } from "./networks";
import ms from "ms.macro";

export const AVERAGE_L1_BLOCK_TIME = ms`12s`;

export class AppJsonRpcProvider extends StaticJsonRpcProvider {
  private _blockCache = new Map<string, Promise<any>>();
  get blockCache() {
    // If the blockCache has not yet been initialized this block, do so by
    // setting a listener to clear it on the next block.
    if (!this._blockCache.size) {
      this.once("block", () => this._blockCache.clear());
    }
    return this._blockCache;
  }

  constructor(chainId: SupportedChainId, url: string) {
    // Including networkish allows ethers to skip the initial detectNetwork call.
    super(
      url,
      /* networkish= */ { chainId, name: CHAIN_IDS_TO_NAMES[chainId] }
    );

    // NB: Third-party providers (eg MetaMask) will have their own polling intervals,
    // which should be left as-is to allow operations (eg transaction confirmation) to resolve faster.
    // Network providers (eg AppJsonRpcProvider) need to update less frequently to be considered responsive.
    this.pollingInterval = AVERAGE_L1_BLOCK_TIME;
  }

  send(method: string, params: Array<any>): Promise<any> {
    // Only cache eth_call's.
    if (method !== "eth_call") return super.send(method, params);

    // Only cache if params are serializable.
    if (!isPlain(params)) return super.send(method, params);

    const key = `call:${JSON.stringify(params)}`;
    const cached = this.blockCache.get(key);
    if (cached) {
      this.emit("debug", {
        action: "request",
        request: deepCopy({ method, params, id: "cache" }),
        provider: this,
      });
      return cached;
    }

    const result = super.send(method, params);
    this.blockCache.set(key, result);
    return result;
  }
}

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: { [chainId: number]: StaticJsonRpcProvider } = {
  [SupportedChainId.MANTLE]: new AppJsonRpcProvider(
    SupportedChainId.MANTLE,
    RPC_URLS[SupportedChainId.MANTLE][0]
  ),
};

// get second provider if exists
export const getSecondaryProvider = (chainId: number) => {
  let url = RPC_URLS[chainId]?.[1];
  if (url) return (url = RPC_URLS[chainId]?.[0]);
  return new AppJsonRpcProvider(chainId, url);
};

// get provider if exists
export const getProviderByIndex = (chainId: number, id: number) => {
  let url = RPC_URLS[chainId]?.[id];
  if (!url) return getProviderByIndex(chainId, id - 1);
  return new AppJsonRpcProvider(chainId, url);
};

// get provider if exists
export const getFallbackProviderByIndex = (chainId: number, id: number) => {
  let url = FALLBACK_URLS[chainId]?.[id];
  if (!url) return getFallbackProviderByIndex(chainId, id - 1);
  return new AppJsonRpcProvider(chainId, url);
};
