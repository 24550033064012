import { SupportedChainId } from "constants/chains";
import { SupportedAssets } from "types/fusionx";
import { AddressDictionary } from "./addresses";

export const addressesLendleCore: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.MANTLE]: "0x870c9692Ab04944C86ec6FEeF63F261226506EfC",
  },
  PoolProxy: {
    [SupportedChainId.MANTLE]: "0xCFa5aE7c2CE8Fadc6426C1ff872cA45378Fb7cF3",
  },
  PoolDataProvider: {
    [SupportedChainId.MANTLE]: "0x552b9e4bae485C4B7F540777d7D25614CdB84773",
  },
  IncentivesController: {
    [SupportedChainId.MANTLE]: "0x79e2fd1c484EB9EE45001A98Ce31F28918F27C41",
  },
  MultiFeeDistribution: {
    [SupportedChainId.MANTLE]: "0x5C75A733656c3E42E44AFFf1aCa1913611F49230",
  },
};

export const addressesLendleLTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: "0xf36afb467d1f05541d998bbbcd5f7167d67bd8fc",
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: "0xe71cbaaa6b093fce66211e6f218780685077d8b5",
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: "0x44cccbbd7a5a9e2202076ea80c185da0058f1715",
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: "0x787cb0d29194f0faca73884c383cf4d2501bb874",
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: "0x683696523512636b46a826a7e3d1b0658e8e2e1c",
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: "0x0e927aa52a38783c1fd5dfa5c8873cbdbd01d2ca",
  },
};

export const addressesLendleVTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: "0x334a542b51212b8bcd6f96efd718d55a9b7d1c35",
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: "0xac3c14071c80819113df501e1ab767be910d5e5a",
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: "0x42f9f9202d5f4412148662cf3bc68d704c8e354f",
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: "0x5df9a4be4f9d717b2bfece9ec350dcf4cbcb91d8",
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: "0x18d3e4f9951fedcddd806538857ebed2f5f423b7",
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: "0xd739fb7a3b652306d00f92b20439afc637650254",
  },
};

export const addressesLendleSTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: "0xee8d412a4ef6613c08889f9cd1fd7d4a065f9a8b",
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: "0xea8bd20f6c5424ab4acf132c70b6c7355e11f62e",
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: "0x1817cde5cd6423c3b87039e1cb000bb2ac4e05c7",
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: "0x0ca5e3cd5f3273b066422291edda3768451fbb68",
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: "0xafefc53be7e32c7510f054abb1ec5e44c03fccab",
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: "0x614110493ceae1171532eb635242e4ca71ccbba2",
  },
};
