import { Connector } from "@web3-react/types";
import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";
import {
  getIsCoinbaseWallet,
  getIsInjected,
  getIsMetaMaskWallet,
} from "connection/utils";
import usePrevious from "hooks/usePrevious";
import { useCallback, useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { updateConnectionError } from "state/connection/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { updateSelectedWallet } from "state/user/reducer";
import { useConnectedWallets } from "state/wallets/hooks";
import styled from "styled-components";
import { isMobile } from "utils/userAgent";
import { ReactComponent as Close } from "assets/images/x.svg";
import { ExternalLink, ThemedText } from "theme";
import AccountDetails from "../AccountDetails";
import { LightCard } from "../Card";
import {
  CoinbaseWalletOption,
  OpenCoinbaseWalletOption,
} from "./CoinbaseWalletOption";
import {
  InjectedOption,
  InstallMetaMaskOption,
  MetaMaskOption,
} from "./InjectedOption";
import PendingView from "./PendingView";
import { WalletConnectOption } from "./WalletConnectOption";
import { getConnection, getConnectionName } from "connection";
import { UniswapOption } from "./UniswapOption";
import { trackWalletConnected } from "utils/analytics";
import { useWeb3ReactWrapped } from "hooks/web3";
import { MenuItem } from "@chakra-ui/react";

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.accent : "inherit"};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const ContentWrapper = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`;

const UpperSection = styled.div`
  white-space: wrap;
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`;

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

const WalletWrapper = styled.div`
  border-radius: 16px;
  width: 360px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  top: 60px;
  right: 70px;
  background-color: ${({ theme }) => theme.backgroundTertiary};
  border: ${({ theme }) => `1px solid ${theme.container_border}`};
  overflow: auto;
`;

export default function WalletMenu({
  pendingTransactions,
  confirmedTransactions,
}: {
  pendingTransactions: string[]; // hashes of pending
  confirmedTransactions: string[]; // hashes of confirmed
}) {
  const dispatch = useAppDispatch();
  const { connector, account, chainId } = useWeb3ReactWrapped();
  const previousAccount = usePrevious(account);

  const [connectedWallets, addWalletToConnectedWallets] = useConnectedWallets();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [lastActiveWalletAddress, setLastActiveWalletAddress] = useState<
    string | undefined
  >(account);

  const [userJustConnected, setUserJustConnected] = useState(false);

  const [pendingConnector, setPendingConnector] = useState<
    Connector | undefined
  >();
  const pendingError = useAppSelector((state) =>
    pendingConnector
      ? state.connection.errorByConnectionType[
          getConnection(pendingConnector).type
        ]
      : undefined
  );

  const openOptions = useCallback(() => {
    setWalletView(WALLET_VIEWS.OPTIONS);
  }, [setWalletView]);

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateConnectionError({
        connectionType: getConnection(pendingConnector).type,
        error: undefined,
      });
      setPendingConnector(undefined);
    }
  }, [pendingConnector, walletView]);

  // When new wallet is successfully set by the user, trigger logging of Amplitude analytics event.
  useEffect(() => {
    if (account && account !== lastActiveWalletAddress) {
      const walletType = getConnectionName(connector);
      const isReconnect =
        connectedWallets.filter(
          (wallet) =>
            wallet.account === account && wallet.walletType === walletType
        ).length > 0;
      if (!isReconnect) addWalletToConnectedWallets({ account, walletType });
    }
    setLastActiveWalletAddress(account);
  }, [
    connectedWallets,
    addWalletToConnectedWallets,
    lastActiveWalletAddress,
    account,
    connector,
    chainId,
  ]);

  useEffect(() => {
    if (userJustConnected && account) {
      trackWalletConnected(account);
    }
    setUserJustConnected(false);
  }, [userJustConnected]);

  useEffect(() => {
    if (account) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [account]);

  const tryActivation = useCallback(
    async (connector: Connector) => {
      const connectionType = getConnection(connector).type;

      // @ts-ignore:disable-next-line
      // gtag("event", "connect_wallet");
      try {
        setPendingConnector(connector);
        setWalletView(WALLET_VIEWS.PENDING);
        dispatch(updateConnectionError({ connectionType, error: undefined }));

        await connector.activate();

        dispatch(updateSelectedWallet({ wallet: connectionType }));
        setUserJustConnected(true);
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`);
        dispatch(
          updateConnectionError({ connectionType, error: error.message })
        );
      }
    },
    [dispatch]
  );

  function getOptions() {
    const isInjected = getIsInjected();
    const isMetaMask = getIsMetaMaskWallet();
    const isCoinbaseWallet = getIsCoinbaseWallet();

    const isCoinbaseWalletBrowser = isMobile && isCoinbaseWallet;
    const isMetaMaskBrowser = isMobile && isMetaMask;
    const isInjectedMobileBrowser =
      isCoinbaseWalletBrowser || isMetaMaskBrowser;

    let injectedOption;
    if (!isInjected) {
      if (!isMobile) {
        injectedOption = <InstallMetaMaskOption />;
      }
    } else if (!isCoinbaseWallet) {
      if (isMetaMask) {
        injectedOption = <MetaMaskOption tryActivation={tryActivation} />;
      } else {
        injectedOption = <InjectedOption tryActivation={tryActivation} />;
      }
    }

    let coinbaseWalletOption;
    if (isMobile && !isInjectedMobileBrowser) {
      coinbaseWalletOption = <OpenCoinbaseWalletOption />;
    } else if (!isMobile || isCoinbaseWalletBrowser) {
      coinbaseWalletOption = (
        <CoinbaseWalletOption tryActivation={tryActivation} />
      );
    }

    const walletConnectionOption =
      (!isInjectedMobileBrowser && (
        <WalletConnectOption tryActivation={tryActivation} />
      )) ??
      null;

    return (
      <>
        {injectedOption}
        {coinbaseWalletOption}
        {walletConnectionOption}
      </>
    );
  }

  function Content() {
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          openOptions={openOptions}
        />
      );
    }

    let headerRow;
    if (
      account &&
      (walletView === WALLET_VIEWS.PENDING ||
        walletView === WALLET_VIEWS.ACCOUNT)
    ) {
      headerRow = (
        <HeaderRow color="blue">
          <HoverText
            onClick={() =>
              setWalletView(
                account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS
              )
            }
          >
            <ArrowLeft />
          </HoverText>
        </HeaderRow>
      );
    } else {
      headerRow = (
        <HeaderRow>
          <HoverText>Connect a wallet</HoverText>
        </HeaderRow>
      );
    }

    function getTermsOfService() {
      return (
        <LightCard>
          <AutoRow style={{ flexWrap: "wrap" }}>
            <ThemedText.Body fontSize={12}>
              By connecting a wallet, you agree to fusionx’s{" "}
              <ExternalLink style={{ textDecoration: "underline" }} href="">
                Terms of Service
              </ExternalLink>{" "}
              and acknowledge that you have read and understand the fusionx{" "}
              <ExternalLink style={{ textDecoration: "underline" }} href="">
                Protocol Disclaimer
              </ExternalLink>
              .
            </ThemedText.Body>
          </AutoRow>
        </LightCard>
      );
    }

    return (
      <UpperSection>
        {/* Todo */}
        <CloseIcon data-testid="wallet-modal-close">
          <MenuItem bg="none" p={0} _hover={{ border: "none" }}>
            <CloseColor />
          </MenuItem>
        </CloseIcon>
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px" style={{ padding: "10px" }}>
            {!account &&
              walletView === WALLET_VIEWS.PENDING &&
              pendingConnector && (
                <PendingView
                  openOptions={openOptions}
                  connector={pendingConnector}
                  error={!!pendingError}
                  tryActivation={tryActivation}
                />
              )}
            {walletView !== WALLET_VIEWS.PENDING && (
              <OptionGrid data-testid="option-grid">{getOptions()}</OptionGrid>
            )}
            {!pendingError && getTermsOfService()}
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    );
  }

  return (
    <WalletWrapper>
      <Content />
    </WalletWrapper>
  );
}
