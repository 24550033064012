// To define Events, refer to the best practices:
// https://docs.mixpanel.com/docs/data-structure/events-and-properties#best-practices

import mixpanel from "mixpanel-browser";
import {
  Event,
  Property,
  PropertyButtonName_Value,
  QuickActionEvent,
  TradeEvent,
  TradeErrorEvent,
  UtmTrackedEvent,
  UTM_KEY,
} from "./types";
import { CHAIN_IDS_TO_NAMES } from "constants/chains";
import { Lender } from "types/lenderData/base";
import * as multibase from "@multibase/js";

const TOKEN_MIXPANEL = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "";
const TOKEN_MULTIBASE = process.env.REACT_APP_MULTIBASE_KEY || "";

export const initTracking = (account: string | undefined) => {
  if (TOKEN_MIXPANEL) {
    mixpanel.init(TOKEN_MIXPANEL, {
      debug: process.env.REACT_APP_ENVIRONMENT !== "production",
      track_pageview: true,
      persistence: "localStorage",
      api_host: process.env.REACT_APP_MIXPANEL_PROXY_URL,
    });
    account && mixpanel.identify(account);
  }
  if (TOKEN_MULTIBASE) {
    multibase.init(TOKEN_MULTIBASE);
    account && multibase.identify({ address: account });
  }
};

export const trackWalletConnected = (userAddress: string) => {
  mixpanel.identify(userAddress); // see https://docs.mixpanel.com/docs/tracking-methods/identifying-users#usage
  mixpanel.track(Event.WALLET_CONNECTED);

  multibase.identify({ address: userAddress });
  multibase.track(Event.WALLET_CONNECTED, {});
};

export const trackWalletDisconnected = () => {
  mixpanel.track(Event.WALLET_DISCONNECTED);
  mixpanel.reset(); // see https://docs.mixpanel.com/docs/tracking-methods/identifying-users#usage

  multibase.track(Event.WALLET_DISCONNECTED, {});
};

export const trackAccountSwitched = (userAddress: string) => {
  mixpanel.identify(userAddress);
  mixpanel.track(Event.ACCOUNT_SWITCHED);

  multibase.identify({ address: userAddress });
  multibase.track(Event.ACCOUNT_SWITCHED, {});
};

export const trackChainSelected = (chainId: number) => {
  mixpanel.track(Event.CHAIN_SELECTED, {
    [Property.CHAIN]: CHAIN_IDS_TO_NAMES[chainId],
  });

  multibase.track(Event.CHAIN_SELECTED, {
    [Property.CHAIN]: CHAIN_IDS_TO_NAMES[chainId],
  });
};

export const trackLenderSelected = (lender: Lender) => {
  mixpanel.track(Event.LENDER_SELECTED, { [Property.LENDER]: lender });

  multibase.track(Event.LENDER_SELECTED, { [Property.LENDER]: lender });
};

// generic for any button click
export const trackButtonClicked = (buttonName: PropertyButtonName_Value) => {
  mixpanel.track(Event.BUTTON_CLICKED, { [Property.BUTTON_NAME]: buttonName });

  multibase.track(Event.BUTTON_CLICKED, { [Property.BUTTON_NAME]: buttonName });
};

// generic for any quick action (modal)
export const trackQuickActionExecuted = (quickAction: QuickActionEvent) => {
  mixpanel.track(Event.QUICK_ACTION_EXECUTED, quickAction);

  multibase.track(Event.QUICK_ACTION_EXECUTED, quickAction);
};

// generic for any trade (main panel / swap)
export const trackTradeExecuted = (trade: TradeEvent) => {
  mixpanel.track(Event.TRADE_EXECUTED, trade);

  multibase.track(Event.TRADE_EXECUTED, trade);
};

// track error on gas estimate
export const trackExecutionError = (error: TradeErrorEvent) => {
  mixpanel.track(Event.TRANSACTION_ERROR, error);

  multibase.track(Event.TRANSACTION_ERROR, error);
};

// track utm params in url
// ONLY FOR MULTIBASE (doesn't support utm params yet)
export const trackUtmTracked = (search: string) => {
  if (search.includes("utm_")) {
    let utmTracked: UtmTrackedEvent = {};
    for (const key in UTM_KEY) {
      if (search.includes(UTM_KEY[key])) {
        utmTracked[UTM_KEY[key]] = search
          .split(`${UTM_KEY[key]}=`)[1]
          .split("&")[0];
      }
    }
    multibase.track(Event.UTM_TRACKED, utmTracked);
  }
};
