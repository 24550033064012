import { Currency, CurrencyAmount, Price, Token } from "@fusionx-finance/sdk";
import { parseUnits } from "ethers/lib/utils";
import JSBI from "jsbi";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo, useRef } from "react";
import { useChainId } from "state/globalNetwork/hooks";
import { useGeneralPrices, usePrices } from "state/oracles/hooks";
import { SupportedAssets } from "types/fusionx";
import { tokenToAssetUnsafe } from "./fusionx/addressesTokens";
import { getTokenFromAsset } from "./fusionx/tokens";

export const getVirtualUSD = (chainId: number): Token => {
  return new Token(
    chainId,
    "0x00006F5b9430bDB1C305452b27C64695445f23C2",
    18,
    "USD",
    "Dollar"
  );
};

export const skimToDecimals = (
  value: number | string,
  decimals: number,
  precision = decimals
) => {
  return Number(parseFloat(String(value)).toFixed(decimals)).toPrecision(
    precision
  );
};

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useOracleStablecoinAmountFromFiatValue(
  fiatValue: string | null | undefined
) {
  const chainId = useChainId();
  const [price] = usePrices([SupportedAssets.USDCE]);

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId) {
      return undefined;
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = parseFloat(fiatValue).toFixed(6).toString();
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(
        parsedForDecimals,
        getTokenFromAsset(SupportedAssets.USDCE, chainId)
      );
    } catch (error) {
      console.log("failed to parse:", parsedForDecimals);
      return undefined;
    }
  }, [chainId, fiatValue, price]);
}

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export function useDollarPriceViaOracles(
  currency?: Currency
): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId;
  const asset = tokenToAssetUnsafe(currency?.wrapped);
  const priceFromOracle = usePrices(asset ? [asset] : []);
  const price = useMemo(() => {
    if (!currency || !chainId) {
      return undefined;
    }

    if (priceFromOracle?.[0]) {
      try {
        const priceRaw = parseUnits(String(priceFromOracle[0]), 18);
        return new Price(
          currency,
          getVirtualUSD(chainId),
          JSBI.exponentiate(JSBI.BigInt("10"), JSBI.BigInt(currency.decimals)),
          priceRaw.toString()
        );
      } catch (e) {
        console.log("Error calculating oracle rates:", e);
      }
    }

    return undefined;
  }, [currency, chainId, priceFromOracle]);

  const lastPrice = useRef(price);
  if (!price || !lastPrice.current || !price.equalTo(lastPrice.current)) {
    lastPrice.current = price;
  }
  return lastPrice.current;
}

export function useOracleDollarValue(
  currencyAmount: CurrencyAmount<Currency> | undefined | null
) {
  const price = useDollarPriceViaOracles(currencyAmount?.currency);

  return useMemo(() => {
    if (!price || !currencyAmount) return null;
    try {
      return price.quote(currencyAmount);
    } catch (error) {
      return null;
    }
  }, [currencyAmount, price]);
}

/*
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export function useGeneralDollarPriceViaOracles(
  currency?: Currency
): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId;
  const asset =
    tokenToAssetUnsafe(currency?.wrapped) ?? (currency?.wrapped.address as any);
  const priceFromOracle = useGeneralPrices(asset ? [asset] : []);
  const price = useMemo(() => {
    if (!currency || !chainId) {
      return undefined;
    }

    if (priceFromOracle?.[0]) {
      try {
        const priceRaw = parseUnits(String(priceFromOracle[0]), 18);
        return new Price(
          currency,
          getVirtualUSD(chainId),
          JSBI.multiply(JSBI.BigInt("10"), JSBI.BigInt(currency.decimals)),
          priceRaw.toString()
        );
      } catch (e) {
        console.log("Error calculationg oracle rates:", e);
      }
    }

    return undefined;
  }, [currency, chainId, priceFromOracle]);

  const lastPrice = useRef(price);
  if (!price || !lastPrice.current || !price.equalTo(lastPrice.current)) {
    lastPrice.current = price;
  }
  return lastPrice.current;
}

/**
 * get a dollar value for a currency amount
 * Supports ANY token given a price is somewhere in the state
 */
export function useGeneralOracleDollarValue(
  currencyAmount: CurrencyAmount<Currency> | undefined | null
) {
  const price = useGeneralDollarPriceViaOracles(currencyAmount?.currency);

  return useMemo(() => {
    if (!price || !currencyAmount) return null;
    try {
      return price.quote(currencyAmount);
    } catch (error) {
      return null;
    }
  }, [currencyAmount, price]);
}
