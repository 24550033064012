import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import ORACLE_ABI from "abis/aave/AAVEOracle.json";
import API3_ABI from "abis/api3/Api3Oracle.json";
import CHAINLINK_AGGREGATOR_ABI from "abis/chainlink/ChainLinkAggregator.json";
import UNI_V2_PAIR_ABI from "abis/uniswap/uniswap-v2-pair.json";
import { multicallSecondary } from "utils/multicall";

import { SupportedChainId } from "constants/chains";
import { chainlinkOracles, getChainLinkKeys } from "hooks/fusionx/addresses";
import { api3OracleAddresses } from "hooks/fusionx/oracles/api3";
import { getAaveOracleContract } from "hooks/fusionx/useFusionxContract";
import { getLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { SupportedAssets } from "types/fusionx";
import { Lender } from "types/lenderData/base";
import { formatAavePrice, parseRawAmount } from "utils/tableUtils/prices";

const LENDLE_PAIR_MANTLE = "0x4c57BE599d0e0414785943569E9B6A66dA79Aa6b";
const WMNT_USDT_PAIR = "0x3e5922cd0cec71dc2d60ec8b36aa4c05b7c1672f";

interface OracleData {
  data: {
    [key: string]: number;
  };
  chainId: number;
}

interface QueryParams {
  chainId: number;
}

/**
 * Fetches Aave and uniswap V2 oracle data
 */
export const fetchOracleData: AsyncThunk<OracleData, QueryParams, any> =
  createAsyncThunk<OracleData, QueryParams>(
    "oracles/fetchOracleData",
    async ({ chainId }) => {
      const rawAddressDict = getLenderTokenAddresses(chainId, Lender.INIT);
      const filtered = Object.fromEntries(Object.entries(rawAddressDict));
      const aaveAssetNames = Object.keys(filtered);
      const addressesAaveUnderlyings = Object.values(filtered);
      const contract = getAaveOracleContract(chainId);
      const callAave = getAaveCalls(chainId, addressesAaveUnderlyings);
      try {
        const prices = await contract.getAssetsPrices(addressesAaveUnderlyings);
        const aaveData = parseAaveResults(chainId, prices, aaveAssetNames);
        return {
          data: { ...aaveData },
          chainId,
        };
      } catch (e) {
        console.log("fecthoracle price error", e);
        return {
          data: {},
          chainId,
        };
      }
      // const multicallResult = await multicallSecondary(
      //   chainId,
      //   [...ORACLE_ABI],
      //   [...callAave]
      // );

      // console.log("multicallResult", multicallResult);

      // const aaveResult = multicallResult.slice(0, callAave.length);
    }
  );

/**
 * Gets the calls foir the uniswap pools
 * @param chainId network id
 * @returns array of calls
 */
const getUniswapV2Calls = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return [
        {
          address: WMNT_USDT_PAIR,
          name: "getReserves",
          params: [],
        },
        {
          address: LENDLE_PAIR_MANTLE,
          name: "getReserves",
          params: [],
        },
      ];
    default:
      return [];
  }
};

/**
 * Create calldata for aave oracles
 * @param chainId network
 * @param addressesAaveUnderlyings address array
 * @returns call data
 */
const getAaveCalls = (chainId: number, addressesAaveUnderlyings: string[]) => {
  switch (chainId) {
    default: {
      const aaveOracle = getAaveOracleContract(chainId);
      return [
        {
          address: aaveOracle.address,
          name: "getAssetsPrices",
          params: [addressesAaveUnderlyings],
        },
      ];
    }
  }
};

/**
 * Create calldata for chainLink oracles
 * @param chainId network
 * @param addressesAaveUnderlyings address array
 * @returns call data
 */
const getChainLinkCalls = (
  chainId: number
): [
  {
    address: string;
    name: string;
    params: any[];
  }[],
  string[]
] => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return [[], []];
    default: {
      const keys = getChainLinkKeys(chainId).filter(
        (k) => k.split("")[1] === "USD"
      );
      const addresses = keys.map((k) => chainlinkOracles[k][chainId]);
      return [
        addresses.map((tk) => {
          return {
            address: tk,
            name: "latestRoundData",
            params: [],
          };
        }),
        keys.map((k) => k.split("-")[0]),
      ];
    }
  }
};

/**
 * Processes data creeated from fetch through 'getUniswapV2Calls
 * @param data the result data array slice from the multicall
 * @param chainId network id
 * @returns price dictionary asset->number
 */
const parseUniswapV2results = (data: any[], chainId: number) => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      let uniswapData = {};
      if (data.length > 0) {
        const reserves = data[1];
        // lendle and wmnt are respective token0s
        const lendleReserve = parseRawAmount(reserves[0].toString(), 18);
        const wmntReserve = parseRawAmount(reserves[1].toString(), 18);
        const reservesWmntUsdt = data[0];
        const usdtReserve = parseRawAmount(reservesWmntUsdt[0].toString(), 6);
        const wmnt2Reserve = parseRawAmount(reservesWmntUsdt[1].toString(), 18);
        const lendPrice =
          (wmntReserve / lendleReserve / wmnt2Reserve) * usdtReserve;
        uniswapData["LEND"] = lendPrice;
      }
      return uniswapData;
    }
    default:
      return {};
  }
};

/**
 * Parser for aave results in ulticall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseAaveResults = (chainId: number, data: any, assetName: string[]) => {
  switch (chainId) {
    default: {
      return Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: formatAavePrice(entry.toString(), chainId),
          };
        })
      );
    }
  }
};

/**
 * Parser for aave results in ulticall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseChainLinkResults = (
  chainId: number,
  data: any[],
  assetName: string[]
) => {
  switch (chainId) {
    default: {
      return Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: parseRawAmount(
              entry?.answer?.toString() ?? "0",
              8
            ),
          };
        })
      );
    }
  }
};

/**
 * Create calldata for api3 oracles
 * @param chainId network
 * @returns call data
 */
const getApi3Calls = (
  chainId: number
): [
  {
    address: string;
    name: string;
    params: any[];
  }[],
  string[]
] => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      const keys = [SupportedAssets.WETH, SupportedAssets.METH];
      const addresses = keys.map((k) => api3OracleAddresses[chainId][k]);
      return [
        addresses.map((tk) => {
          return {
            address: tk,
            name: "read",
            params: [],
          };
        }),
        keys,
      ];
    }
    default:
      return [[], []];
  }
};

/**
 * Parser for api3 results in ulticall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseApi3Results = (
  chainId: number,
  data: any[],
  assetName: string[]
) => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      let prices = Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: parseRawAmount(
              entry?.[0]?.toString() ?? "0",
              18
            ),
          };
        })
      );
      // METH is given in mETH/WETH
      prices[SupportedAssets.METH] =
        prices[SupportedAssets.METH] * prices[SupportedAssets.WETH];
      return prices;
    }
    default: {
      return {};
    }
  }
};
