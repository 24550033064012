import { getChainInfo } from "constants/chainInfo";
import { SupportedChainId } from "constants/chains";
import { AlertCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { ThemedText } from "theme";
import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function FailedNetworkSwitchPopup({
  chainId,
}: {
  chainId: SupportedChainId;
}) {
  const chainInfo = getChainInfo(chainId);
  const theme = useTheme();

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <AlertCircle color={theme.red} size={24} />
      </div>
      <AutoColumn gap="8px">
        <ThemedText.Body fontWeight={500}>
          Failed to switch networks from the fusionx Interface. In order to use
          fusionx on {chainInfo.label}, you must change the network in your
          wallet.
        </ThemedText.Body>
      </AutoColumn>
    </RowNoFlex>
  );
}
