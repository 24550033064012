import { ZERO, Currency, CurrencyAmount, Token } from "@fusionx-finance/sdk";
import { MAINNET_CHAINS, NATIVE_SYMBOL, TOKEN_META } from "constants/fusionx";
import { DEFAULT_CHAINID, SupportedChainId } from "constants/chains";
import { getLenderAssets } from "constants/getAssets";
import { WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import {
  AaveInterestMode,
  SupportedAssets,
  getFallbackAsset,
  toErc20Asset,
} from "types/fusionx";
import { Lender } from "types/lenderData/base";
import {
  addressesLendleLTokens,
  addressesLendleSTokens,
  addressesLendleVTokens,
} from "./addressesLendle";
import { addressesTokens, tokenToAsset } from "./addressesTokens";

const FALLBACK_ADDRESS = "0x0000000000000000000000000000000000000001";

export const getAaveTokens = (
  chainId: number
): { [assetKey: string]: Token } => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId).map((asset) => {
      return {
        [asset]: safeGetToken(chainId, asset),
      };
    })
  );
};

export const getCompoundV2Tokens = (
  chainId: number
): { [assetKey: string]: Token } => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.COMPOUND_V2)
      .filter((x) => Object.keys(x)[0] !== NATIVE_SYMBOL[chainId])
      .map((asset) => {
        return {
          [asset]: safeGetToken(chainId, asset, Lender.COMPOUND_V2),
        };
      })
  );
};

export const getCompoundV3Tokens = (
  chainId: number
): { [assetKey: string]: Token } => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.COMPOUND_V3)
      .filter((x) => Object.keys(x)[0] !== NATIVE_SYMBOL[chainId])
      .map((asset) => {
        return {
          [asset]: safeGetToken(chainId, asset, Lender.COMPOUND_V3),
        };
      })
  );
};

/**
 * Gets a dictionary of all tokens for a given lender
 * @param chainId chain number / enum
 * @param lendingProtocol lender
 * @returns dictionary of all underlying tokens mapped via address->Token
 */
export const getTokensByAddress = (
  chainId: number,
  lendingProtocol = Lender.INIT
): { [address: string]: Token } => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId, lendingProtocol).map((asset) => {
      return {
        [safeGetToken(chainId, asset, lendingProtocol).address.toLowerCase()]:
          safeGetToken(chainId, asset, lendingProtocol),
      };
    })
  );
};

/**
 * Gets a token for a given asset, if none is found, a default token is returned
 * Lending protocol only makes a difference on testnets
 * as each lender hsa their own e.g. USDC deployment
 * @param chainId chainId
 * @param asset asset enum
 * @param lendingProtocol lending protocol
 * @returns Token related to asset or default token
 */
export const safeGetToken = (
  chainId: number,
  asset: SupportedAssets,
  lendingProtocol = Lender.INIT
): Token => {
  const safeChainId = chainId ?? DEFAULT_CHAINID;

  if (asset === SupportedAssets.MNT && chainId == SupportedChainId.MANTLE) {
    return WRAPPED_NATIVE_CURRENCY[chainId];
  }

  let tokenAddress: `0x${string}` = "0x";
  if (MAINNET_CHAINS.includes(chainId)) {
    tokenAddress = addressesTokens[asset]?.[safeChainId] ?? FALLBACK_ADDRESS;
  }
  try {
    let _asset = String(asset).toUpperCase();
    if (_asset.toUpperCase() === "MIMATIC") {
      _asset = SupportedAssets.MAI;
    }
    return new Token(
      safeChainId,
      tokenAddress, // defaults to WETH
      TOKEN_META[_asset].decimals,
      TOKEN_META[_asset].symbol,
      TOKEN_META[_asset].name
    );
  } catch (e) {
    console.log("Error getting token from asset:", asset, e);
    return new Token(
      safeChainId,
      tokenAddress, // defaults to WETH
      TOKEN_META[SupportedAssets.WETH].decimals,
      TOKEN_META[SupportedAssets.WETH].symbol,
      TOKEN_META[SupportedAssets.WETH].name
    );
  }
};

export const safeGetCompoundCToken = (
  chainId: number,
  asset: SupportedAssets
): Token => {
  return new Token(
    chainId ?? DEFAULT_CHAINID,
    FALLBACK_ADDRESS,
    8,
    TOKEN_META[asset].symbol,
    TOKEN_META[asset].name
  );
};

/**
 * Converts underlying token class to aToken
 * @param currency currency to convert
 * @returns aToken with USDC as fallback
 */
const toAToken = (currency?: Currency, lender = Lender.INIT): Token => {
  const chainId = currency?.chainId ?? DEFAULT_CHAINID;
  const _asset = tokenToAsset(currency);

  const address =
    addressesLendleLTokens[_asset]?.[chainId ?? DEFAULT_CHAINID] ??
    FALLBACK_ADDRESS;

  return new Token(
    chainId,
    address,
    TOKEN_META[_asset]?.decimals ?? 18,
    TOKEN_META[_asset]?.symbol,
    TOKEN_META[_asset]?.name
  );
};

/**
 * Converts underlying currency amount to aToken amount
 * @param currencyAmount currencyAmount to convert
 * @param lender aave V3 or a valid aave V2 fork
 * @returns aToken amount with USDC as fallback
 */
export const toATokenAmount = (
  currencyAmount?: CurrencyAmount<Currency> | null,
  lender = Lender.INIT
): CurrencyAmount<Currency> => {
  const aToken = toAToken(currencyAmount?.currency, lender);
  return CurrencyAmount.fromRawAmount(
    aToken,
    currencyAmount?.quotient.toString() ?? "0"
  );
};

/**
 * Converts underlying token class to either a vToken or a sToken
 * @param currency currency to convert
 * @param lender aave V3 or a valid aave V2 fork
 * @param interestMode the interest mode
 * @returns debt token with USDC as fallback
 */
export const toDebtToken = (
  currency?: Currency,
  interestMode?: AaveInterestMode,
  lender = Lender.INIT
): Token => {
  const chainId = currency?.chainId ?? DEFAULT_CHAINID;
  const _asset = tokenToAsset(currency);
  const _irMode = interestMode ?? AaveInterestMode.VARIABLE;
  const isStable = _irMode === AaveInterestMode.STABLE;
  let address = ZERO;
  if (_irMode === AaveInterestMode.VARIABLE) {
    address =
      addressesLendleVTokens[_asset][chainId ?? DEFAULT_CHAINID] ??
      FALLBACK_ADDRESS;
  } else {
    address =
      addressesLendleSTokens[_asset][chainId ?? DEFAULT_CHAINID] ??
      FALLBACK_ADDRESS;
  }
  return new Token(
    chainId,
    address as any,
    TOKEN_META[_asset]?.decimals ?? 18,
    TOKEN_META[_asset]?.symbol,
    `${isStable ? "s" : "v"}${TOKEN_META[_asset]?.name}`
  );
};

/**
 * Converts underlying currency amount to debtToken amount
 * @param currencyAmount currencyAmount to convert
 * @param interestRateMode ir mode of debt
 * @param lender aave V3 or a valid aave V2 fork
 * @returns debt token amount with USDC as fallback
 */
export const toDebtTokenAmount = (
  currencyAmount?: CurrencyAmount<Currency> | null | undefined,
  interestMode?: AaveInterestMode,
  lender = Lender.INIT
): CurrencyAmount<Currency> => {
  const debtToken = toDebtToken(currencyAmount?.currency, interestMode, lender);
  return CurrencyAmount.fromRawAmount(
    debtToken,
    currencyAmount?.quotient.toString() ?? "0"
  );
};

/**
 * Gets a debt token of aave V3 or aave v2 forks
 * @param chainId chainId
 * @param asset underlying asset
 * @param interestMode debt mode
 * @param lender aave V3 or a valid aave v2 fork
 * @returns token if debt token is available, undefined otherwise
 */
export const safeGetBorrowToken = (
  chainId: number,
  asset: SupportedAssets,
  interestMode: AaveInterestMode,
  lender = Lender.INIT
): Token | undefined => {
  const erc20Asset = toErc20Asset(asset);
  if (interestMode === AaveInterestMode.STABLE) {
    const address =
      addressesLendleSTokens[erc20Asset]?.[chainId ?? DEFAULT_CHAINID] ??
      FALLBACK_ADDRESS;
    return new Token(
      chainId ?? DEFAULT_CHAINID,
      address,
      TOKEN_META[erc20Asset].decimals,
      TOKEN_META[erc20Asset].symbol,
      TOKEN_META[erc20Asset].name
    );
  }
  if (interestMode === AaveInterestMode.VARIABLE) {
    const address =
      addressesLendleVTokens[erc20Asset]?.[chainId ?? DEFAULT_CHAINID] ??
      FALLBACK_ADDRESS;
    return new Token(
      chainId ?? DEFAULT_CHAINID,
      address,
      TOKEN_META[erc20Asset]?.decimals ?? 18,
      TOKEN_META[erc20Asset]?.symbol,
      TOKEN_META[erc20Asset]?.name
    );
  }
  return undefined;
};

export const filterSupportedAssets = (
  token0?: Currency,
  token1?: Currency
): SupportedAssets[] => {
  const chainId = token0?.chainId ?? DEFAULT_CHAINID;
  const arr: SupportedAssets[] = [];
  const asset0 = tokenToAsset(token0);
  if (token0 && safeGetToken(chainId, asset0)?.equals(token0)) arr.push(asset0);
  const asset1 = tokenToAsset(token1);
  if (token1 && safeGetToken(chainId, asset1)?.equals(token1)) arr.push(asset1);

  return arr;
};

export const getAaveATokenAddress = (
  chainId: number | undefined,
  asset: string | undefined
) => {
  if (!chainId || !asset) return FALLBACK_ADDRESS;
  const _asset = getFallbackAsset(asset);
  return addressesLendleLTokens[_asset]?.[chainId];
};

export const getTokenFromAsset = (asset: SupportedAssets, chainId: number) => {
  const address = addressesTokens[asset]?.[chainId];
  const meta = TOKEN_META[asset];
  if (!address || !meta) return undefined;
  return new Token(chainId, address, meta.decimals, meta.symbol, meta.name);
};
