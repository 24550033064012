import AuthenticatedHeader from "components/WalletDropdown/AuthenticatedHeader";
import { getConnection } from "connection";
import { useCallback } from "react";
import { useAppDispatch } from "state/hooks";
import styled, { useTheme } from "styled-components";

import { ReactComponent as Close } from "assets/images/x.svg";
import { clearAllTransactions } from "state/transactions/reducer";
import { LinkStyledButton, ThemedText } from "theme";
import { ButtonPrimary } from "../Button";
import { AutoRow } from "../Row";
import Transaction from "./Transaction";
import { useWeb3ReactWrapped } from "hooks/web3";
import { MenuItem } from "@chakra-ui/react";

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.accent : "inherit"};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const AccountSection = styled.div`
  padding: 0rem 1rem;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`;

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.backgroundTertiary};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.textTertiary};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`;

const WalletName = styled.div`
  margin-left: 10px;
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textTertiary};
`;

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`;

const ConnectButton = styled(ButtonPrimary)`
  height: 40px;
  width: 288px;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-top: 16px;
  margin-bottom: 16px;
`;

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />;
      })}
    </TransactionListWrapper>
  );
}

interface AccountDetailsProps {
  pendingTransactions: string[];
  confirmedTransactions: string[];
  openOptions: () => void;
}

export default function AccountDetails({
  pendingTransactions,
  confirmedTransactions,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useWeb3ReactWrapped();

  const theme = useTheme();
  const dispatch = useAppDispatch();

  function formatConnectorName() {
    return (
      <WalletName>
        Connected with {getConnection(connector).getName()}
      </WalletName>
    );
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  const isAuthenticated = !!account;

  return (
    <>
      <UpperSection>
        {/* TODO */}
        <CloseIcon>
          <MenuItem
            bg="none"
            p={0}
            _hover={{ border: "none" }}
            _active={{ border: "none" }}
            _focus={{ border: "none" }}
          >
            <CloseColor />
          </MenuItem>
        </CloseIcon>
        <HeaderRow>
          Account
          {formatConnectorName()}
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            {isAuthenticated ? (
              <AuthenticatedHeader openOptions={openOptions} />
            ) : (
              <ConnectButton data-testid="wallet-connect-wallet">
                Connect wallet
              </ConnectButton>
            )}
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <Divider />
          <AutoRow mb={"1rem"} style={{ justifyContent: "space-between" }}>
            <ThemedText.Body>Recent Transactions</ThemedText.Body>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>
              (clear all)
            </LinkStyledButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection>
          <Divider />
          <ThemedText.Body color={theme.textPrimary}>
            Your transactions will appear here...
          </ThemedText.Body>
        </LowerSection>
      )}
    </>
  );
}
