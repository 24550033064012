import { transparentize } from "polished";
import styled, { DefaultTheme } from "styled-components";

export const BasicColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const BasicRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const LightBoxShadow = (theme: DefaultTheme) => `0 4px 8px 0 ${transparentize(0.9, theme.shadow)};`

export const DarkerBoxShadow = `2px 5px 10px 5px rgba(0,0,0,0.2)`

export const FadeInAnimationQuick = `
  animation: fadeIn;
  animation-duration: 150ms;
`

export const FadeInAnimation = `
  animation: fadeIn;
  animation-duration: 300ms;
`

export const PageContainerWithAnimation = styled.div`
  ${FadeInAnimation}
`
