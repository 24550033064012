import useDebounce from 'hooks/useDebounce'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'state/hooks'
import { supportedChainId } from 'utils/supportedChainId'

import { updateChainId } from './reducer'
import { useViewMode } from 'hooks/viewMode/useViewMode'
import { useWeb3ReactWrapped } from 'hooks/web3'

export default function Updater(): null {
  const { chainId, provider } = useWeb3ReactWrapped()
  const dispatch = useAppDispatch()
  const windowVisible = useIsWindowVisible()

  const [activeChainId, setActiveChainId] = useState(chainId)

  useEffect(() => {
    if (provider && chainId && windowVisible) {
      setActiveChainId(chainId)
    }
  }, [dispatch, chainId, provider, windowVisible])

  const viewMode = useViewMode()
  const debouncedChainId = useDebounce(activeChainId, 100)

  useEffect(() => {
    if (debouncedChainId && supportedChainId(debouncedChainId, viewMode)) {
      dispatch(updateChainId({ chainId: debouncedChainId }))
    }
  }, [dispatch, debouncedChainId, viewMode])

  return null
}
