import "@reach/dialog/styles.css";
import "inter-ui";
import "./css/animate.css";

import { FeatureFlagsProvider } from "./featureFlags";
import { MulticallUpdater } from "./lib/state/multicall";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Web3Provider from "components/Web3Provider";
import { LanguageProvider } from "./i18n";
import App from "./pages/App";
import store from "./state";
import ApplicationUpdater from "./state/application/updater";
import ListsUpdater from "./state/lists/updater";
import TransactionUpdater from "./state/transactions/updater";
import UserUpdater from "./state/user/updater";
import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import RadialGradientByChainUpdater from "./theme/RadialGradientByChainUpdater";
import { MulticallAltUpdater } from "./lib/state/multicallAlt";

const queryClient = new QueryClient();

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  return (
    <>
      <RadialGradientByChainUpdater />
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <MulticallAltUpdater />
    </>
  );
}

const container = document.getElementById("root") as HTMLElement;

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <FeatureFlagsProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LanguageProvider>
              <Web3Provider>
                <Updaters />
                <ThemeProvider>
                  <ThemedGlobalStyle />
                  <App />
                </ThemeProvider>
              </Web3Provider>
            </LanguageProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </Provider>
  </StrictMode>
);

// if (process.env.REACT_APP_SERVICE_WORKER !== "false") {
//   serviceWorkerRegistration.register();
// }
