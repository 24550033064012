import { BetaBanner, BetaContainer } from "components/Beta";
import { useGetNativeBalance } from "lib/hooks/useCurrencyBalance";
import { Text } from "rebass";
import { useDarkModeManager, useTopBarBannerHidden } from "state/user/hooks";
import styled, { useTheme } from "styled-components";
import Web3Status from "../Web3Status";
import NetworkSelector from "./NetworkSelector";
import { useIsMobile } from "hooks/useIsMobile";
import SettingsTab from "components/Settings";
import { Percent } from "@fusionx-finance/sdk";
import { useHistory, useLocation } from "react-router-dom";
import { BasicRow } from "components/Basic/layout";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Menu as MenuIcon } from "react-feather";
import { useIsTablet } from "hooks/useIsTablet";
import { useWeb3ReactWrapped } from "hooks/web3";
import Column from "components/Column";
import { opacify } from "theme/utils";
import { ButtonPrimary } from "components/Button";
import { CloseIcon } from "theme";
import Row from "components/Row";
import { FusionXLogo, FusionXLogoWithText } from "components/Logo";

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 0px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 0.75rem 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.backgroundTertiary} 50%, ${theme.backgroundTertiary} 50% )}}`};
  background-position: ${({ showBackground }) =>
    showBackground ? "0 -100%" : "0 0"};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px
    ${({ theme, showBackground }) =>
      showBackground ? theme.backgroundTertiary : "transparent;"};
  transition: background-position 0.3s, box-shadow 0.3s;
  background-blend-mode: hard-light;
  border-bottom: 1px solid ${({ theme }) => theme.container_border};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 5px 1fr;
  `};
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  gap: 0.75rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 0.5rem;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) =>
    !active ? theme.backgroundDark : theme.backgroundOutline};
  border-radius: 40px;
  white-space: nowrap;
  width: 100%;
  height: 38px;
  :focus {
    border: 1px solid ${({ theme }) => theme.container_border};
    border-radius: 40px;
  }
`;

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`;

const StyledIconAbs = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) =>
    theme.darkMode
      ? ``
      : `filter: drop-shadow(-0.1px -0.1px 2px rgba(0, 0, 0, 0.1)) 
  drop-shadow(0.1px -0.1px 2px rgba(0, 0, 0, 0.1)) 
  drop-shadow(0.1px 0.1px 2px rgba(0, 0, 0, 0.1))
  drop-shadow(-0.1px 0.1px 2px rgba(0, 0, 0, 0.1));`}
  align-items: center;
`;

const TitleAndLinks = styled(BasicRow)`
  gap: 2rem;
`;

const HeaderLinks = styled(BasicRow)`
  gap: 2rem;
  margin-left: 1rem;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
    gap: 1rem;
    margin-left: unset;
  `};
`;

const HeaderLink = styled.a<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  line-height: normal;
  font-size: 0.9rem;
  color: ${({ active, theme }) => (active ? theme.accent : theme.textPrimary)};
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.accent};
  }
  width: max-content;
`;

const TopBar = styled(Row)<{ isHidden: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.accent};
  padding: 0.5em;
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
`;

const TopBarLink = styled.a`
  margin: auto;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.black};
  text-align: center;
  :hover {
    color: ${({ theme }) => opacify(70, theme.black)};
    transition: all 0.2s ease-in-out;
  }
`;

const TopBarCloseButton = styled(ButtonPrimary)`
  width: fit-content;
  padding: 0;
  width: 20px;
  height: 20px;
`;
// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
export default function Header() {
  const { account } = useWeb3ReactWrapped();
  const userEthBalance = useGetNativeBalance();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const theme = useTheme();

  const [darkMode] = useDarkModeManager();

  const { pathname } = useLocation();
  const history = useHistory();

  const selectPath = (path: string) => () =>
    !pathname.includes(path) && history.push(path);

  const HamburgerMenuItem = ({ children, active, onClick }) => {
    return (
      <HeaderLink active={active} onClick={onClick}>
        <MenuItem
          background={theme.backgroundDark}
          _hover={{ background: theme.backgroundDark }}
          _active={{ background: theme.backgroundDark }}
          _focus={{ background: theme.backgroundDark }}
          fontWeight={active ? 700 : 400}
          fontSize="1.25rem"
          padding="0.75rem 1.25rem"
        >
          {children}
        </MenuItem>
      </HeaderLink>
    );
  };

  const tradingCompetitionPath = "leaderboard";
  const [isTopBarBannerHidden, hideTopBarBanner] = useTopBarBannerHidden();

  return (
    <Column style={{ width: "100%" }}>
      {/* <TopBar isHidden={isTopBarBannerHidden}>
        <div style={{ display: "flex", width: "100%", textAlign: "center" }}>
          <TopBarLink href="https://fusionx.io/competition/" target="_blank">
            Join our trading competition with $10,000+ in prize money!
          </TopBarLink>
        </div>
        <TopBarCloseButton>
          <CloseIcon onClick={() => hideTopBarBanner()} />
        </TopBarCloseButton>
      </TopBar> */}
      <HeaderFrame showBackground={true}>
        <TitleAndLinks>
          <Title>
            <StyledIconAbs>
              {isMobile ? (
                <FusionXLogo size="24px" />
              ) : (
                <FusionXLogoWithText size="32px" />
              )}
              {/* <BetaContainer isMobile={isMobile}>
                <BetaBanner />
              </BetaContainer> */}
            </StyledIconAbs>
          </Title>
          {/* <HeaderLinks>
            <HeaderLink
              active={pathname.includes("margin")}
              onClick={selectPath("margin")}
            >
              Margin
            </HeaderLink>
          </HeaderLinks> */}
        </TitleAndLinks>
        <HeaderControls>
          <HeaderElement>
            <AccountElement active={!!account}>
              <NetworkSelector />
            </AccountElement>
          </HeaderElement>
          <HeaderElement>
            <AccountElement active={!!account}>
              {account && userEthBalance ? (
                <BalanceText
                  style={{ flexShrink: 0, userSelect: "none" }}
                  pl="0.75rem"
                  pr=".4rem"
                  fontWeight={500}
                >
                  {userEthBalance?.toSignificant(3)}{" "}
                  {userEthBalance.currency.symbol}
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElement style={{ height: "36px" }}>
            <SettingsTab placeholderSlippage={new Percent(3, 1000)} />
          </HeaderElement>

          <Menu>
            <MenuButton
              as={Button}
              background="none"
              color={theme.white}
              _hover={{ background: "none", color: theme.accent }}
              _active={{ background: "none", color: theme.accent }}
              display={isMobile || isTablet ? "flex" : "none"}
              padding={0}
              minW={0}
            >
              <MenuIcon />
            </MenuButton>
            <MenuList
              background={theme.backgroundDark}
              border={"1px solid" + theme.container_border}
              minW={0}
              borderRadius="1rem"
            >
              <HamburgerMenuItem
                active={pathname.includes("margin")}
                onClick={selectPath("margin")}
              >
                Margin
              </HamburgerMenuItem>
            </MenuList>
          </Menu>
        </HeaderControls>
      </HeaderFrame>
    </Column>
  );
}
