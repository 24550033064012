import { ethers } from "ethers";
import { getMulticallContract } from "./fusionx/contractHelper";

export type MultiCallResponse<T> = T | null;

export interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

const multicall = async (
  chainId: number,
  abi: any[],
  calls: Call[],
  providerId = 0,
  retry = true,
  fallback = false
): Promise<ethers.utils.Result[]> => {
  try {
    const multi = getMulticallContract(chainId, providerId, fallback);
    const itf = new ethers.utils.Interface(abi);

    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);
    const { returnData } = await multi.callStatic.aggregate(calldata);

    const res = (returnData as any[]).map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    return res;
  } catch (error: any) {
    console.log("Error with multicall", error);
    if (fallback || !retry)
      // if we are already in the fallback, we throw an error
      throw new Error(error);
    else return multicall(chainId, abi, calls, providerId, false, true);
  }
};

export const multicallSecondary = async (
  chainId: number,
  abi: any[],
  calls: Call[]
): Promise<ethers.utils.Result[]> => {
  return multicall(chainId, abi, calls, 1);
};

export default multicall;
