import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SupportedAssets } from "types/fusionx";

const FeedData = {
  [SupportedAssets.WSTETH]:
    "https://api.diadata.org/v1/assetQuotation/Ethereum/0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
  [SupportedAssets.WMNT]:
    "https://api.diadata.org/v1/assetQuotation/Ethereum/0x3c3a81e81dc49A522A592e7622A7E711c06bf354",
  [SupportedAssets.USDY]:
    "https://api.diadata.org/v1/assetQuotation/Ethereum/0x96F6eF951840721AdBF46Ac996b59E0235CB985C",
};

export interface ApiResponse {
  price: number;
  price24H: number;
}

interface ApiResponseData {
  data: {
    [key: string]: ApiResponse;
  };
}

interface EmptyQueryParams {}

export const fetchViaApi: AsyncThunk<ApiResponseData, EmptyQueryParams, any> =
  createAsyncThunk<ApiResponseData, EmptyQueryParams>(
    "oracles/fetchViaApi",
    async () => {
      const keys = Object.keys(FeedData);
      const promises = keys.map((k) => axios.get(FeedData[k]));
      const data = await Promise.all(promises);

      return {
        data: Object.assign(
          {},
          ...data.map((d: any, i) => {
            return {
              [keys[i]]: {
                price: d.data.Price,
                price24H: d.data.PriceYesterday,
              },
            };
          })
        ),
      };
    }
  );
