import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector } from "@web3-react/core";
import { GnosisSafe } from "@web3-react/gnosis-safe";
import { MetaMask } from "@web3-react/metamask";
import { Network } from "@web3-react/network";
import { Actions, Connector } from "@web3-react/types";
import {
  default as GNOSIS_ICON,
  default as UNISWAP_LOGO,
} from "assets/svg/logo.svg";
import COINBASE_ICON from "assets/wallets/coinbase-icon.svg";
import UNIWALLET_ICON from "assets/wallets/uniswap-wallet-icon.png";
import WALLET_CONNECT_ICON from "assets/wallets/walletconnect-icon.svg";
import { SupportedChainId } from "constants/chains";
import { RPC_URLS } from "constants/networks";
import { RPC_PROVIDERS } from "constants/providers";
import { isMobile, isNonIOSPhone } from "utils/userAgent";
import {
  UniwalletConnect as UniwalletWCV2Connect,
  WalletConnectV2,
} from "./WalletConnectV2";
import { COINBASE_WALLET_DEEPLINK, Connection, ConnectionType } from "./types";
import {
  getInjection,
  getIsCoinbaseWallet,
  getIsInjected,
  getIsMetaMaskWallet,
} from "./utils";

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      urlMap: RPC_PROVIDERS,
      defaultChainId: SupportedChainId.MANTLE,
    })
);
export const networkConnection: Connection = {
  getName: () => "Network",
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
  shouldDisplay: () => false,
};

const getIsCoinbaseWalletBrowser = () => isMobile && getIsCoinbaseWallet();
const getIsMetaMaskBrowser = () => isMobile && getIsMetaMaskWallet();
const getIsInjectedMobileBrowser = () =>
  getIsCoinbaseWalletBrowser() || getIsMetaMaskBrowser();

const getShouldAdvertiseMetaMask = () =>
  !getIsMetaMaskWallet() &&
  !isMobile &&
  (!getIsInjected() || getIsCoinbaseWallet());
const getIsGenericInjector = () =>
  getIsInjected() && !getIsMetaMaskWallet() && !getIsCoinbaseWallet();

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError })
);

export const injectedConnection: Connection = {
  getName: () => getInjection().name,
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  getIcon: (isDarkMode: boolean) => getInjection(isDarkMode).icon,
  shouldDisplay: () =>
    getIsMetaMaskWallet() ||
    getShouldAdvertiseMetaMask() ||
    getIsGenericInjector(),
  // If on non-injected, non-mobile browser, prompt user to install Metamask
  overrideActivate: () => {
    if (getShouldAdvertiseMetaMask()) {
      window.open("https://metamask.io/", "inst_metamask");
      return true;
    }
    return false;
  },
};
const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>(
  (actions) => new GnosisSafe({ actions })
);
export const gnosisSafeConnection: Connection = {
  getName: () => "Gnosis Safe",
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
  getIcon: () => GNOSIS_ICON,
  shouldDisplay: () => false,
};

export const walletConnectV2Connection: Connection = new (class
  implements Connection
{
  private initializer = (
    actions: Actions,
    defaultChainId = SupportedChainId.MANTLE
  ) => new WalletConnectV2({ actions, defaultChainId, onError });

  type = ConnectionType.WALLET_CONNECT;
  getName = () => "WalletConnect";
  getIcon = () => WALLET_CONNECT_ICON;
  shouldDisplay = () => !getIsInjectedMobileBrowser();

  private _connector = initializeConnector<WalletConnectV2>(this.initializer);
  overrideActivate = (chainId?: SupportedChainId) => {
    // Always re-create the connector, so that the chainId is updated.
    this._connector = initializeConnector((actions) =>
      this.initializer(actions, chainId)
    );
    return false;
  };
  get connector() {
    return this._connector[0];
  }
  get hooks() {
    return this._connector[1];
  }
})();

const [web3WCV2UniwalletConnect, web3WCV2UniwalletConnectHooks] =
  initializeConnector<UniwalletWCV2Connect>(
    (actions) => new UniwalletWCV2Connect({ actions, onError })
  );
export const uniwalletWCV2ConnectConnection: Connection = {
  getName: () => "Uniswap Wallet",
  connector: web3WCV2UniwalletConnect,
  hooks: web3WCV2UniwalletConnectHooks,
  type: ConnectionType.UNISWAP_WALLET_V2,
  getIcon: () => UNIWALLET_ICON,
  shouldDisplay: () => Boolean(!getIsInjectedMobileBrowser() && !isNonIOSPhone),
  isNew: true,
};

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] =
  initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: RPC_URLS[SupportedChainId.MANTLE][0],
          appName: "fusionx",
          appLogoUrl: UNISWAP_LOGO,
          reloadOnDisconnect: false,
        },
        onError,
      })
  );
export const coinbaseWalletConnection: Connection = {
  getName: () => "Coinbase Wallet",
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
  getIcon: () => COINBASE_ICON,
  shouldDisplay: () =>
    Boolean(
      (isMobile && !getIsInjectedMobileBrowser()) ||
        !isMobile ||
        getIsCoinbaseWalletBrowser()
    ),
  // If on a mobile browser that isn't the coinbase wallet browser, deeplink to the coinbase wallet app
  overrideActivate: () => {
    if (isMobile && !getIsInjectedMobileBrowser()) {
      window.open(COINBASE_WALLET_DEEPLINK, "cbwallet");
      return true;
    }
    return false;
  },
};

export function getConnections() {
  return [
    uniwalletWCV2ConnectConnection,
    injectedConnection,
    walletConnectV2Connection,
    coinbaseWalletConnection,
    gnosisSafeConnection,
    networkConnection,
  ];
}

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = getConnections().find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error("unsupported connector");
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return injectedConnection;
      case ConnectionType.COINBASE_WALLET:
        return coinbaseWalletConnection;
      case ConnectionType.WALLET_CONNECT:
        return walletConnectV2Connection;
      case ConnectionType.UNISWAP_WALLET_V2:
        return uniwalletWCV2ConnectConnection;
      case ConnectionType.NETWORK:
        return networkConnection;
      case ConnectionType.GNOSIS_SAFE:
        return gnosisSafeConnection;
    }
  }
}

export const getConnectionName = (c: Connector | ConnectionType) => {
  if (c instanceof Connector) {
    const connection = getConnections().find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error("unsupported connector");
    }
    return getConnection(c).getName();
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return "Browser Wallet";
      case ConnectionType.COINBASE_WALLET:
        return "Coinbase Wallet";
      case ConnectionType.WALLET_CONNECT:
        return "Wallet Connect";
      case ConnectionType.UNISWAP_WALLET_V2:
        return "Uniswap Wallet";
      case ConnectionType.NETWORK:
        return "Network";
      case ConnectionType.GNOSIS_SAFE:
        return "Gnosis Safe";
    }
  }
};
