import { createSlice } from "@reduxjs/toolkit";
import { ConnectionType } from "connection/types";
import { SupportedLocale } from "constants/locales";
import { TradeAggregator } from "types/fusionx";

import { DEFAULT_DEADLINE_FROM_NOW } from "../../constants/misc";
import { updateVersion } from "../global/actions";
import { SerializedToken } from "./types";
import { Lender } from "types/lenderData/base";

const currentTimestamp = () => new Date().getTime();

export enum RouterPreference {
  AUTO = "auto",
  API = "api",
  CLIENT = "client",
}

export interface UserState {
  selectedWallet?: ConnectionType;

  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  matchesDarkMode: boolean; // whether the dark mode media query matches

  userDarkMode: boolean | null; // the user's choice for dark mode or light mode
  userLocale: SupportedLocale | null;

  userExpertMode: boolean;

  // which router should be used to calculate trades
  userRouterPreference: RouterPreference;

  userClientSideRouter: boolean; // whether routes should be calculated with the client side router only

  // hides closed (inactive) positions across the app
  userHideClosedPositions: boolean;

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number | "auto";
  userSlippageToleranceHasBeenMigratedToAuto: boolean; // temporary flag for migration status

  // deadline set by user in minutes, used in all txns
  userDeadline: number;

  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };

  selectedAggregators: TradeAggregator[];

  preferPermits: boolean;

  timestamp: number;
  URLWarningVisible: boolean;

  // undefined means has not gone through A/B split yet
  showSurveyPopup: boolean | undefined;

  showDonationLink: boolean;

  showTokensPromoBanner: boolean; // show tokens promo banner for token explore

  isAuthenticatedSwapBeta: boolean;

  selectedLender: Lender;

  topBarHidden: boolean;
}

export const initialState: UserState = {
  selectedWallet: undefined,
  preferPermits: true,
  matchesDarkMode: true,
  userDarkMode: true,
  userExpertMode: false,
  userLocale: null,
  userRouterPreference: RouterPreference.API,
  userClientSideRouter: false,
  userHideClosedPositions: false,
  userSlippageTolerance: "auto",
  userSlippageToleranceHasBeenMigratedToAuto: true,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  selectedAggregators: [TradeAggregator.OneInch, TradeAggregator.Paraswap],
  timestamp: currentTimestamp(),
  URLWarningVisible: true,
  showSurveyPopup: undefined,
  showDonationLink: true,
  showTokensPromoBanner: true,
  isAuthenticatedSwapBeta: false,
  selectedLender: Lender.INIT,
  topBarHidden: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet;
    },
    updateAggregators(state, { payload: { aggregatorList } }) {
      state.selectedAggregators = aggregatorList;
    },
    updateLender(state, { payload: { lender } }) {
      state.selectedLender = lender;
    },
    updatePreferPermit(state, { payload: { preferPermits } }) {
      state.preferPermits = preferPermits;
    },
    updateUserDarkMode(state, action) {
      state.userDarkMode = action.payload.userDarkMode;
      state.timestamp = currentTimestamp();
    },
    updateMatchesDarkMode(state, action) {
      state.matchesDarkMode = action.payload.matchesDarkMode;
      state.timestamp = currentTimestamp();
    },
    updateUserExpertMode(state, action) {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    },
    updateUserLocale(state, action) {
      state.userLocale = action.payload.userLocale;
      state.timestamp = currentTimestamp();
    },
    updateUserSlippageTolerance(state, action) {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    },
    updateUserDeadline(state, action) {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    },
    updateUserRouterPreference(state, action) {
      state.userRouterPreference = action.payload.userRouterPreference;
    },
    updateUserClientSideRouter(state, action) {
      state.userClientSideRouter = action.payload.userClientSideRouter;
    },
    updateHideClosedPositions(state, action) {
      state.userHideClosedPositions = action.payload.userHideClosedPositions;
    },
    updateShowSurveyPopup(state, action) {
      state.showSurveyPopup = action.payload.showSurveyPopup;
    },
    updateShowDonationLink(state, action) {
      state.showDonationLink = action.payload.showDonationLink;
    },
    updateShowTokensPromoBanner(state, action) {
      state.showTokensPromoBanner = action.payload.showTokensPromoBanner;
    },
    updateIsAuthenticated(state, action) {
      state.isAuthenticatedSwapBeta = action.payload.isAuthenticatedSwapBeta;
    },
    addSerializedToken(state, { payload: { serializedToken } }) {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] =
        state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] =
        serializedToken;
      state.timestamp = currentTimestamp();
    },
    removeSerializedToken(state, { payload: { address, chainId } }) {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[chainId] = state.tokens[chainId] || {};
      delete state.tokens[chainId][address];
      state.timestamp = currentTimestamp();
    },
    hideTopBar(state) {
      state.topBarHidden = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userSlippageTolerance !== "number" ||
        !Number.isInteger(state.userSlippageTolerance) ||
        state.userSlippageTolerance < 0 ||
        state.userSlippageTolerance > 5000
      ) {
        state.userSlippageTolerance = "auto";
      } else {
        if (
          !state.userSlippageToleranceHasBeenMigratedToAuto &&
          [10, 50, 100].indexOf(state.userSlippageTolerance) !== -1
        ) {
          state.userSlippageTolerance = "auto";
          state.userSlippageToleranceHasBeenMigratedToAuto = true;
        }
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userDeadline !== "number" ||
        !Number.isInteger(state.userDeadline) ||
        state.userDeadline < 60 ||
        state.userDeadline > 180 * 60
      ) {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    });
  },
});

export const {
  updateAggregators,
  updateLender,
  updatePreferPermit,
  updateSelectedWallet,
  addSerializedToken,
  removeSerializedToken,
  updateHideClosedPositions,
  updateMatchesDarkMode,
  updateShowDonationLink,
  updateShowSurveyPopup,
  updateUserClientSideRouter,
  updateUserDarkMode,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserLocale,
  updateUserSlippageTolerance,
  updateShowTokensPromoBanner,
  updateUserRouterPreference,
  updateIsAuthenticated,
  hideTopBar,
} = userSlice.actions;
export default userSlice.reducer;
