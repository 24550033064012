import { constructSameAddressMap } from "../utils/constructSameAddressMap";
import { SupportedChainId } from "./chains";

type AddressMap = { [chainId: number]: `0x${string}` };

export const UNI_ADDRESS: AddressMap = constructSameAddressMap(
  "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
);

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"
);
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap(
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
);

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES =
  "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc";
const CELO_ROUTER_ADDRESS = "0x5615CDAb10dc425a742d643d949a7F474C01abc4";
const CELO_V3_MIGRATOR_ADDRESSES = "0x3cFd4d48EDfDCC53D3f173F596f621064614C582";
// Uniswap interface Multicalls
const CELO_MULTICALL_ADDRESS = "0x633987602DE5C4F337e3DbF265303A1080324204";
const BNB_MULTICALL_ADDRESS = "0x963Df249eD09c358A4819E39d9Cd5736c3087184";
const MANTLE_MULTICALL_ADDRESS = "0x2A51f2F647E1883F88C992244dEd171238693107";
const CELO_QUOTER_ADDRESSES = "0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8";
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A";
const CELO_TICK_LENS_ADDRESSES = "0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D";

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0x1F98431c8aD98523631AE4a59f267346ea31F984", []),
};

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xA5644E29708357803b5A882D272c41cC0dF92B34", []),
};

/**
 * Uniswap Interface Multicall
 */
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap("0x1F98415757620B543A52E61c46B32eB19261F984", []),
  [SupportedChainId.MANTLE]: MANTLE_MULTICALL_ADDRESS,
};

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45", []),
};

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap =
  constructSameAddressMap("0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F");
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {};
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {};

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap(
  "0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
);

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {};

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {};

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6", []),
};

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xC36442b4a4522E871399CD717aBDD847Ab11FE88", []),
};

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {};

export const TICK_LENS_ADDRESSES: AddressMap = {};
