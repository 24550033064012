import { TokenInfo, TokenList } from "@uniswap/token-lists";
import { SupportedChainId } from "./chains";
import fusionxListLogo from "assets/svg/logos/fusionx-slim.png";

export const FUSIONX_LIST =
  "https://raw.githubusercontent.com/FusionX-Finance/tokens/main/lists/extended-tokens.json";

export const UNSUPPORTED_LIST_URLS: string[] = [];

// this is the default list of lists that are exposed to users
// lower index === higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
  // UNI_LIST,
  // UNI_EXTENDED_LIST,
  // COMPOUND_LIST,
  // AAVE_LIST,
  // CMC_ALL_LIST,
  // COINGECKO_LIST,
  // KLEROS_LIST,
  // GEMINI_LIST,
  // WRAPPED_LIST,
  // SET_LIST,
  // ROLL_LIST,
  // ARBITRUM_LIST,
  // OPTIMISM_LIST,
  // CELO_LIST,
  // // MANTLE_LIST,
  // FUSIONX_LIST_URL,
  FUSIONX_LIST,
];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [FUSIONX_LIST];

const IZI: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  address: "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
  decimals: 18,
  name: "iZUMi Token",
  symbol: "iZi",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/izumi.svg",
};

const iUSD: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  address: "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
  decimals: 18,
  name: "iZUMi Bond USD",
  symbol: "iUSD",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
};

const MINU: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  address: "0x51cfe5b1E764dC253F4c8C1f19a081fF4C3517eD",
  decimals: 18,
  name: "Mantle Inu Token",
  symbol: "MINU",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/minu.png",
};

const METH: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  address: "0xcda86a272531e8640cd7f1a92c01839911b90bb0",
  decimals: 18,
  name: "Mantle Staked Ether",
  symbol: "mETH",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/meth.png",
};

const WETH: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  address: "0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111",
  decimals: 18,
  name: "Wrapped Ether",
  symbol: "WETH",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/weth.png",
};

const CTT: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Cashtree Token",
  symbol: "CTT",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/ctt.png",
  address: "0x6A3b0eb5b57C9a4F5772fc900dAE427E65f8C1a5",
  decimals: 18,
};

const AXL: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Axelar Token",
  symbol: "AXL",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/axl.png",
  address: "0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f",
  decimals: 6,
};

const USDLR: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "USDLR by Stable",
  symbol: "USDLR",
  logoURI:
    "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdlr.png",
  address: "0x8fe7176f0bf63358ad9490fd24ac0bdb4dac33a8",
  decimals: 6,
};

const JOE: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Joe Token",
  symbol: "JOE",
  logoURI:
    "https://raw.githubusercontent.com/merchant-moe/moe-tokenlists/main/logos/0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07/logo.png",
  address: "0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07",
  decimals: 18,
};

const MOE: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Moe Token",
  symbol: "MOE",
  logoURI:
    "https://raw.githubusercontent.com/merchant-moe/moe-tokenlists/main/logos/0x4515A45337F461A11Ff0FE8aBF3c606AE5dC00c9/logo.png",
  address: "0x4515A45337F461A11Ff0FE8aBF3c606AE5dC00c9",
  decimals: 18,
};

const LIZ: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Theranos Coin",
  symbol: "LIZ",
  logoURI:
    "https://butter.xyz/_next/image?url=%2Fassets%2Fcurrencies%2Ficons%2FLIZ.png&w=128&q=100",
  address: "0xe449bf38e417b32245dc27552d8de8a733e830d5",
  decimals: 18,
};

const MEF: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "MEF Coin",
  symbol: "MEF",
  logoURI:
    "https://butter.xyz/_next/image?url=%2Fassets%2Fcurrencies%2Ficons%2FMEF.png&w=128&q=100",
  address: "0x050a0789b3b5ff6fe33e4a6727da9f4546e94804",
  decimals: 18,
};

const MVM: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "VelociMantle",
  symbol: "MVM",
  logoURI:
    "https://www.velocimeter.xyz/_next/image?url=%2Fimages%2Fmantle%2FMVM.png&w=1920&q=75",
  address: "0x861A6Fc736Cbb12ad57477B535B829239c8347d7",
  decimals: 18,
};

const CLEO: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Cleopatra",
  symbol: "CLEO",
  logoURI: "https://cleo.exchange/_next/static/media/emission.fbdcf1a8.svg",
  address: "0xC1E0C8C30F251A07a894609616580ad2CEb547F2",
  decimals: 18,
};

const NEADCLEO: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Ennead CLEO",
  symbol: "neadCleo",
  logoURI:
    "https://cleo.exchange/_next/image?url=https%3A%2F%2Fraw.githubusercontent.com%2FRamsesExchange%2Framses-assets%2Fmain%2Fblockchains%2Fmantle%2Fassets%2F0x999890b3A00EA4d60da39A65FA4B70bb0bdb61f7%2Flogo.png&w=32&q=75",
  address: "0x999890b3A00EA4d60da39A65FA4B70bb0bdb61f7",
  decimals: 18,
};

const GRAI: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Gravita Debt Token",
  symbol: "GRAI",
  logoURI:
    "https://cleo.exchange/_next/image?url=https%3A%2F%2Fraw.githubusercontent.com%2FRamsesExchange%2Framses-assets%2Fmain%2Fblockchains%2Fmantle%2Fassets%2F0x894134a25a5faC1c2C26F1d8fBf05111a3CB9487%2Flogo.png&w=32&q=75",
  address: "0x894134a25a5faC1c2C26F1d8fBf05111a3CB9487",
  decimals: 18,
};

const SFRAX: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Staked FRAX",
  symbol: "sFRAX",
  logoURI: "https://www.velocimeter.xyz/images/mantle/sFRAX.svg",
  address: "0xf3602C5A7f625181659445C8dDDde73dA15c22e3",
  decimals: 18,
};

const AXL_FRAX_MANTLE: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Axelar Wrapped FRAX",
  symbol: "axlFRAX",
  logoURI:
    "https://www.velocimeter.xyz/_next/image?url=%2Fimages%2Fmantle%2FaxlFRAX.png&w=1920&q=75",
  address: "0x406Cde76a3fD20e48bc1E0F60651e60Ae204B040",
  decimals: 18,
};

const AXL_SFRXETH_MANTLE: TokenInfo = {
  chainId: SupportedChainId.MANTLE,
  name: "Axelar Wrapped sfrxETH",
  symbol: "sfrxETH",
  logoURI:
    "https://www.velocimeter.xyz/_next/image?url=%2Fimages%2Fmantle%2FsfrxETH.png&w=1920&q=75",
  address: "0x4f74ca4a686203a5D4eBF6E8868c5eBC450bf283",
  decimals: 18,
};

export const ONE_FUSIONX_LIST: TokenList = {
  name: "fusionx Token List",
  logoURI: fusionxListLogo,
  timestamp: "1706554825",
  version: {
    major: 1,
    minor: 1,
    patch: 4,
  },
  tokens: [
    MOE,
    JOE,
    IZI,
    iUSD,
    MINU,
    CTT,
    AXL,
    USDLR,
    METH,
    WETH,
    LIZ,
    MEF,
    MVM,
    CLEO,
    GRAI,
    AXL_FRAX_MANTLE,
    AXL_SFRXETH_MANTLE,
    SFRAX,
    NEADCLEO,
  ],
  tokenMap: {
    [MOE.address]: MOE,
    [JOE.address]: JOE,
    [IZI.address]: IZI,
    [iUSD.address]: iUSD,
    [MINU.address]: MINU,
    [CTT.address]: CTT,
    [AXL.address]: AXL,
    [USDLR.address]: USDLR,
    [METH.address]: METH,
    [WETH.address]: WETH,
    [LIZ.address]: LIZ,
    [MEF.address]: MEF,
    [MVM.address]: MVM,
    [CLEO.address]: CLEO,
    [GRAI.address]: GRAI,
    [AXL_FRAX_MANTLE.address]: AXL_FRAX_MANTLE,
    [AXL_SFRXETH_MANTLE.address]: AXL_SFRXETH_MANTLE,
    [SFRAX.address]: SFRAX,
    [NEADCLEO.address]: NEADCLEO,
  },
};
