import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInitCoreContract,
  getInitLensContract,
  getLendingPoolContract,
  getMarginTradingHookContract,
  getPosManagerContract,
} from "hooks/fusionx/useFusionxContract";
import { TOKEN_META } from "constants/fusionx";
import { parseRawAmount } from "utils/tableUtils/prices";
import { addressToAsset } from "hooks/fusionx/addressesTokens";
import { getLenderAssets } from "constants/getAssets";
import { Lender } from "types/lenderData/base";
import { SupportedChainId } from "constants/chains";
import { initBrokerAddresses } from "hooks/fusionx/addressesFusionx";
import { Token } from "@fusionx-finance/sdk";
import { safeGetToken } from "hooks/fusionx/tokens";
import { SupportedAssets } from "types/fusionx";
import { BigNumber } from "ethers";
import { Divide } from "react-feather";

type posDataInfo = {
  token?: Token;
  assetId?: SupportedAssets;
  pool?: string;
  amount: number;
  amountUSD: number;
};

export interface InitPositionInfo {
  posId: string;
  posIdIndex: number;
  mode: number;
  healthFactor: BigNumber;
  isAllowed: boolean;
  isApprovedForAll: boolean;
  collateralInfo: posDataInfo;
  debtInfo: posDataInfo;
}

export interface InitPositionsInfo {
  [posId: string]: InitPositionInfo;
}

interface InitUserReserveResponse {
  chainId: number;
  positions: InitPositionsInfo;
  assets: string[];
  account: string;
}

export interface InitUserDataQueryParams {
  chainId: number;
  account?: string;
  prices: { [asset: string]: number };
}

export const fetchInitUserData: AsyncThunk<
  InitUserReserveResponse,
  InitUserDataQueryParams,
  any
> = createAsyncThunk<InitUserReserveResponse, InitUserDataQueryParams>(
  "lender-init/fetchInitUserData",
  // async ({ chainId, account, prices }) => {
  //   if (chainId !== SupportedChainId.MANTLE || !account)
  //     return {
  //       chainId,
  //       tokensData: {},
  //       assets: [],
  //       account: "",
  //     };
  //   const validOwner = account.toLowerCase();
  //   const lensContract = getInitLensContract(chainId);
  //   const brorkerAddress = initBrokerAddresses[chainId] ?? "";
  //   try {
  //     const data = await lensContract.callStatic.getUserPositionData(
  //       account,
  //       brorkerAddress
  //     );

  //     const indexes = data.posMeta
  //       .map((e, i) => {
  //         return { e, i };
  //       })
  //       .filter((a) => a.e.owner.toLowerCase() === validOwner)
  //       .map((a) => a.i);
  //     const assets = getLenderAssets(chainId, Lender.INIT);
  //     const result: any = Object.assign(
  //       {},
  //       ...indexes.map((index) => {
  //         const entry = data.posMeta[index];
  //         const mode = entry.mode;
  //         const id = entry.posId.toString();
  //         const collatData = Object.assign(
  //           {},
  //           ...data.collaterals[index].map((c) => {
  //             const asset = addressToAsset(chainId, c.underlying);
  //             const decimals = TOKEN_META[asset]?.decimals ?? 18;
  //             return {
  //               [asset]: {
  //                 deposits: parseRawAmount(c.amount.toString(), decimals),
  //               },
  //             };
  //           })
  //         );
  //         const debtData = Object.assign(
  //           {},
  //           ...data.debts[index].map((c) => {
  //             const asset = addressToAsset(chainId, c.underlying);
  //             const decimals = TOKEN_META[asset]?.decimals ?? 18;
  //             return {
  //               [asset]: {
  //                 debt: parseRawAmount(c.amount.toString(), decimals),
  //               },
  //             };
  //           })
  //         );

  //         return {
  //           [id]: {
  //             mode,
  //             isApprovedForAll: entry.isApprovedForAll,
  //             isAllowed:
  //               entry.approved.toLowerCase() === brorkerAddress.toLowerCase(),
  //             positions: Object.assign(
  //               {},
  //               ...assets.map((a) => {
  //                 const price = prices[a] ?? 1;
  //                 return {
  //                   [a]: {
  //                     deposits: collatData[a]?.deposits ?? 0,
  //                     debt: debtData[a]?.debt ?? 0,
  //                     debtStable: 0,
  //                     debtStableUSD: 0,
  //                     depositsUSD: price * (collatData[a]?.deposits ?? 0),
  //                     debtUSD: price * (debtData[a]?.debt ?? 0),
  //                     collateralActive: true,
  //                   },
  //                 };
  //               })
  //             ),
  //           },
  //         };
  //       })
  //     );

  //     return {
  //       chainId,
  //       tokensData: result,
  //       assets,
  //       account,
  //     };
  //   } catch (e: any) {
  //     console.log("Error fetching init data", e);
  //   }
  //   return {
  //     chainId,
  //     tokensData: {},
  //     assets: [],
  //     account: "",
  //   };
  // }
  async ({ chainId, account, prices }) => {
    if (chainId !== SupportedChainId.MANTLE || !account)
      return {
        chainId,
        positions: {},
        assets: [],
        account: "",
      };
    try {
      const marginTraderContract = getMarginTradingHookContract(chainId);
      const positionManagerContract = getPosManagerContract(chainId);
      const initCoreContract = getInitCoreContract(chainId);
      const assets = getLenderAssets(chainId, Lender.INIT);
      const totalPositionLength = await marginTraderContract.lastPosIds(
        account
      );

      const positionIdsCall = [];
      for (let index = 1; index <= Number(totalPositionLength); index++) {
        positionIdsCall.push(marginTraderContract.initPosIds(account, index));
      }
      const posIds = await Promise.all(positionIdsCall);

      const posHealthFactor = await Promise.all(
        posIds.map((posId) =>
          initCoreContract.callStatic.getPosHealthCurrent_e18(posId)
        )
      );

      const modes = await Promise.all(
        posIds.map((posId) => positionManagerContract.getPosMode(posId))
      );
      const posBorrInfos = await Promise.all(
        posIds.map((posId) => positionManagerContract.getPosBorrInfo(posId))
      );
      const posCollInfo = await Promise.all(
        posIds.map((posId) => positionManagerContract.getPosCollInfo(posId))
      );

      const borrAmounts = await Promise.all(
        posBorrInfos.map((borrInfo) =>
          borrInfo.pools[0]
            ? getLendingPoolContract(
                borrInfo.pools[0],
                chainId
              ).debtShareToAmtStored(borrInfo.debtShares[0])
            : BigNumber.from(0)
        )
      );

      // Note: collateral amount must be converted
      const collAmounts = await Promise.all(
        posCollInfo.map(async (collInfo) =>
          collInfo.pools[0]
            ? getLendingPoolContract(collInfo.pools[0], chainId).toAmt(
                collInfo.amts[0]
              )
            : BigNumber.from(0)
        )
      );
      const marginPosInfo = await Promise.all(
        posIds.map((posId) => marginTraderContract.getMarginPos(posId))
      );

      // const posBorrTokenAddresses = await Promise.all(
      //   posBorrInfos.map(async (borrInfo) =>
      //     borrInfo.pools[0]
      //       ? getLendingPoolContract(
      //           borrInfo.pools[0],
      //           chainId
      //         ).underlyingToken()
      //       : undefined
      //   )
      // );

      // const posCollTokenAddresses = await Promise.all(
      //   posCollInfo.map(async (collInfo) =>
      //     collInfo.pools[0]
      //       ? getLendingPoolContract(
      //           collInfo.pools[0],
      //           chainId
      //         ).underlyingToken()
      //       : undefined
      //   )
      // );

      const result: any = Object.assign(
        {},
        ...posIds.map((posId, index) => {
          const mode = modes[index];
          const { collPool, borrPool, baseAsset, quoteAsset, isLongBaseAsset } =
            marginPosInfo[index];
          const collTokenAddress = isLongBaseAsset ? baseAsset : quoteAsset;
          const debtTokenAddress = isLongBaseAsset ? quoteAsset : baseAsset;

          const collAssetId = addressToAsset(chainId, collTokenAddress);
          const collToken = safeGetToken(chainId, collAssetId);
          const debtAssetId = addressToAsset(chainId, debtTokenAddress);
          const debtToken = safeGetToken(chainId, debtAssetId);

          const collateralInfo = {
            token: collToken,
            assetId: collAssetId,
            pool: posCollInfo[index].pools[0],
            amount: parseRawAmount(
              collAmounts[index].toString(),
              collToken.decimals ?? 18
            ),
            amountUSD:
              parseRawAmount(
                collAmounts[index].toString(),
                collToken.decimals ?? 18
              ) * (prices[collAssetId] ?? 0),
          };

          const debtInfo = {
            token: debtToken,
            assetId: debtAssetId,
            pool: posBorrInfos[index].pools[0],
            amount: parseRawAmount(
              borrAmounts[index].toString(),
              debtToken.decimals ?? 18
            ),
            amountUSD:
              parseRawAmount(
                borrAmounts[index].toString(),
                debtToken.decimals ?? 18
              ) * (prices[debtAssetId] ?? 0),
          };

          return {
            [posId.toString()]: {
              posId,
              posIdIndex: index + 1,
              mode,
              healthFactor: posHealthFactor[index],
              isApprovedForAll: true, //TODO entry.isApprovedForAll,
              isAllowed: true, //TODO entry.approved.toLowerCase() === brorkerAddress.toLowerCase(),
              collateralInfo,
              debtInfo,
            },
          };
        })
      );

      return {
        chainId,
        positions: result,
        assets,
        account,
      };
    } catch (e: any) {
      console.log("Error fetching init data", e);
    }

    return {
      chainId,
      positions: {},
      assets: [],
      account: "",
    };
  }
);
