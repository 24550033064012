import { createReducer } from "@reduxjs/toolkit";
import { DEFAULT_CHAINID, STATE_CHAINIDS } from "constants/chains";
import {
  setChainId,
  setAccount,
  setIsSupported,
  setBlockNumber,
  setIsLoading,
  setImpersonatedAccount,
  setUseImpersonatedAccount,
} from "./actions";
import { fetchTimestamp, fetchBlockDataAndBalances } from "./fetchGeneralData";
import { getAllLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";

export interface GlobalNetworkState {
  readonly chainId: number;
  readonly account: string | undefined;
  readonly impersontedAccount: string | undefined;
  readonly useImpersonatedAccount: boolean;
  readonly connectionIsSupported: boolean;
  readonly loading: boolean;
  networkData: {
    [chainId: number]: {
      readonly lastTimestamp: string;
      readonly blockNumber: number;
      readonly nativeBalance: string | undefined;
      readonly balances: { [asset: string]: string };
    };
  };
}

const initialChainId = Number(DEFAULT_CHAINID);

const initialData = {
  lastTimestamp: String(Math.round(Date.now() / 1000)),
  blockNumber: 0,
  nativeBalance: undefined,
};

const initialState: GlobalNetworkState = {
  chainId: initialChainId,
  account: undefined,
  impersontedAccount: undefined,
  useImpersonatedAccount: false,
  connectionIsSupported: true,
  loading: false,
  networkData: Object.assign(
    {},
    ...STATE_CHAINIDS.map((chainId) => {
      return {
        [chainId]: {
          ...initialData,
          balances: Object.assign(
            {},
            ...Object.keys(getAllLenderTokenAddresses(chainId)).map((x) => {
              return { [x]: "0" };
            })
          ),
        },
      };
    })
  ),
};

export default createReducer<GlobalNetworkState>(initialState, (builder) =>
  builder
    .addCase(setChainId, (state, { payload: { chainId } }) => {
      if (chainId) {
        state.chainId = chainId;
      }
    })
    .addCase(setImpersonatedAccount, (state, { payload: { account } }) => {
      state.impersontedAccount = account;
    })
    .addCase(setUseImpersonatedAccount, (state, { payload: { isUsed } }) => {
      state.useImpersonatedAccount = isUsed;
    })
    .addCase(setIsLoading, (state, { payload: { loading } }) => {
      state.loading = loading;
    })
    .addCase(setAccount, (state, { payload: { account } }) => {
      state.account = account;
    })
    .addCase(setBlockNumber, (state, { payload: { blockNumber, chainId } }) => {
      state.networkData[chainId].blockNumber = blockNumber;
    })
    .addCase(setIsSupported, (state, { payload: { isSupported } }) => {
      state.connectionIsSupported = isSupported;
    })
    .addCase(fetchTimestamp.fulfilled, (state, action) => {
      state.networkData[action.payload.chainId].lastTimestamp =
        action.payload.timestamp;
    })
    .addCase(fetchBlockDataAndBalances.fulfilled, (state, action) => {
      const chainId = action.payload.chainId;
      if (
        Number(state.networkData[chainId].lastTimestamp) <
        Number(action.payload.timestamp)
      ) {
        state.networkData[chainId].lastTimestamp = action.payload.timestamp;
      }
      if (state.networkData[chainId].blockNumber < action.payload.blockNumber) {
        state.networkData[chainId].blockNumber = action.payload.blockNumber;
        // we do not want to fetch old balances
        state.networkData[chainId].nativeBalance = action.payload.nativeBalance;
      }

      const assetKeys = Object.keys(action.payload.balances);
      for (let i = 0; i < assetKeys.length; i++)
        state.networkData[chainId].balances[assetKeys[i]] =
          action.payload.balances[assetKeys[i]];
    })
);
