import { Color, ColorNames, Colors } from "./styled";
import { opacify } from "./utils";

export const colors: Colors = {
  white: "#F9F9F9",
  white100: "#F7F8FA",
  white200: "#EDEEF2",

  black: "#000000",
  black425T: "rgba(0, 0, 0, 0.425)",
  black300T: "rgba(0, 0, 0, 0.3)",

  gray50: "#F5F6FC",
  gray100: "#E8ECFB",
  gray200: "#C3C5CB",
  gray300: "#9F9F9F",
  gray400: "#7C85A2",
  gray450: "#6C7284",
  gray500: "#565A69",
  gray600: "#334A5B",
  gray650: "#464646",
  gray700: "#1F313F",
  gray725: "#1A232C",
  gray750: "#192834",
  gray775: "#212429",
  gray800: "#141B2B",
  gray850: "#141C24",
  gray900: "#0E111A",

  red200: "#FD766B",
  red300: "#FF4343",
  red400: "#FE3653",
  red500: "#DA2D2B",
  red600: "#D60000",
  redTransparent: "#ff434370",

  yellow400: "#F3B71E",
  yellow500: "#FF8F00",

  gold200: "#EEB317",

  green25: "#b75e29",
  green50: "#b5561e",
  green100: "#ba4e0d",
  green200: "#ba4e0d",
  green250: "#ba4e0d",
  green300: "#ba4e0d",
  green350: "#ba4e0d",
  green400: "#ba4e0d",
  green600: "#ba4e0d",
  green650: "#ba4e0d",
  green700: "#ba4e0d",
  greenTransparent: "#00a51c42",

  blueGray: "#265056",
  blueNavyGray: "#222b35",
  blueNavyGrayLight: "#28323e",

  greenGray: "#799a8a",

  turquoise: "#E86110",

  magenta: "#AD3B8F",
  magentaVibrant: "#FC72FF",

  lilac: "#7055ab",

  success: "#22c55e",
  border_white: "#7c7c7c",
  dark_tertiary: "#353547",

  shadow100:
    "12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32)",
  shadow200:
    "8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02), 4px 4px 8px rgba(51, 53, 72, 0.04)",
  green125: function (arg0: number, green125: any): string {
    throw new Error("Function not implemented.");
  },
  green500: function (arg0: number, green500: any): string {
    throw new Error("Function not implemented.");
  },
  darkMode: false,
  deprecated_white: "",
  deprecated_black: "",
  deprecated_text1: "",
  deprecated_text2: "",
  deprecated_text3: "",
  deprecated_text4: "",
  deprecated_text5: "",
  deprecated_bg0: "",
  deprecated_bg1: "",
  deprecated_bg2: "",
  deprecated_bg3: "",
  deprecated_bg4: "",
  deprecated_bg5: "",
  deprecated_bg6: "",
  deprecated_modalBG: "",
  deprecated_advancedBG: "",
  deprecated_primary1: "",
  deprecated_primary2: "",
  deprecated_primary3: "",
  deprecated_primary4: "",
  deprecated_primary5: "",
  deprecated_primaryText1: "",
  deprecated_secondary1: "",
  deprecated_secondary2: "",
  deprecated_secondary3: "",
  deprecated_red1: "",
  deprecated_red2: "",
  deprecated_red3: "",
  deprecated_green1: "",
  deprecated_yellow1: "",
  deprecated_yellow2: "",
  deprecated_yellow3: "",
  deprecated_blue1: "",
  deprecated_blue2: "",
  deprecated_blue4: "",
  deprecated_error: "",
  deprecated_success: "",
  deprecated_warning: "",
  backgroundSecondary: "",
  subText: "",
  backgroundDark: "",
  container_border: "",
  accentDark: "",
};

type ThemedColorNames = {
  [key in keyof ColorNames]: [Color, Color];
};

// first key is dark mode, second key is light mode
export const colorNames: ThemedColorNames = {
  white: [colors.white, colors.white],
  black: [colors.black, colors.black],

  userThemeColor: [colors.magentaVibrant, colors.magentaVibrant],

  backgroundPrimary: [colors.gray750, colors.white],
  backgroundSecondary: ["rgba(17, 17, 17, 1)", colors.white100],
  backgroundTertiary: ["#111", colors.white200],
  backgroundDark: ["#0A0909", colors.gray200],
  backgroundLight: [colors.gray500, colors.gray400],
  backgroundLighter: [colors.gray450, colors.gray400],
  backgroundNavyGray: [colors.blueNavyGray, colors.blueNavyGray],
  backgroundNavyGrayLight: [colors.blueNavyGrayLight, colors.blueNavyGrayLight],

  backgroundSurface: [colors.gray900, colors.white],
  backgroundModule: [colors.gray800, colors.gray50],
  backgroundInteractive: [colors.gray700, colors.gray100],
  backgroundOutline: [opacify(24, colors.gray200), opacify(24, colors.gray500)],
  backgroundScrim: [opacify(72, colors.gray900), opacify(60, colors.gray900)],
  backgroundEmphasized: [colors.blueGray, opacify(40, colors.blueGray)],

  textPrimary: [colors.white, colors.gray900],
  textSecondary: [colors.gray200, colors.gray500],
  textTertiary: [colors.gray500, colors.gray200],
  textAccent: [colors.green600, colors.green600],
  textAccentTurquoise: [colors.turquoise, colors.turquoise],
  textAccentMagenta: [colors.magenta, colors.magenta],

  accentAction: [colors.green400, colors.green100],
  accentActionSoft: [
    opacify(24, colors.green400),
    opacify(24, colors.green100),
  ],
  accentActive: [colors.green400, colors.green400],
  accentSuccess: [colors.green200, colors.green300],
  accentWarning: [colors.gold200, colors.gold200],
  accentFailure: [colors.red200, colors.red400],

  modalBG: [colors.black425T, colors.black300T],
  subText: [colors.gray300, colors.gray300],

  accent: [colors.green100, colors.green700],
  accentLight: [colors.green25, colors.green25],
  accentDark: [colors.green400, colors.green300],
  accentDarker: [colors.green650, colors.green250],
  accentDarkest: [colors.green700, colors.green50],

  iconInner: [colors.gray600, colors.green50],
  iconOuter: [colors.border_white, colors.green50],

  changeRed: [colors.red400, colors.red400],
  changeGreen: [colors.success, colors.green50],

  primaryButton: [colors.green100, colors.green700],
  primaryButtonDisabled: [opacify(50, colors.gray600), colors.green700],
  primaryTextButton: [colors.white, colors.white],
  primaryTextButtonDisabled: [opacify(50, colors.white), colors.white],
  primaryButtonHover: [colors.green25, colors.green25],
  primaryButtonActive: [colors.green200, colors.green200],

  secondaryButton: [colors.gray700, colors.green200],
  secondaryButtonDisabled: [opacify(50, colors.gray450), colors.greenGray],
  secondaryTextButton: [colors.white, colors.gray650],
  secondaryButtonBorder: [colors.border_white, colors.border_white],

  tertiaryButtonDisabled: [opacify(20, colors.gray100), colors.green300],
  tertiaryButton: [opacify(20, colors.gray100), colors.green300],
  tertiaryTextButton: [colors.white, colors.gray650],
  tertiaryButtonBorder: [colors.gray650, colors.gray600],

  red: [colors.red300, colors.red500],
  redDark: [colors.red600, colors.red600],
  green: [colors.green350, colors.green600],
  yellow: [colors.yellow400, colors.yellow400],

  error: [colors.red300, colors.red500],
  errorTransparent: [colors.redTransparent, colors.redTransparent],
  success: [colors.green350, colors.green600],
  successTransparent: [colors.greenTransparent, colors.greenTransparent],
  warning: [colors.yellow500, colors.yellow500],

  container_border: [colors.border_white, colors.gray100],
  dropdownSubheading: [colors.gray200, colors.gray200],

  shadow: [colors.black, colors.green50],
  deepShadow: [colors.shadow100, colors.shadow200],

  lendleAccent: [colors.lilac, colors.lilac],
  hoverState: [colors.green400, colors.lilac],
  hoverDefault: [colors.lilac, colors.lilac],
};
