import { Lender } from "types/lenderData/base";
import { SupportedChainId } from "./chains";
import { SupportedAssets, aaveAssets, compoundAssets } from "types/fusionx";

export const getLenderAssets = (
  chainId: number,
  lendingProtocol = Lender.INIT
): SupportedAssets[] => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      switch (lendingProtocol) {
        case Lender.INIT: {
          return [
            SupportedAssets.WBTC,
            SupportedAssets.WETH,
            SupportedAssets.METH,
            SupportedAssets.USDCE,
            SupportedAssets.USDT,
            SupportedAssets.WMNT,
            // SupportedAssets.USDY,
          ];
        }
        default:
          return [];
      }
    }
    default:
      return [
        SupportedAssets.WETH,
        SupportedAssets.DAI,
        SupportedAssets.USDCE,
        SupportedAssets.WBTC,
        SupportedAssets.USDT,
      ];
  }
};

// for oracles
export const allSupportedAssets: { [chainId: number]: SupportedAssets[] } = {};

export const getVenusCoreAssets = () => {
  return [
    SupportedAssets.AAVE,
    SupportedAssets.ADA,
    SupportedAssets.BCH,
    SupportedAssets.BNB,
    SupportedAssets.BETH,
    SupportedAssets.BTCB,
    SupportedAssets.BUSD,
    SupportedAssets.CAKE,
    SupportedAssets.DAI,
    SupportedAssets.DOGE,
    SupportedAssets.DOT,
    SupportedAssets.WETH,
    SupportedAssets.FIL,
    SupportedAssets.LINK,
    SupportedAssets.LTC,
    SupportedAssets.LUNA,
    SupportedAssets.WMATIC,
    SupportedAssets.SXP,
    SupportedAssets.TRX,
    SupportedAssets.TRXOLD,
    SupportedAssets.TUSD,
    SupportedAssets.TUSDOLD,
    SupportedAssets.BUSDC,
    SupportedAssets.BUSDT,
    SupportedAssets.UST,
    SupportedAssets.WBETH,
    SupportedAssets.XRP,
    SupportedAssets.XVS,
  ];
};
