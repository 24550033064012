import { getAvailableChainIds } from "constants/chains";
import { useEffect, useMemo } from "react";
import { flushAccount, setToLoading } from "state/fusionx/actions";
import {
  setAccount,
  setChainId,
  setIsSupported,
} from "state/globalNetwork/actions";
import { useNetworkState } from "state/globalNetwork/hooks";
import { useAppDispatch } from "state/hooks";
import { resetPnLs, setOraclesToLoading } from "state/oracles/actions";
import { trackAccountSwitched } from "utils/analytics";
import { ViewMode } from "./viewMode/useViewMode";

// sets the chainId and account if provided by web3
// in case of a change, everything is set to loading for a state refresh
export function useChainIdAndAccountHandling(
  chainIdWeb3: number | undefined,
  accountFromWeb3: string | undefined,
  viewMode: ViewMode
) {
  const { chainId, account: accountFromState } = useNetworkState();

  const availabelChainIds = useMemo(
    () => getAvailableChainIds(viewMode),
    [viewMode]
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    // connected, but not to current chain
    if (chainIdWeb3 && chainId === chainIdWeb3 && accountFromWeb3) {
      dispatch(setChainId({ chainId: chainIdWeb3 }));
      dispatch(setAccount({ account: accountFromWeb3 }));
      dispatch(setToLoading());
      dispatch(setOraclesToLoading());
      if (accountFromWeb3 !== accountFromState) {
        dispatch(resetPnLs());
      }
    } else {
      if (!accountFromWeb3) {
        dispatch(flushAccount());
      } else {
        dispatch(setAccount({ account: accountFromWeb3 }));
      }
    }
    if (
      accountFromWeb3 &&
      accountFromWeb3 !== accountFromState &&
      accountFromState !== undefined
    ) {
      trackAccountSwitched(accountFromWeb3);
    }
  }, [dispatch, chainId, chainIdWeb3, accountFromWeb3, viewMode]);

  useEffect(() => {
    dispatch(
      setIsSupported({
        isSupported: availabelChainIds.includes(Number(chainIdWeb3)),
      })
    );
  }, [chainIdWeb3, viewMode]);
}
