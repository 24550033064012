import { getAvailableChainIds, SupportedChainId } from "constants/chains";
import { ViewMode } from "hooks/viewMode/useViewMode";

/**
 * Returns the input chain ID if chain is supported. If not, return undefined
 * @param chainId a chain ID, which will be returned if it is a supported chain ID
 */
export function supportedChainId(
  chainId: number | undefined,
  viewMode = ViewMode.ALL
): SupportedChainId | undefined {
  if (
    typeof chainId === "number" &&
    getAvailableChainIds(viewMode).includes(chainId)
  ) {
    return chainId;
  }
  return undefined;
}
