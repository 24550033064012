import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { chainlinkOracles, getChainLinkKeys } from "hooks/fusionx/addresses";
import { Call, multicallSecondary } from "utils/multicall";
import CHAINLINK_AGGREGATOR_ABI from "abis/chainlink/ChainLinkAggregator.json";

export interface ChainLinkRawResponse extends RawOracleResponse {
  roundId: number;
}

interface ChainLinkAggregatedResponse {
  data: {
    [key: string]: ChainLinkRawResponse;
  };
  chainId: number;
}

interface ChainLinkQueryParams {
  chainId: number;
}

export const fetchChainLinkData: AsyncThunk<
  ChainLinkAggregatedResponse,
  ChainLinkQueryParams,
  any
> = createAsyncThunk<ChainLinkAggregatedResponse, ChainLinkQueryParams>(
  "oracles/fetchChainLinkData",
  async ({ chainId }) => {
    const keys = getChainLinkKeys(chainId);
    const addresses = keys.map((k) => chainlinkOracles[k][chainId]);
    const calls: Call[] = addresses.map((tk) => {
      return {
        address: tk,
        name: "latestRoundData",
        params: [],
      };
    });
    const multicallResult = await multicallSecondary(
      chainId,
      CHAINLINK_AGGREGATOR_ABI,
      calls
    );
    const result = Object.assign(
      {},
      ...multicallResult.map((entry, index) => {
        return {
          [keys[index]]: {
            roundId: Number(entry?.roundId?.toString()) ?? 0,
            price: entry?.answer?.toString() ?? "0",
            time: Number(entry?.updatedAt?.toString() ?? "0"),
          },
        };
      })
    );

    return { data: result, chainId };
  }
);
