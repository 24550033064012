import { useEffect } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import { useChainId } from 'state/globalNetwork/hooks'

const initialStyles = {
  width: '200vw',
  height: '200vh',
  transform: 'translate(-50vw, -100vh)',
  backgroundBlendMode: '',
}
const backgroundResetStyles = {
  width: '100vw',
  height: '100vh',
  transform: 'unset',
  backgroundBlendMode: '',
}

type TargetBackgroundStyles = typeof initialStyles | typeof backgroundResetStyles

const backgroundRadialGradientElement = document.getElementById('background-radial-gradient')
const setBackground = (newValues: TargetBackgroundStyles) =>
  Object.entries(newValues).forEach(([key, value]) => {
    if (backgroundRadialGradientElement) {
      backgroundRadialGradientElement.style[key as keyof typeof backgroundResetStyles] = value
    }
  })
export default function RadialGradientByChainUpdater(): null {
  const chainId = useChainId()
  const [darkMode] = useDarkModeManager()
  // manage background color
  useEffect(() => {
    if (!backgroundRadialGradientElement) {
      return
    }
    setBackground(backgroundResetStyles)
    const polygonLightGradient = 'radial-gradient(circle at top right, rgba(11, 176, 11, 0.0864) 0%, rgba(0, 41, 255, 0.06) 48.19%, rgba(0, 41, 255, 0.012) 100%), #FFFFFF'
    const polygonDarkGradient = '#141C24'
    backgroundRadialGradientElement.style.background = darkMode ? polygonDarkGradient : polygonLightGradient
    backgroundRadialGradientElement.style.backgroundBlendMode = darkMode
      ? 'overlay,normal'
      : 'multiply,normal'
  }, [darkMode, chainId])
  return null
}
