import axios from "axios";
import {
  DexscreenerPair,
  DexscreenerPairReduced,
  chainIdToDexscreenerChainId,
} from "types/fusionx";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { CurrencyAmount, Token } from "@fusionx-finance/sdk";
import { getNativeAddress } from "hooks/fusionx/addressesTokens";

interface DexscreenerPairsResponse {
  data: {
    [tokenAddress: string]: DexscreenerPairReduced;
  };
  chainId: number;
  success: boolean;
}

interface DexscreenerPairsArgs {
  chainId: number;
  balances: {
    [tokenAddress: string]: CurrencyAmount<Token> | undefined;
  };
}

const findBestPair = async (
  tokenAddress: string,
  chainId: number,
  balance?: number
) => {
  const query = "https://api.dexscreener.com/latest/dex/tokens/";
  let pairs: DexscreenerPair[] = (await axios.get(query + tokenAddress)).data
    .pairs;
  if (pairs === null || pairs.length === 0) {
    return undefined;
  }
  // Sort by liquidity
  pairs.sort((a, b) => {
    const aLiquidity = (a.liquidity?.usd && +a.liquidity.usd) || 0;
    const bLiquidity = (b.liquidity?.usd && +b.liquidity.usd) || 0;
    return bLiquidity - aLiquidity;
  });
  // Remove pairs where baseToken.address is not the same as tokenAddress
  // (i.e., the token we're looking for is the quoteToken)
  pairs = pairs.filter(
    (pair) =>
      pair.baseToken.address.toLowerCase() === tokenAddress.toLowerCase()
  );
  // Filter by chainId
  const pair = pairs.find(
    (pair) => pair.chainId === chainIdToDexscreenerChainId[chainId]
  );

  if (!pair) return undefined;

  const priceUsd = (pair.priceUsd && +pair.priceUsd) || 0;
  const pairReduced: DexscreenerPairReduced = {
    tokenAddress: tokenAddress,
    pairAddress: pair.pairAddress,
    priceUsd,
    assetId: pair.baseToken.symbol,
    assetName: pair.baseToken.name,
    userBalance: balance || undefined,
    userBalanceUsd: balance ? balance * priceUsd : undefined,
  };
  return pairReduced;
};

/**
 * Fetches the dexscreener pairs for the given chainId,
 * just for the tokens with non-zero balances for the user
 */
export const fetchDexscreenerPairs: AsyncThunk<
  DexscreenerPairsResponse,
  DexscreenerPairsArgs,
  any
> = createAsyncThunk<DexscreenerPairsResponse, DexscreenerPairsArgs>(
  "oracles/fetchDexscreenerPrices",
  async ({ chainId, balances }: DexscreenerPairsArgs) => {
    if (Object.keys(balances).length === 0)
      return { data: {}, chainId, success: false };

    // remove the native token as we'll add it later
    let nativeAddress = getNativeAddress(chainId);
    delete balances[nativeAddress];

    let nonZeroBalancesAddresses = Object.keys(balances).filter(
      (key) => balances[key]?.toExact() !== "0"
    );

    let pairs: {
      [tokenAddress: string]: DexscreenerPairReduced;
    } = {};

    for (const address of nonZeroBalancesAddresses) {
      const stringBalance = balances?.[address]?.toExact();
      const intBalance = (stringBalance && +stringBalance) || 0;
      const pair = await findBestPair(address, chainId, intBalance);
      pair && (pairs[address] = pair);
    }

    return {
      data: pairs,
      chainId,
      success: true,
    };
  }
);

interface DexscreenerPairResponse {
  data: {
    [tokenAddress: string]: DexscreenerPairReduced;
  };
  chainId: number;
  success: boolean;
}

interface DexscreenerPairArgs {
  chainId: number;
  tokenAddress: string;
}

export const fetchDexscreenerPair: AsyncThunk<
  DexscreenerPairResponse,
  DexscreenerPairArgs,
  any
> = createAsyncThunk<DexscreenerPairResponse, DexscreenerPairArgs>(
  "oracles/fetchDexscreenerPair",
  async ({ chainId, tokenAddress }: DexscreenerPairArgs) => {
    if (!tokenAddress) return { data: {}, chainId, success: false };
    const pair = await findBestPair(tokenAddress, chainId);
    if (pair) {
      return {
        data: {
          [tokenAddress.toLowerCase()]: pair,
        },
        chainId,
        success: true,
      };
    } else {
      return { data: {}, chainId, success: false };
    }
  }
);
