import React from "react";
import { styled } from "styled-components";

const ResponsiveLogo = styled.img<{ size?: string }>`
  width: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  height: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  min-width: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  min-height: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  max-width: auto;
`;

const Icon: React.FC<React.PropsWithChildren<any>> = (props) => {
  return <ResponsiveLogo src="https://fusionx.finance/logo.png" {...props} />;
};

export default Icon;
