import { createReducer } from "@reduxjs/toolkit";
import { SupportedChainId } from "constants/chains";
import {
  DexscreenerPairReduced,
  SupportedAssets,
  toOracleKey,
} from "types/fusionx";
import { formatAavePrice, parseRawAmount } from "utils/tableUtils/prices";
import {
  resetPnLs,
  resetState,
  setHistPrices,
  setOraclesToLoading,
  setPnL,
} from "./actions";
import { ChainLinkRawResponse, fetchChainLinkData } from "./fetchChainLinkData";
import {
  fetchDexscreenerPair,
  fetchDexscreenerPairs,
} from "./fetchDexscreenerPairs";
import { fetchOracleData } from "./fetchOracleData";
import { fetchViaApi } from "./fetchViaApi";
import { fetchStakingYields } from "./staking/fetchStakingYields";
import { PnLData } from "./utils";

interface ParsedResponse {
  price: number;
  time: number;
}

interface ChainLinkResponse extends ParsedResponse {
  roundId: number;
}

export const parseGeneralResponse = (
  r: any,
  c: SupportedChainId
): ParsedResponse => {
  return {
    price: formatAavePrice(r.price, c),
    time: Number(r.time),
  };
};

export const parseChainLinkResponse = (
  r: ChainLinkRawResponse,
  d: number
): ChainLinkResponse => {
  return {
    roundId: r.roundId,
    price: parseRawAmount(r.price, d),
    time: Number(r.time),
  };
};

export interface TokenAddressesToDexscreenerPair {
  [chainId: number]: {
    [tokenAddress: string]: DexscreenerPairReduced;
  };
}

interface PnLEntry {
  pnl: PnLData;
  refBalance: number;
  loaded: boolean;
}

export interface OracleState {
  history: { [key: string]: number };
  live: { [key: string]: number };
  liveLoaded: boolean;
  apiLoaded: boolean;
  ref: { [key: string]: number };
  dexscreenerPairs: TokenAddressesToDexscreenerPair;
  pnl: { [chainId: number]: { [lender: string]: PnLEntry } };
  txns: { [chainId: number]: { [lender: string]: any[] } }; // TODO AaveTypeTx
  staking: {
    assetsYields: { [key: string]: number };
    loaded: boolean;
  };
}

const getDecimals = (k: string) => {
  const ccyTo = k.split("-")[1];
  switch (ccyTo) {
    case "ETH":
    case "MATIC":
      return 18;
    case "USD":
      return 8;
    case "USDC":
    case "USDC.E":
      return 8;
    default:
      return 8;
  }
};

export const initialState: OracleState = {
  history: {},
  live: {},
  liveLoaded: false,
  apiLoaded: false,
  ref: {},
  dexscreenerPairs: {},
  pnl: {},
  txns: {},
  staking: {
    assetsYields: {},
    loaded: false,
  },
};

export default createReducer<OracleState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(setOraclesToLoading, (state) => {})
    .addCase(setHistPrices, (state, action) => {
      const histKeys = Object.keys(action.payload.prices);
      for (let i = 0; i < histKeys.length; i++) {
        const key = histKeys[i];
        const oracleKey = toOracleKey(histKeys[i]);
        // only add non-zeros
        if (action.payload.prices[key] > 0) {
          state.history[oracleKey] = action.payload.prices[key];
        }
      }
      // this is to extrapolate the mETH hist price from the weth hist price
      const weth = SupportedAssets.WETH;
      const meth = SupportedAssets.METH;
      const methPrice = state.live[meth];
      const wethPrice = state.live[weth];
      const wethHist = state.history[weth];
      if (wethPrice && methPrice && wethHist) {
        state.history[meth] = (methPrice * wethHist) / wethPrice;
      }
    })
    // simple pnl
    .addCase(setPnL, (state, action) => {
      const { chainId, lender } = action.payload;
      if (!state.pnl?.[chainId]) state.pnl[chainId] = {};
      const { pnl, refBalance } = action.payload;
      state.pnl[chainId][lender] = { pnl, refBalance, loaded: true };
    })
    // public data fetch
    .addCase(fetchChainLinkData.pending, (state) => {
      // state.userDataLoading = true
    })
    .addCase(fetchChainLinkData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data);
      const chainId = action.payload.chainId;
      for (let i = 0; i < assetKeys.length; i++) {
        const key = assetKeys[i];
        const priceData = parseChainLinkResponse(
          action.payload.data[key],
          getDecimals(key)
        );
      }
    })
    .addCase(fetchViaApi.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data);

      for (let i = 0; i < assetKeys.length; i++) {
        const key = assetKeys[i];
        state.history[key] = action.payload.data[key].price24H;
        if (key !== "WMNT") {
          if (state.live[key] && state.live[key] > 0) {
            state.ref[key] = state.live[key];
          }
          state.live[key] = action.payload.data[key].price;
        }
      }
      state.apiLoaded = true;
    })
    .addCase(fetchViaApi.pending, (state) => {
      //
    })
    // multifetch oracles
    .addCase(fetchOracleData.fulfilled, (state, action) => {
      const chainId = action.payload.chainId;
      const assetKeys = Object.keys(action.payload.data);
      for (let i = 0; i < assetKeys.length; i++) {
        const key = assetKeys[i];
        const priceData = action.payload.data[key];
        // for polygon, assign the data to the live feed
        const newPrice = priceData;
        const oracleKey = toOracleKey(key);
        state.live[oracleKey] = newPrice;
        // if (
        //   chainId !== SupportedChainId.MANTLE ||
        //   ["LEND", "WMNT", "METH"].includes(key)
        // ) {
        //   if (state.live[oracleKey] && state.live[oracleKey] > 0) {
        //     state.ref[oracleKey] = state.live[oracleKey] * 0.3 + newPrice * 0.7;
        //   }
        //   state.live[oracleKey] = newPrice;
        // }
      }
      state.liveLoaded = true;
    })
    .addCase(fetchOracleData.pending, () => {})
    .addCase(fetchDexscreenerPairs.fulfilled, (state, action) => {
      if (!action.payload.success) return;

      Object.keys(action.payload.data).forEach((tokenAddress) => {
        const chainId = action.payload.chainId;
        if (!state.dexscreenerPairs[chainId]) {
          state.dexscreenerPairs[chainId] = {};
        }
        const newPair = action.payload.data[tokenAddress];
        const oldPair =
          state.dexscreenerPairs[chainId][tokenAddress.toLowerCase()];
        if (
          !oldPair ||
          oldPair.priceUsd !== newPair.priceUsd ||
          (!oldPair.userBalance && newPair.userBalance)
        ) {
          state.dexscreenerPairs[chainId][tokenAddress.toLowerCase()] = newPair;
        }
      });
    })
    .addCase(fetchDexscreenerPair.pending, () => {})
    .addCase(fetchDexscreenerPair.fulfilled, (state, action) => {
      if (!action.payload.success) return;

      const chainId = action.payload.chainId;
      if (!state.dexscreenerPairs[chainId]) {
        state.dexscreenerPairs[chainId] = {};
      }
      const newPair = action.payload.data;
      const tokenAddress = Object.keys(newPair)[0];
      const oldPair =
        state.dexscreenerPairs[chainId][tokenAddress.toLowerCase()];
      if (
        !oldPair ||
        oldPair.priceUsd !== newPair[tokenAddress].priceUsd ||
        (!oldPair.userBalance && newPair[tokenAddress].userBalance)
      ) {
        state.dexscreenerPairs[chainId][tokenAddress.toLowerCase()] =
          newPair[tokenAddress];
      }
    })
    // HISTORY
    // force fetch
    .addCase(resetPnLs, (state, action) => {
      state.pnl = {};
      state.txns = {};
    })

    // STAKING YIELDS
    .addCase(fetchStakingYields.fulfilled, (state, action) => {
      state.staking.loaded = true;
      state.staking.assetsYields = action.payload.data;
    })
    .addCase(fetchStakingYields.pending, (state) => {
      //
      state.staking.loaded = true;
    })
);
