import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.accent};
  }
`;

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = "16px",
  stroke,
  strokeWidth,
  ...rest
}: {
  size?: string;
  stroke?: string;
  strokeWidth?: number;
  [k: string]: any;
}) {
  return (
    <StyledSVG
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      stroke={stroke}
      {...rest}
    >
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
        strokeWidth={strokeWidth ?? "2.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSVG>
  );
}

const animateC = keyframes`

  0%{
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
`;
const animate = keyframes`
  0%{
    transform:rotate(45deg);
  }
  100%{
    transform:rotate(405deg);
  }
`;

const Ring = styled.div`
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-size: 18px;
  color: ${({ theme }) => theme.primaryButton};
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px ${({ theme }) => theme.primaryButton};
  :before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid ${({ theme }) => theme.primaryButton};
    border-right: 3px solid ${({ theme }) => theme.primaryButton};
    border-radius: 50%;
    animation: ${animateC} 2s linear infinite;
  }
`;

const Load = styled.span`
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: ${animate} 2s linear infinite;

  :before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryButton};
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px ${({ theme }) => theme.primaryButton};
  }
`;

export function LargeLoader() {
  return (
    <Ring>
      <Load />
      Loading
    </Ring>
  );
}
