import { Currency } from "@fusionx-finance/sdk";
import useCurrencyLogoURIs, {
  getTokenLogoURIBySymbol,
} from "lib/hooks/useCurrencyLogoURIs";
import React, { useMemo } from "react";
import styled from "styled-components";

import Logo from "../Logo";

export const StyledLogo = styled(Logo)<{ size: string; crop: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  ${({ crop }) => (crop ? "border-radius: 50%" : "")};
`;

const StyledNativeLogo = styled(StyledLogo)``;

export default function CurrencyLogo({
  currency,
  symbol,
  size = "24px",
  style,
  src,
  ...rest
}: {
  currency?: Currency | null;
  symbol?: string | null;
  size?: string;
  style?: React.CSSProperties;
  src?: string | null;
}) {
  let logoURIs: string[];
  let crop = "false";

  const _logoURIsClassic = getTokenLogoURIBySymbol(currency?.symbol);
  const _logoURIsNew = useCurrencyLogoURIs(currency);
  if (_logoURIsClassic) {
    logoURIs = [_logoURIsClassic];
  } else {
    crop = "true";
    logoURIs = _logoURIsNew;
  }
  const srcs = useMemo(() => (src ? [src] : logoURIs), [src, logoURIs]);
  const props = {
    alt: `${currency?.symbol ?? "token"} logo`,
    size,
    srcs,
    symbol: symbol ?? currency?.symbol,
    style,
    crop,
    ...rest,
  };

  return currency?.isNative ? (
    <StyledNativeLogo {...props} />
  ) : (
    <StyledLogo {...props} />
  );
}
