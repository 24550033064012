import { LargeLoader } from "components/Loader";
import { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Z_INDEX } from "theme/zIndex";
import ErrorBoundary from "components/ErrorBoundary";
import Header from "components/Header";
import Polling from "components/Header/Polling";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Popups from "components/Popups";
import DarkModeQueryParamReader from "theme/DarkModeQueryParamReader";
import { BasicColumn } from "components/Basic/layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChakraBaseProvider } from "@chakra-ui/react";
import useChakraTheme from "theme/chakraTheme";
// import "@sjmc11/tourguidejs/src/scss/tour.scss";
import { RedirectToMargin } from "./Professional/redirects";
import { MarginTradeType } from "types/fusionx";
import { USDC_ON, WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import { SupportedChainId } from "constants/chains";
import { useTopBarBannerHidden } from "state/user/hooks";
import { useAccount } from "state/globalNetwork/hooks";
import { initTracking, trackUtmTracked } from "utils/analytics";
import TEMPApp from "./TempApp";

const Pro = lazy(() => import("./Professional"));
// const Strategies = lazy(() => import("./Strategies"));
// const Swap = lazy(() => import("./Swap"));
const NotFound = lazy(() => import("../NotFound"));
// const UnderMaintenance = lazy(() => import('./UnderMaintenance'))
// const YieldExplorer = lazy(() => import("./Dashboard"));
// const TradingCompetition = lazy(() => import("./TradingCompetition"));

const AppWrapper = styled(BasicColumn)`
  align-items: flex-start;
  font-feature-settings: ${"'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on"};
`;

const BodyWrapper = styled(BasicColumn)<{ isTopBarHidden: boolean }>`
  width: 100%;
  padding: ${(
    { isTopBarHidden } // MUST BE THE SAME OF FixedContainer in Professional/index.tsx
  ) => (isTopBarHidden ? "80px 0px 0px 0px" : "115px 0px 0px 0px")};
  align-items: center;
  flex: 1;
  ${({ theme, isTopBarHidden }) => theme.mediaWidth.upToSmall`
    padding: ${isTopBarHidden ? "52px 0px 16px 0px" : "90px 0px 16px 0px"};
  `};
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.sticky};
  border-bottom: 1px solid ${({ theme }) => theme.container_border};
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

export const queryClient = new QueryClient();

export default function App() {
  const { pathname, search } = useLocation();
  const theme = useChakraTheme();
  const [isTopBarBannerHidden] = useTopBarBannerHidden();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const chain = "mantle"; // DEFAULT ChainId
  const lender = "init"; // DEFAULT Lenader
  const operation = MarginTradeType.Open;
  const inputCurrency = USDC_ON(SupportedChainId.MANTLE).address;
  const outputCurrency =
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MANTLE].address;
  const defaultRouteUrl =
    `/margin?chain=${chain}&lender=${lender}` +
    `&operation=${operation}&inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`;

  const account = useAccount();

  useEffect(() => {
    initTracking(account);
  }, [account]);

  useEffect(() => {
    trackUtmTracked(search);
  }, [search]);

  return (
    <ChakraBaseProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <DarkModeQueryParamReader />
          <AppWrapper>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper isTopBarHidden={isTopBarBannerHidden}>
              <Popups />
              <Polling />
              <Suspense fallback={<LargeLoader />}>
                <Switch>
                  {/* MARGIN */}
                  <Route
                    exact
                    path="/"
                    component={() => <Redirect to={defaultRouteUrl} />}
                  />
                  <Route exact path="/margin" component={Pro} />

                  <Route path="/*" component={NotFound} />
                </Switch>
              </Suspense>
              <Marginer />
            </BodyWrapper>
          </AppWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraBaseProvider>
  );
}
