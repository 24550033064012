import { createAction } from "@reduxjs/toolkit";

export const resetState = createAction<void>("lender-init/resetState");

export const setToLoading = createAction("lender-init/setToLoading");

export const resetInitUserState = createAction<{ chainId: number }>(
  "lender-init/resetInitUserState"
);

export const selectPosId = createAction<{ posId: string; chainId: number }>(
  "lender-init/setToLoading"
);

export const setPMode = createAction<{ pMode: string; chainId: number }>(
  "lender-init/setPMode"
);
