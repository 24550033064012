import { Currency, CurrencyAmount } from "@fusionx-finance/sdk";
import { nativeOnChain } from "constants/tokens";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../index";
import {
  setChainId,
  setAccount,
  setImpersonatedAccount,
  setUseImpersonatedAccount,
} from "./actions";
import { SupportedAssets } from "types/fusionx";
import { getTokenFromAsset } from "hooks/fusionx/tokens";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { isAddress } from "utils";

export function useNetworkState() {
  const { account, impersontedAccount, useImpersonatedAccount, ...rest } =
    useAppSelector((state) => state.globalNetwork);
  if (impersontedAccount) return { account: impersontedAccount, ...rest };
  return { account, ...rest };
}

export function useChainId(): number {
  return useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).chainId;
}

export function useAccount(): string | undefined {
  const { account, impersontedAccount, useImpersonatedAccount } =
    useAppSelector((state) => state.globalNetwork);
  if (useImpersonatedAccount) return impersontedAccount;
  return account;
}

export function useIsImpersonated() {
  const { useImpersonatedAccount, impersontedAccount } = useAppSelector(
    (state) => state.globalNetwork
  );
  return { useImpersonatedAccount, impersontedAccount };
}

export function useChainIdAndAccount(): {
  chainId: number;
  account: string | undefined;
} {
  const state = useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  );
  return { chainId: state.chainId, account: state.account };
}

/**
 * Setter for impersonated account
 * @returns setter for account and disabler
 */
export const useSetImpersonatedAccount = () => {
  const dispatch = useAppDispatch();

  const setAccount = useCallback((account: string | undefined) => {
    const address = isAddress(account);
    if (address) {
      dispatch(setImpersonatedAccount({ account }));
      dispatch(setUseImpersonatedAccount({ isUsed: true }));
    }
  }, []);

  const disableAccount = useCallback(() => {
    dispatch(setUseImpersonatedAccount({ isUsed: false }));
  }, []);
  return { setAccount, disableAccount };
};

/**
 * Get flag as to whether connection is supported
 * @returns true if supported, false if not
 */
export function useIsSupported(): boolean {
  return useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).connectionIsSupported;
}

export function useTimestamp(): string {
  const chainId = useChainId();
  return useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).networkData[chainId].lastTimestamp;
}

/**
 * Get raw native balance as serialized BigNumber
 * @returns unformatted native balance, undefined if not connected
 */
export function useNativeBalance(): string | undefined {
  const chainId = useChainId();
  return useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).networkData[chainId].nativeBalance;
}

/**
 * Get the native balance for connected account - defaults to 0 if not connected
 * @returns native balance as CurrencyAmount object
 */
export function useNativeCurrencyBalance(): CurrencyAmount<Currency> {
  const chainId = useChainId();
  return CurrencyAmount.fromRawAmount(
    nativeOnChain(chainId),
    useSelector<AppState, AppState["globalNetwork"]>(
      (state) => state.globalNetwork
    ).networkData[chainId].nativeBalance ?? "0"
  );
}

/**
 * Get the block number from state
 * @returns block number as number object
 */
export function useBlockNumber(): number {
  const chainId = useChainId();
  return useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).networkData[chainId].blockNumber;
}

export function useGlobalNetworkActionHandlers(): {
  onChainChange: (chainId: number) => void;
  onAccountChange: (account: string) => void;
} {
  const dispatch = useDispatch<AppDispatch>();

  const onChainChange = useCallback(
    (chainId: number) => {
      dispatch(setChainId({ chainId }));
    },
    [dispatch]
  );
  const onAccountChange = useCallback(
    (account: string) => {
      dispatch(setAccount({ account }));
    },
    [dispatch]
  );
  return {
    onChainChange,
    onAccountChange,
  };
}

/**
 * Get the native balance for connected account - defaults to 0 if not connected
 * @returns native balance as CurrencyAmount object
 */
export function useTokenBalances(
  assets: (SupportedAssets | undefined)[]
): (CurrencyAmount<Currency> | undefined)[] {
  const chainId = useChainId();
  const balances = useSelector<AppState, AppState["globalNetwork"]>(
    (state) => state.globalNetwork
  ).networkData[chainId].balances;
  return assets.map((a) => {
    if (!a) return undefined;
    const token = getTokenFromAsset(a, chainId);
    const balance = balances[a];
    if (!token || balance === undefined) return undefined;
    return CurrencyAmount.fromRawAmount(token, balances[a]);
  });
}

/**
 * Get flag whether the wallet data is changing
 * @returns boolean
 */
export function useIsLoading() {
  return useAppSelector((state) => state.globalNetwork.loading);
}
