import { SupportedChainId } from "constants/chains";
import { SupportedAssets } from "types/fusionx";
import { AddressDictionary } from "./addresses";

export const addressesInitCore: AddressDictionary = {
  InitOracle: {
    [SupportedChainId.MANTLE]: "0x4E195A32b2f6eBa9c4565bA49bef34F23c2C0350",
  },
  Core: {
    [SupportedChainId.MANTLE]: "0x972BcB0284cca0152527c4f70f8F689852bCAFc5",
  },
  Config: {
    [SupportedChainId.MANTLE]: "0x007F91636E0f986068Ef27c950FA18734BA553Ac",
  },
  PosManager: {
    [SupportedChainId.MANTLE]: "0x0e7401707CD08c03CDb53DAEF3295DDFb68BBa92",
  },
  Lens: {
    [SupportedChainId.MANTLE]: "0x7d2b278b8ef87bEb83AeC01243ff2Fed57456042",
  },
  PublicLens: {
    [SupportedChainId.MANTLE]: "0x1F4a54F855A807F85746d2FFdfEBDFF5EAD2Cb3d",
  },
  marginTradingHook: {
    [SupportedChainId.MANTLE]: "0xe4Fe22F64F37bA62BDDFeD3B05DaBcc1F01Ad1Ad",
  },
};

export const addressesInitPoolTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: "0x00A55649E597d463fD212fBE48a3B40f0E227d06",
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: "0xadA66a8722B5cdfe3bC504007A5d793e7100ad09",
  },
  [SupportedAssets.USDY]: {
    [SupportedChainId.MANTLE]: "0xf084813F1be067d980a0171F067f084f27B3F63A",
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: "0x9c9F28672C4A8Ad5fb2c9Aca6d8D68B02EAfd552",
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: "0x51AB74f8B03F0305d8dcE936B473AB587911AEC4",
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: "0x44949636f778fAD2b139E665aee11a2dc84A2976",
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: "0x5071c003bB45e49110a905c1915EbdD2383A89dF",
  },
};
