import { Percent, Token } from "@fusionx-finance/sdk";
import {
  CHAIN_IDS_TO_NAMES,
  L2_CHAIN_IDS,
  LENDER_TO_ROUTE,
} from "constants/chains";
import { SupportedLocale } from "constants/locales";
import { L2_DEADLINE_FROM_NOW } from "constants/misc";
import { useCallback, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useChainId } from "state/globalNetwork/hooks";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  EXTERNAL_TRADE_AGGREGATORS_PER_CHAIN,
  MarginTradeType,
  TradeAggregator,
} from "types/fusionx";

import { AppState } from "../index";
import {
  RouterPreference,
  addSerializedToken,
  removeSerializedToken,
  updateAggregators,
  updateHideClosedPositions,
  updatePreferPermit,
  updateShowSurveyPopup,
  updateShowTokensPromoBanner,
  updateUserClientSideRouter,
  updateUserDarkMode,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserLocale,
  updateUserRouterPreference,
  updateUserSlippageTolerance,
  updateIsAuthenticated,
  updateLender,
  hideTopBar,
} from "./reducer";
import { SerializedToken } from "./types";
import { Lender } from "types/lenderData/base";
import { generatePath, useHistory } from "react-router-dom";

function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  };
}

function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address as any,
    serializedToken.decimals,
    serializedToken.symbol!,
    serializedToken.name
  );
}

export function useIsDarkMode(): boolean {
  const { userDarkMode, matchesDarkMode } = useAppSelector(
    ({ user: { matchesDarkMode, userDarkMode } }) => ({
      userDarkMode,
      matchesDarkMode,
    }),
    shallowEqual
  );

  return userDarkMode === null ? matchesDarkMode : userDarkMode;
}

export function useDarkModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const darkMode = useIsDarkMode();

  const toggleSetDarkMode = useCallback(() => {
    dispatch(updateUserDarkMode({ userDarkMode: !darkMode }));
  }, [darkMode, dispatch]);

  return [darkMode, toggleSetDarkMode];
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale);
}

export function useUserLocaleManager(): [
  SupportedLocale | null,
  (newLocale: SupportedLocale) => void
] {
  const dispatch = useAppDispatch();
  const locale = useUserLocale();

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }));
    },
    [dispatch]
  );

  return [locale, setLocale];
}

export function useIsExpertMode(): boolean {
  return useAppSelector((state) => state.user.userExpertMode);
}

export function useExpertModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const expertMode = useIsExpertMode();

  const toggleSetExpertMode = useCallback(() => {
    dispatch(updateUserExpertMode({ userExpertMode: !expertMode }));
  }, [expertMode, dispatch]);

  return [expertMode, toggleSetExpertMode];
}

export function useShowSurveyPopup(): [
  boolean | undefined,
  (showPopup: boolean) => void
] {
  const dispatch = useAppDispatch();
  const showSurveyPopup = useAppSelector((state) => state.user.showSurveyPopup);
  const toggleShowSurveyPopup = useCallback(
    (showPopup: boolean) => {
      dispatch(updateShowSurveyPopup({ showSurveyPopup: showPopup }));
    },
    [dispatch]
  );
  return [showSurveyPopup, toggleShowSurveyPopup];
}

export function useShowTokensPromoBanner(): [
  boolean,
  (showTokensBanner: boolean) => void
] {
  const dispatch = useAppDispatch();
  const showTokensPromoBanner = useAppSelector(
    (state) => state.user.showTokensPromoBanner
  );
  const toggleShowTokensPromoBanner = useCallback(
    (showTokensBanner: boolean) => {
      dispatch(
        updateShowTokensPromoBanner({ showTokensPromoBanner: showTokensBanner })
      );
    },
    [dispatch]
  );
  return [showTokensPromoBanner, toggleShowTokensPromoBanner];
}

export function useClientSideRouter(): [
  boolean,
  (userClientSideRouter: boolean) => void
] {
  const dispatch = useAppDispatch();

  const clientSideRouter = useAppSelector((state) =>
    Boolean(state.user.userClientSideRouter)
  );

  const setClientSideRouter = useCallback(
    (newClientSideRouter: boolean) => {
      dispatch(
        updateUserClientSideRouter({
          userClientSideRouter: newClientSideRouter,
        })
      );
    },
    [dispatch]
  );

  return [clientSideRouter, setClientSideRouter];
}

export function useSlippagePercent() {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance;
  });
  return useMemo(
    () =>
      userSlippageToleranceRaw === "auto"
        ? new Percent(30, 10_000)
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  );
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): [
  Percent | "auto",
  (slippageTolerance: Percent | "auto") => void
] {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance;
  });
  const userSlippageTolerance = useMemo(
    () =>
      userSlippageToleranceRaw === "auto"
        ? "auto"
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  );

  const dispatch = useAppDispatch();
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance: Percent | "auto") => {
      let value: "auto" | number;
      try {
        value =
          userSlippageTolerance === "auto"
            ? "auto"
            : Number(
                userSlippageTolerance.multiply(10_000).quotient.toString()
              );
      } catch (error) {
        value = "auto";
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      );
    },
    [dispatch]
  );

  return useMemo(
    () => [userSlippageTolerance, setUserSlippageTolerance],
    [setUserSlippageTolerance, userSlippageTolerance]
  );
}

export function useUserHideClosedPositions(): [
  boolean,
  (newHideClosedPositions: boolean) => void
] {
  const dispatch = useAppDispatch();

  const hideClosedPositions = useAppSelector(
    (state) => state.user.userHideClosedPositions
  );

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(
        updateHideClosedPositions({
          userHideClosedPositions: newHideClosedPositions,
        })
      );
    },
    [dispatch]
  );

  return [hideClosedPositions, setHideClosedPositions];
}

/**
 * Same as above but replaces the auto with a default value
 * @param defaultSlippageTolerance the default value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(
  defaultSlippageTolerance: Percent
): Percent {
  const allowedSlippage = useUserSlippageTolerance()[0];
  return useMemo(
    () =>
      allowedSlippage === "auto" ? defaultSlippageTolerance : allowedSlippage,
    [allowedSlippage, defaultSlippageTolerance]
  );
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  const userDeadline = useAppSelector((state) => state.user.userDeadline);
  const onL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId));
  const deadline = onL2 ? L2_DEADLINE_FROM_NOW : userDeadline;

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }));
    },
    [dispatch]
  );

  return [deadline, setUserDeadline];
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch]
  );
}

export function useRemoveUserAddedToken(): (
  chainId: number,
  address: string
) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (chainId: number, address: string) => {
      dispatch(removeSerializedToken({ chainId, address }));
    },
    [dispatch]
  );
}

export function useUserAddedTokensOnChain(
  chainId: number | undefined | null
): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    if (!chainId) return [];
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map(deserializeToken)
      : [];
    return tokenMap;
  }, [serializedTokensMap, chainId]);
}

export function useUserAddedTokens(): Token[] {
  return useUserAddedTokensOnChain(useChainId());
}

export function useURLWarningVisible(): boolean {
  return useAppSelector((state: AppState) => state.user.URLWarningVisible);
}

export function useRouterPreference(): [
  RouterPreference,
  (routerPreference: RouterPreference) => void
] {
  const dispatch = useAppDispatch();

  const routerPreference = useAppSelector(
    (state) => state.user.userRouterPreference
  );

  const setRouterPreference = useCallback(
    (newRouterPreference: RouterPreference) => {
      dispatch(
        updateUserRouterPreference({
          userRouterPreference: newRouterPreference,
        })
      );
    },
    [dispatch]
  );

  return [routerPreference, setRouterPreference];
}

export function useSelectedAggregators(): TradeAggregator[] {
  const aggregators = useAppSelector((state) => state.user.selectedAggregators);
  const chainId = useChainId();
  // if undefined, return all
  if (!aggregators) return EXTERNAL_TRADE_AGGREGATORS_PER_CHAIN[chainId];
  return aggregators;
}

export function useSelectAggregators(): [
  TradeAggregator[],
  (aggregator: TradeAggregator) => void
] {
  const dispatch = useAppDispatch();

  const selectedAggregators = useSelectedAggregators();

  const setAggregator = useCallback(
    (aggregator: TradeAggregator) => {
      if (selectedAggregators?.includes(aggregator)) {
        dispatch(
          updateAggregators({
            aggregatorList: selectedAggregators.filter((a) => a !== aggregator),
          })
        );
      } else {
        dispatch(
          updateAggregators({
            aggregatorList: [...selectedAggregators, aggregator],
          })
        );
      }
    },
    [dispatch, selectedAggregators]
  );

  return [selectedAggregators, setAggregator];
}

export function usePreferPermits(): boolean {
  return Boolean(useAppSelector((state) => state.user.preferPermits));
}

export function useSetPreferPermits(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const preferPermits = usePreferPermits();

  const setPreferPermits = useCallback(() => {
    dispatch(updatePreferPermit({ preferPermits: !preferPermits }));
  }, [dispatch, preferPermits]);
  return [preferPermits, setPreferPermits];
}

export function useAuth(): [boolean, (isAuth: boolean) => void] {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => state.user.isAuthenticatedSwapBeta);

  const setIsAuth = useCallback(
    (isAuth: boolean) => {
      dispatch(updateIsAuthenticated({ isAuthenticatedSwapBeta: isAuth }));
    },
    [dispatch]
  );

  return [isAuth, setIsAuth];
}

/**
 * Return the selected lender enum from the user state
 * @returns lender enum
 */
export const useSelectedLender = (): Lender => {
  return (
    useAppSelector((state) => {
      return state.user.selectedLender;
    }) ?? Lender.INIT
  );
};

/**
 * Get the selected lender and setter from the user state
 * @returns [lender, setter: (lender)=>void]
 */
export function useSelectLenderState(): [Lender, (lender: Lender) => void] {
  const dispatch = useAppDispatch();

  const selectedLender = useSelectedLender();

  const setLender = useCallback(
    (lender: Lender) => {
      dispatch(updateLender({ lender }));
    },
    [dispatch, selectedLender]
  );

  return [selectedLender, setLender];
}

/**
 * Amends the current path based on lender and chainId
 * @returns hook to amend path
 */
export function useAmendPath(): (lender?: Lender, chainId?: number) => void {
  const history = useHistory();

  return useCallback(
    (lender?: Lender, chainId?: number) => {
      if (chainId || lender)
        history.push({
          search:
            chainId && lender
              ? `?chain=${CHAIN_IDS_TO_NAMES[chainId]}&lender=${LENDER_TO_ROUTE[lender]}`
              : lender
              ? `?lender=${LENDER_TO_ROUTE[lender]}`
              : `?chain=${CHAIN_IDS_TO_NAMES[chainId ?? 0]}`,
        });
    },
    [history]
  );
}

/**
 * Amends the current path based on lender and chainId
 * @returns hook to amend path
 */
export function useAmendPathForSwap(): (
  chainId?: number,
  inputCurrency?: string,
  outputCurrency?: string
) => void {
  const history = useHistory();
  return useCallback(
    (chainId?: number, inputCurrency?: string, outputCurrency?: string) => {
      let search = "";
      if (!chainId) return;
      search = `?chain=${CHAIN_IDS_TO_NAMES[chainId]}`;
      if (inputCurrency) search += `&inputCurrency=${inputCurrency}`;
      if (outputCurrency) search += `&outputCurrency=${outputCurrency}`;
      history.push({ search });
    },
    [history]
  );
}

/**
 * Amends the current path based on lender and chainId
 * @returns hook to amend path
 */
export function useReplaceChainIdForPath(): (chainId?: number) => void {
  const history = useHistory();
  return useCallback(
    (chainId?: number) => {
      const pathAndSearch = history.location.pathname + history.location.search;
      if (chainId) {
        // first case: chain is already in path -> switch it
        if (pathAndSearch.includes("chain"))
          history.replace(
            generatePath(
              pathAndSearch, // concat pathg and search
              { chain: CHAIN_IDS_TO_NAMES[chainId] } // replace element
            )
          );
        // second case: chain not in path -> add it
        else history.push({ search: `?chain=${CHAIN_IDS_TO_NAMES[chainId]}` });
      }
    },
    [history]
  );
}

/**
 * Amends the current path based on lender and chainId
 * @returns hook to amend path
 */
export function useAmendPathForMargin(): (
  lender?: Lender,
  chainId?: number,
  operation?: MarginTradeType,
  inputCurrency?: string,
  outputCurrency?: string
) => void {
  const history = useHistory();
  return useCallback(
    (
      lender?: Lender,
      chainId?: number,
      operation?: MarginTradeType,
      inputCurrency?: string,
      outputCurrency?: string
    ) => {
      let search = "";
      if (!chainId || !lender || !operation) return;
      // base link
      search = `?chain=${CHAIN_IDS_TO_NAMES[chainId]}&lender=${LENDER_TO_ROUTE[lender]}&operation=${operation}`;
      if (inputCurrency) search += `&inputCurrency=${inputCurrency}`;
      if (outputCurrency) search += `&outputCurrency=${outputCurrency}`;
      history.push({ search });
    },
    [history]
  );
}

export function useTopBarBannerHidden(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const isTopBarBannerHidden = useAppSelector(
    (state) => state.user.topBarHidden
  );

  const hideTopBarBanner = useCallback(() => {
    dispatch(hideTopBar());
  }, [dispatch]);
  return [isTopBarBannerHidden, hideTopBarBanner];
}
