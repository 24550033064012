import { createReducer } from "@reduxjs/toolkit";
import {
  Field,
  MarginTradeType,
  MarginTradeState,
  SupportedAssets,
  QuickActionType,
} from "types/fusionx";
import {
  replaceMarginState,
  resetSelectionState,
  selectMarginCurrency,
  setRecipient,
  setMarginInteraction,
  setQuickActionType,
  typeInput,
} from "./actions";

const defaultState: MarginTradeState = {
  [Field.INPUT]: { currencyId: "" },
  [Field.OUTPUT]: { currencyId: "" },
  typedValue: "",
  independentField: Field.INPUT,
  marginTradeType: MarginTradeType.Open,
  quickActionType: QuickActionType.Deposit,
  baseCurrency: SupportedAssets.USDCE, // only relevant for Compound V3
  recipient: "",
};

export default createReducer<MarginTradeState>(defaultState, (builder) =>
  builder
    .addCase(resetSelectionState, () => defaultState)
    .addCase(setMarginInteraction, (state, action) => {
      state.marginTradeType = action.payload.marginTradeType;
    })
    .addCase(setQuickActionType, (state, action) => {
      state.quickActionType = action.payload.quickActionType;
    })
    .addCase(
      selectMarginCurrency,
      (state, { payload: { currencyId, field } }) => {
        return {
          ...state,
          [field]: { currencyId },
        };
      }
    )
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      };
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient;
    })
    .addCase(
      replaceMarginState,
      (
        state,
        {
          payload: {
            typedValue,
            recipient,
            field,
            inputCurrencyId,
            outputCurrencyId,
          },
        }
      ) => {
        return {
          ...defaultState,
          [Field.INPUT]: {
            currencyId: inputCurrencyId ?? undefined,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId ?? undefined,
          },
          independentField: field,
          typedValue,
          recipient,
        };
      }
    )
);
