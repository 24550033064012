import { MarginTradeType, QuickActionType } from "types/fusionx";
import { Lender } from "types/lenderData/base";

// Use the (Object) (Verb) format for event names. Like "Song Played" or "Page Viewed".
export enum Event {
  WALLET_CONNECTED = "Wallet Connected",
  WALLET_DISCONNECTED = "Wallet Disconnected",
  ACCOUNT_SWITCHED = "Account Switched",
  CHAIN_SELECTED = "Chain Selected",
  LENDER_SELECTED = "Lender Selected",
  BUTTON_CLICKED = "Entity Clicked",
  QUICK_ACTION_EXECUTED = "Quick Action Executed",
  TRADE_EXECUTED = "Trade Executed",
  SWAP_EXECUTED = "Swap Executed",
  TRANSACTION_ERROR = "Transaction Error",
  UTM_TRACKED = "UTM Tracked",
}

// Events can have properties
export enum Property {
  CHAIN = "Chain",
  LENDER = "Lender",
  BUTTON_NAME = "Button Name",
  TX_HASH = "Tx Hash",
  QUICK_ACTION_TYPE = "Quick Action Type",
  TRADE_TYPE = "Trade Type",
  IS_DIRECT = "Is Direct",
  TOKEN_IN = "Token In",
  TOKEN_OUT = "Token Out",
  AMOUNT_IN = "Amount In",
  AMOUNT_OUT = "Amount Out",
  AMOUNT_IN_USD = "Amount In USD",
  AMOUNT_OUT_USD = "Amount Out USD",
  BLOCK_NUMBER = "Block Number",
  ERROR_MESSAGE = "Error Message",
  TRADE = "Trade",
}

// here we create a list of all the buttons we want to track
export enum PropertyButtonName_Value {
  LENDER_SELECTION_ROW = "Lender Selection Row",
}

export type QuickActionEvent = {
  [Property.TX_HASH]: string;
  [Property.QUICK_ACTION_TYPE]: QuickActionType;
  [Property.IS_DIRECT]: boolean;
  [Property.LENDER]: Lender;
  [Property.TOKEN_IN]: string;
  [Property.AMOUNT_IN]: string;
  [Property.AMOUNT_IN_USD]: string;
  [Property.TOKEN_OUT]?: string;
  [Property.AMOUNT_OUT]?: string;
  [Property.AMOUNT_OUT_USD]?: string;
};

export type TradeEvent = {
  [Property.TX_HASH]: string;
  [Property.TRADE_TYPE]: MarginTradeType | "Swap";
  [Property.LENDER]?: Lender;
  [Property.TOKEN_IN]: string;
  [Property.AMOUNT_IN]: string;
  [Property.AMOUNT_IN_USD]: string;
  [Property.TOKEN_OUT]: string;
  [Property.AMOUNT_OUT]: string;
  [Property.AMOUNT_OUT_USD]: string;
};

export type TradeErrorEvent = {
  [Property.BLOCK_NUMBER]: string;
  [Property.TRADE_TYPE]: MarginTradeType | QuickActionType | "Swap";
  [Property.LENDER]?: Lender;
  [Property.TRADE]: string;
  [Property.ERROR_MESSAGE]: string;
};

export type UtmTrackedEvent = {
  [UTM_KEY.SOURCE]?: string;
  [UTM_KEY.MEDIUM]?: string;
  [UTM_KEY.CAMPAIGN]?: string;
  [UTM_KEY.TERM]?: string;
  [UTM_KEY.CONTENT]?: string;
};

export enum UTM_KEY {
  SOURCE = "utm_source",
  MEDIUM = "utm_medium",
  CAMPAIGN = "utm_campaign",
  TERM = "utm_term",
  CONTENT = "utm_content",
}
