import mntLogo from "assets/svg/mnt-logo.svg";
import ms from "ms.macro";

import { ViewMode } from "hooks/viewMode/useViewMode";
import {
  DEFAULT_CHAINID,
  getAvailableChainIds,
  SupportedChainId,
  SupportedL1ChainId,
  SupportedL2ChainId,
} from "./chains";

export const AVERAGE_L1_BLOCK_TIME = ms`12s`;

export enum NetworkType {
  L1,
  L2,
}

interface BaseChainInfo {
  readonly networkType: NetworkType;
  readonly blockWaitMsBeforeWarning?: number;
  readonly docs: string;
  readonly bridge?: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly logoUrl: string;
  readonly circleLogoUrl?: string;
  readonly label: string;
  readonly helpCenterUrl?: string;
  readonly nativeCurrency: {
    name: string; // e.g. 'Goerli ETH',
    symbol: string; // e.g. 'gorETH',
    decimals: number; // e.g. 18,
  };
  readonly color?: string;
  readonly backgroundColor?: string;
  readonly faucet?: string;
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1;
  readonly defaultListUrl?: string;
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2;
  readonly bridge: string;
  readonly statusPage?: string;
  readonly defaultListUrl: string;
}

export interface GeneralChainInfo extends BaseChainInfo {
  // readonly networkType: NetworkType.L2 | NetworkType.L1
  readonly bridge?: string;
  readonly statusPage?: string;
  readonly defaultListUrl?: string;
}

export type ChainInfoMap = { readonly [chainId: number]: GeneralChainInfo };
//  { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
//   readonly [chainId in SupportedL2ChainId]: L2ChainInfo
// } & { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.MANTLE]: {
    networkType: NetworkType.L1,
    bridge: "https://bridge.mantle.xyz/",
    docs: "https://docs.bnbchain.org/",
    explorer: "https://explorer.mantle.xyz/",
    infoLink: "https://info.uniswap.org/#/bnb/",
    label: "Mantle",
    logoUrl: mntLogo,
    nativeCurrency: { name: "MNT", symbol: "MNT", decimals: 18 },
  },
};

export function getChainInfo(chainId: SupportedL1ChainId): L1ChainInfo;
export function getChainInfo(chainId: SupportedL2ChainId): L2ChainInfo;
export function getChainInfo(
  chainId: SupportedChainId
): L1ChainInfo | L2ChainInfo;
export function getChainInfo(
  chainId:
    | SupportedChainId
    | SupportedL1ChainId
    | SupportedL2ChainId
    | number
    | undefined
): L1ChainInfo | L2ChainInfo | undefined;

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * SupportedChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(chainId: any): any {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? CHAIN_INFO[DEFAULT_CHAINID];
  }
  return CHAIN_INFO[DEFAULT_CHAINID];
}

export const MAINNET_INFO = CHAIN_INFO[SupportedChainId.MANTLE];

export function getChainInfoOrDefault(chainId: number | undefined) {
  return getChainInfo(chainId) ?? MAINNET_INFO;
}

export function getRawChainInfo(
  chainId: number | undefined,
  viewMode = ViewMode.ALL
) {
  if (chainId && getAvailableChainIds(viewMode).includes(chainId))
    return CHAIN_INFO[chainId];
  return undefined;
}
