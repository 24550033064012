import { useTheme } from "styled-components";
import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const useChakraTheme = () => {
  const theme = useTheme();

  const baseStyle = definePartsStyle({
    thumb: {
      bg: theme.tertiaryButtonBorder,
      _checked: {
        bg: theme.accent,
      },
    },
    track: {
      bg: "transparent",
      border: "1px solid" + theme.tertiaryButtonBorder,
    },
  });

  const switchTheme = defineMultiStyleConfig({ baseStyle });

  const chakraTheme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: "",
          color: "",
        },
      }),
    },
    components: { Switch: switchTheme },
  });

  return chakraTheme;
};

export default useChakraTheme;
