import { getChainInfoOrDefault } from "constants/chainInfo";
import { SupportedChainId } from "constants/chains";
import useCopyClipboard from "hooks/useCopyClipboard";
import { useDollarPriceViaOracles } from "hooks/useStablecoinPrice";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { useCallback, useMemo } from "react";
import { Copy, ExternalLink, Power } from "react-feather";
import { Text } from "rebass";
import { useCurrencyBalanceString } from "state/connection/hooks";
import { useAppDispatch } from "state/hooks";
import { updateSelectedWallet } from "state/user/reducer";
import styled from "styled-components";

import { shortenAddress } from "utils";
import StatusIcon from "../Identicon/StatusIcon";
import IconButton, { IconHoverText } from "./IconButton";
import { useWeb3ReactWrapped } from "hooks/web3";
import { getConnection } from "connection";
import { useSelectChainNoWallet } from "hooks/useSelectChain";
import { removeConnectedWallet } from "state/wallets/reducer";
import { getIsCoinbaseWallet, getIsMetaMaskWallet } from "connection/utils";
import { isMobile } from "utils/userAgent";
import { setAccount } from "state/globalNetwork/actions";
import { useChainId } from "state/globalNetwork/hooks";
import { trackWalletDisconnected } from "utils/analytics";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`;

const USDText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary};
  margin-top: 8px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const StatusWrapper = styled.div`
  display: inline-block;
  margin-top: 4px;
`;

const BalanceWrapper = styled.div`
  padding: 16px 0;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const AuthenticatedHeaderWrapper = styled.div`
  padding: 0 16px;
`;

interface AuthenticatedHeaderProps {
  openOptions?: () => void;
}

const AuthenticatedHeader = ({ openOptions }: AuthenticatedHeaderProps) => {
  const { account, chainId, connector } = useWeb3ReactWrapped();
  const appChainId = useChainId();
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(account || "");
  }, [account, setCopied]);
  const dispatch = useAppDispatch();

  const isMetaMask = getIsMetaMaskWallet();
  const isCoinbaseWallet = getIsCoinbaseWallet();
  const isInjectedMobileBrowser = (isMetaMask || isCoinbaseWallet) && isMobile;

  const balanceString = useCurrencyBalanceString(account ?? "");
  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MANTLE);

  const connectionType = getConnection(connector).type;
  const nativeCurrency = useNativeCurrency();
  const nativeCurrencyPrice =
    useDollarPriceViaOracles(nativeCurrency ?? undefined) || 0;

  const selectChain = useSelectChainNoWallet();

  const disconnect = useCallback(() => {
    const walletType = getConnection(connector).getName();
    if (connector.deactivate) {
      connector.deactivate();
    }
    connector.resetState();

    dispatch(updateSelectedWallet({ wallet: undefined }));
    dispatch(removeConnectedWallet({ account, walletType }));
    dispatch(setAccount({ account: undefined }));
    selectChain(appChainId);
    if (openOptions) openOptions();
    trackWalletDisconnected();
  }, [connector, dispatch, openOptions, account, selectChain, appChainId]);

  const amountUSD = useMemo(() => {
    const price = parseFloat(nativeCurrencyPrice.toFixed(5));
    const balance = parseFloat(balanceString || "0");
    return price * balance;
  }, [balanceString, nativeCurrencyPrice]);

  return (
    <AuthenticatedHeaderWrapper>
      <HeaderWrapper>
        <StatusWrapper>
          <FlexContainer>
            <StatusIcon connectionType={connectionType} size={24} />
            <Text fontSize={16} fontWeight={600} marginTop="2.5px">
              {account && shortenAddress(account, 3)}
            </Text>
          </FlexContainer>
        </StatusWrapper>
        <IconContainer>
          <IconButton
            onClick={copy}
            Icon={Copy}
            text={isCopied ? <>Copied!</> : <>Copy</>}
          />
          <IconButton
            href={`${explorer}address/${account}`}
            Icon={ExternalLink}
            text={<>Explore</>}
          />
          <IconButton
            dataTestId="wallet-disconnect"
            onClick={disconnect}
            Icon={Power}
            text={<>Disconnect</>}
          />
        </IconContainer>
      </HeaderWrapper>
      <Column>
        <BalanceWrapper>
          <Text fontSize={36} fontWeight={400}>
            {balanceString} {nativeCurrencySymbol}
          </Text>
          <USDText>${amountUSD.toFixed(2)} USD</USDText>
        </BalanceWrapper>
      </Column>
    </AuthenticatedHeaderWrapper>
  );
};

export default AuthenticatedHeader;
