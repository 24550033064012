import { SupportedChainId } from "./chains";

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === "undefined") {
  console.warn(`REACT_APP_INFURA_KEY must be a defined environment variable`);
}

const QUICKNODE_RPC_URL = process.env.REACT_APP_QUICKNODE_RPC_URL;
if (typeof QUICKNODE_RPC_URL === "undefined") {
  console.warn(
    `REACT_APP_QUICKNODE_RPC_URL must be a defined environment variable`
  );
}

/**
 * These are the URLs used by the interface when there is not another available source of chain data.
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const RPC_URLS: { [chainId: number]: string[] } = {
  [SupportedChainId.MANTLE]: [
    "https://rpc.mantle.xyz/",
    "https://mantle-mainnet.blastapi.io/d4e9d4a7-208b-4f35-bbfd-e9659cb024cf",
    "https://mantle-mainnet.public.blastapi.io",
    "https://rpc.ankr.com/mantle",
  ],
};

/**
 * Fallback JSON-RPC endpoints.
 */
export const FALLBACK_URLS: { [chainId: number]: string[] } = {
  [SupportedChainId.MANTLE]: [
    "https://mantle-mainnet.blastapi.io/d4e9d4a7-208b-4f35-bbfd-e9659cb024cf",
    "https://1rpc.io/mantle",
  ],
};
