import { Box } from "rebass/styled-components";
import styled from "styled-components";

const Card = styled(Box)<{
  width?: string;
  padding?: string;
  border?: string;
  $borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  padding: ${({ padding }) => padding ?? "1rem"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "0.5em"};
  border: ${({ border }) => border};
`;
export default Card;

export const LightCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundDark};
`;

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundTertiary};
`;

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundDark};
`;

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundTertiary};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.backgroundDark};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow};
  font-weight: 500;
`;
