import { SupportedChainId } from "constants/chains";
import { ethers } from "ethers";
import { Lender } from "types/lenderData/base";
import { roundNumber } from "utils/fusionx/generalFormatters";

const BLOCK_TIMES: { [chainId: number]: number } = {
  5: 12,
  1: 12,
  56: 3,
};

/**
 * Formats a price decimal number or number string
 * Assumes positive prices, ceils display at $1M
 * @param price price data
 * @param precision decimals to show
 * @returns price formatted as string
 */
export const formatPriceString = (price?: string | number, precision = 2) => {
  const numberPrice = Number(price);
  if (numberPrice && !isNaN(numberPrice)) {
    if (numberPrice > 1e6) return ">$1M"; // ceil for abbreviation is 1M
    return formatDecimalWithPrecision(numberPrice, precision);
  }
  return "-";
};

export const formatAbbreviatedPrice = (amount?: number) => {
  if (!amount) return "-";
  const n = Number(amount);
  if (n < 1e3) return formateDecimal(n);
  if (n >= 1e3 && n < 1e6) return formateDecimal(n / 1e3) + "K";
  if (n >= 1e6 && n < 1e9) return formateDecimal(n / 1e6) + "M";
  if (n >= 1e9 && n < 1e12) return formateDecimal(n / 1e9) + "B";
  if (n >= 1e12 && n < 1e15) return formateDecimal(n / 1e12) + "T";
  if (n >= 1e15 && n < 1e18) return formateDecimal(n / 1e15) + "P";
  if (n !== Infinity) return formateDecimal(n / 1e18) + "E";
  return "-";
};

export const formatAbbreviatedGeneralUSDAmount = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  if (Math.abs(amount) <= 0.01) return formatSmallUSDValueRounded(amount);
  const sign = Number(amount) >= 0 ? "+" : "-";
  const n = Math.abs(Number(amount));
  if (n < 1e5) return sign + formateDecimal(n);
  if (n >= 1e5 && n < 1e6) return sign + formateDecimal(n / 1e3) + "K";
  if (n >= 1e6 && n < 1e9) return sign + formateDecimal(n / 1e6) + "M";
  if (n >= 1e9 && n < 1e12) return sign + formateDecimal(n / 1e9) + "B";
  if (n >= 1e12 && n < 1e15) return sign + formateDecimal(n / 1e12) + "T";
  if (n >= 1e15 && n < 1e18) return sign + formateDecimal(n / 1e15) + "P";
  if (n !== Infinity) return sign + formateDecimal(n / 1e18) + "E";
  return "-";
};

export const formatSmallValueNoFallback = (amount?: number, mobile = false) => {
  if (!amount) return "-";
  return formatSmallValue(amount, mobile);
};

export const formatSmallValue = (amount?: number, mobile = false) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  const n = Number(amount);
  if (n < 0.0001) return "~0";
  if (n < 0.001) return "~0.001";
  if (n < 0.005) return "~0.005";
  return mobile
    ? amount.toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : amount.toLocaleString();
};

export const formatSmallGeneralValue = (amount?: number, mobile = false) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  const n = Math.abs(Number(amount));
  if (n < 0.0001) return "~0.0001";
  if (n < 0.001) return "~0.001";
  if (n < 0.005) return "~0.005";
  return amount.toLocaleString("en-EN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatSmallUSDValueNoFallback = (amount?: number) => {
  if (!amount) return "-";
  return formatSmallUSDValue(amount);
};

export const formatSmallUSDValue = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  const n = Number(amount);
  if (n < 0.001) return "< $0.001";
  if (n < 0.01) return "< $0.01";
  return `$${roundNumber(amount, 2).toLocaleString()}`;
};

export const formatSmallUSDValueRounded = (amount?: number, decimals = 2) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  const isNeg = amount < 0;
  const n = Math.abs(amount);
  if (!isNeg) {
    if (n < 0.001) return "< $0.001";
    if (n < 0.01) return "< $0.01";
    return `$${n.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })}`;
  } else {
    if (n < 0.001) return "> -$0.001";
    if (n < 0.01) return "> -$0.01";
    return `-$${n.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })}`;
  }
};

export const formatSmallGeneralUSDValue = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "$0.0";
  const n = Math.abs(amount);
  const isPos = amount < 0;
  if (n < 0.0001) return isPos ? "~$0" : "~$0.";
  if (n < 0.001) return isPos ? "~$0.001" : "~-$0.001";
  if (n < 0.01) return isPos ? "~$0.01" : "~-$0.01";
  return `${isPos ? "" : "-"}$${roundNumber(n, 2).toLocaleString()}`;
};

export const formateDecimal = (amount: number) =>
  amount.toLocaleString("en-EN", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const formatDecimalWithPrecision = (amount: number, precision: number) =>
  amount.toLocaleString("en-EN", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

export const formatAaveYield = (n: string): string => {
  return roundNumber(
    Number(ethers.utils.formatEther(ethers.BigNumber.from(n).div(1e7))),
    2
  ).toLocaleString();
};

export const formatAaveYieldToNumber = (n?: string): number => {
  if (!n) return 0;
  return Number(ethers.utils.formatEther(ethers.BigNumber.from(n).div(1e7)));
};

export const formatAaveToNumber = (n: string): number => {
  return roundNumber(
    Number(ethers.utils.formatEther(ethers.BigNumber.from(n).div(1e7))),
    2
  );
};

export const formatCompoundYield = (n: string): string => {
  return roundNumber(
    Number(ethers.utils.formatEther(ethers.BigNumber.from(n).div(1e7))),
    2
  ).toLocaleString();
};

export const convertRatePerBlockToRatePerYear = (
  n: string,
  chainId: number
): string => {
  return roundNumber(
    Number(
      ethers.utils.formatEther(
        ethers.BigNumber.from(n)
          .mul(3600 * 24 * 365)
          .div(BLOCK_TIMES[chainId] ?? 1)
      )
    ),
    2
  ).toLocaleString();
};

export enum TimeScale {
  BLOCK,
  MS,
}

export const calculateRate = (
  n: string,
  chainId: number,
  scale = TimeScale.BLOCK
): string => {
  const rate = Number(ethers.utils.formatEther(n));
  if (scale === TimeScale.BLOCK)
    return (
      (Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) -
        1) *
      100
    ).toLocaleString();

  return ((Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100).toLocaleString();
};

export const calculateRateToNumber = (
  n: string,
  chainId: number,
  scale = TimeScale.BLOCK
): number => {
  const rate = Number(ethers.utils.formatEther(n));
  if (scale === TimeScale.BLOCK)
    return (
      (Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) -
        1) *
      100
    );

  return (Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100;
};

const formatNumber = (amount: number) =>
  amount.toLocaleString("en-EN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatAbbreviatedNumberNoFallback = (amount?: number) => {
  if (!amount) return "-";
  return formatAbbreviatedNumber(amount);
};

export const formatAbbreviatedNumber = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "0";
  const n = Number(amount);
  if (n < 1e-4) return "<0.0001";
  if (n < 1)
    return amount.toLocaleString("en-EN", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  if (n < 1e2) return formatSmallGeneralValue(amount);
  if (n < 1e3) return formatNumber(n);
  if (n >= 1e3 && n < 1e6) return formatNumber(n / 1e3) + "K";
  if (n >= 1e6 && n < 1e9) return formatNumber(n / 1e6) + "M";
  if (n >= 1e9 && n < 1e12) return formatNumber(n / 1e9) + "B";
  if (n >= 1e12 && n < 1e15) return formatNumber(n / 1e12) + "T";
  if (n >= 1e15 && n < 1e18) return formatNumber(n / 1e15) + "P";
  if (n !== Infinity) return formatNumber(n / 1e18) + "E";
  if (n === Infinity) return <>&infin;</>;
  return "0";
};

export const formatAbbreviatedDollarNumber = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "$0";
  const n = Math.abs(amount);
  if (amount > 0) {
    if (n < 1e3) return "$" + formatNumber(n);
    if (n >= 1e3 && n < 1e6) return "$" + formatNumber(n / 1e3) + "K";
    if (n >= 1e6 && n < 1e9) return "$" + formatNumber(n / 1e6) + "M";
    if (n >= 1e9 && n < 1e12) return "$" + formatNumber(n / 1e9) + "B";
    if (n >= 1e12 && n < 1e15) return "$" + formatNumber(n / 1e12) + "T";
    if (n >= 1e15 && n < 1e18) return "$" + formatNumber(n / 1e15) + "P";
    if (n !== Infinity) return "$" + formatNumber(n / 1e18) + "E";
    if (n === Infinity) return <>&infin;</>;
    return "0.0";
  } else {
    if (n < 1e3) return "-$" + formatNumber(n);
    if (n >= 1e3 && n < 1e6) return "-$" + formatNumber(n / 1e3) + "K";
    if (n >= 1e6 && n < 1e9) return "-$" + formatNumber(n / 1e6) + "M";
    if (n >= 1e9 && n < 1e12) return "-$" + formatNumber(n / 1e9) + "B";
    if (n >= 1e12 && n < 1e15) return "-$" + formatNumber(n / 1e12) + "T";
    if (n >= 1e15 && n < 1e18) return "-$" + formatNumber(n / 1e15) + "P";
    if (n !== Infinity) return "-$" + formatNumber(n / 1e18) + "E";
    if (n === Infinity) return <>-&infin;</>;
    return "0.0";
  }
};

export const formatAbbreviatedGeneralNumber = (amount?: number) => {
  if (amount === undefined) return "-";
  if (!amount) return "0.0";
  const sign = Number(amount) >= 0 ? "+" : "-";
  const n = Math.abs(Number(amount));
  if (n < 1e3) return sign + formatNumber(n);
  if (n >= 1e3 && n < 1e6) return sign + formatNumber(n / 1e3) + "K";
  if (n >= 1e6 && n < 1e9) return sign + formatNumber(n / 1e6) + "M";
  if (n >= 1e9 && n < 1e12) return sign + formatNumber(n / 1e9) + "B";
  if (n >= 1e12 && n < 1e15) return sign + formatNumber(n / 1e12) + "T";
  if (n >= 1e15 && n < 1e18) return sign + formatNumber(n / 1e15) + "P";
  if (n !== Infinity) return sign + formatNumber(n / 1e18) + "E";
  if (n === Infinity) return sign + <>&infin;</>;
  return "0.0";
};

interface AprData {
  apr: number;
}

export function compareApr(a: AprData, b: AprData) {
  if (a.apr < b.apr) {
    return -1;
  }
  if (a.apr > b.apr) {
    return 1;
  }
  return 0;
}

export const formatUnix = (unix: number): string => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(unix * 1000);
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  return date.getHours() + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
};

export const safeFormatUnix = (unix?: number): string | undefined => {
  if (!unix) return undefined;
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(unix * 1000);
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  return date.getHours() + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
};

export const formatSmallValueWithDefault = (
  amount?: number,
  mobile = false
) => {
  if (amount === undefined) return "-";
  const n = Number(amount);
  if (n === 0) return "0.0";
  if (n < 0.000001) return "~0";
  if (n < 0.00001) return "~0.00001";
  if (n < 0.00005) return "~0.00005";
  return mobile
    ? amount.toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : amount.toLocaleString("en-EN", {
        minimumFractionDigits: 4,
        maximumFractionDigits: 6,
      });
};

export const formatSmallUSDValueWithDefault = (amount?: number) => {
  if (amount === undefined) return "-";
  if (amount === 0) return "$0.0";
  const n = Number(amount);
  if (n < 0.001) return "< $0.001";
  if (n < 0.01) return "< $0.01";
  return `$${roundNumber(amount, 2).toLocaleString()}`;
};

export const calculateRateForCompound = (
  n: string,
  chainId: number,
  lender = Lender.COMPOUND_V3
): number => {
  let scale = TimeScale.MS;
  const rate = Number(ethers.utils.formatEther(n));
  if (scale === TimeScale.BLOCK)
    return (
      (Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) -
        1) *
      100
    );

  return (Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100;
};
