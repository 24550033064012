import { getChainInfo, getRawChainInfo } from "constants/chainInfo";
import {
  LENDERS_PER_CHAIN,
  SupportedChainId,
  getAvailableNetworkChainIds,
} from "constants/chains";
import useSelectChain from "hooks/useSelectChain";
import { AlertTriangle, ArrowDownCircle } from "react-feather";
import { useCloseModal } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import styled from "styled-components";
import { ExternalLink, MEDIA_WIDTHS } from "theme";
import { LendingProtocolLogoStandalone } from "components/ProtocolLogo";
import { toLenderText } from "constants/fusionx";
import { useChainId } from "state/globalNetwork/hooks";
import { trackChainSelected, trackLenderSelected } from "utils/analytics";
import { useViewMode } from "hooks/viewMode/useViewMode";
import { useAmendPath, useSelectLenderState } from "state/user/hooks";
import { Lender } from "types/lenderData/base";
import {
  ActiveRowWrapper,
  CircleContainer,
  FlyoutHeader,
  FlyoutRow,
  FlyoutRowActiveIndicator,
  FlyoutMenuContents,
  InnerRow,
  LenderColumn,
  LenderRow,
  LenderText,
  NetworkLabel,
  RowContainer,
  SelectorLabel,
  SelectorLogo,
  SelectorControls,
  StyledChevronDown,
} from "components/Styles/networkSelector";
import { useWeb3ReactWrapped } from "hooks/web3";
import { Menu, MenuList } from "@chakra-ui/react";
import { DarkerBoxShadow } from "components/Basic/layout";

const ActiveRowLinkList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  & > a {
    align-items: center;
    color: ${({ theme }) => theme.textSecondary};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.3;
    justify-content: space-between;
    padding: 8px 0 4px;
    text-decoration: none;
  }
  & > a:first-child {
    margin: 0;
    margin-top: 0px;
    padding-top: 10px;
  }
`;

const LinkOutCircle = styled(ArrowDownCircle)`
  transform: rotate(230deg);
  width: 16px;
  height: 16px;
`;

const NetworkAlertLabel = styled(NetworkLabel)`
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.4rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  border-radius: 40px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
  }
`;

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 16px;
  height: 16px;
`;

const BridgeLabel = ({ chainId }: { chainId: SupportedChainId }) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return <>Mantle Bridge</>;
    default:
      return <>Bridge</>;
  }
};

const FaucetLabel = ({ chainId }: { chainId: SupportedChainId }) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return <>Mantle Faucet</>;
    default:
      return <>Faucet</>;
  }
};

const ExplorerLabel = ({ chainId }: { chainId: SupportedChainId }) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return <>Mantle Explorer</>;
    default:
      return <>Etherscan</>;
  }
};

function Row({
  targetChain,
  onSelectChain,
  onSelectLender,
  selectedLender,
}: {
  targetChain: SupportedChainId;
  onSelectChain: (targetChain: number) => Promise<boolean>;
  onSelectLender: (lender: Lender) => boolean | void;
  selectedLender: Lender;
}) {
  const { provider, chainId: chainIdUser, account } = useWeb3ReactWrapped();
  const chainId = useChainId();
  const amendPath = useAmendPath();
  const closeModal = useCloseModal(ApplicationModal.NETWORK_SELECTOR);

  // if (!provider || !chainIdUser) {
  //   return null;
  // }

  const onClick = async (lender: Lender) => {
    // add history data
    amendPath(lender, targetChain);
    let chainSwitchSuccessful = true;
    // if not connected, the hook just changes the state chainId
    if (!account || targetChain !== chainIdUser) {
      chainSwitchSuccessful = await onSelectChain(targetChain);
      if (chainSwitchSuccessful && account) trackChainSelected(targetChain);
    }
    if (chainSwitchSuccessful && selectedLender !== lender) {
      onSelectLender(lender);
      account && trackLenderSelected(lender);
    }
    closeModal();
  };

  const onChainSelect = async (lender: Lender) => {
    // add history data
    amendPath(lender, targetChain);
    let chainSwitchSuccessful = true;
    // if not connected, the hook just changes the state chainId
    if (!account || targetChain !== chainIdUser) {
      chainSwitchSuccessful = await onSelectChain(targetChain);
      if (chainSwitchSuccessful && account) trackChainSelected(targetChain);
    }
    if (chainSwitchSuccessful && selectedLender !== lender) {
      onSelectLender(lender);
      account && trackLenderSelected(lender);
    }
    closeModal();
  };

  const active = chainIdUser === targetChain;
  const { helpCenterUrl, explorer, bridge, label, logoUrl, faucet } =
    getChainInfo(targetChain);

  const rowContent = (
    <RowContainer>
      <FlyoutRow
        active={active}
        onClick={async () => await onSelectChain(chainId)}
      >
        <NetworkLabel>{label}</NetworkLabel>
        {active && (
          <CircleContainer>
            <FlyoutRowActiveIndicator />
          </CircleContainer>
        )}
      </FlyoutRow>
      {/* <LenderColumn>
        {LENDERS_PER_CHAIN[targetChain].map((lender) => (
          <LenderRow
            active={targetChain === chainId && selectedLender === lender}
            key={lender}
            onClick={async () => await onClick(lender)}
          >
            <InnerRow>
              <LendingProtocolLogoStandalone
                chainId={targetChain}
                protocol={lender}
                isSelected={
                  targetChain === chainId && selectedLender === lender
                }
                width={"16px"}
                height={"16px"}
                marginRight={"10px"}
              />
              <LenderText>{toLenderText(lender)}</LenderText>
            </InnerRow>
          </LenderRow>
        ))}
      </LenderColumn> */}
    </RowContainer>
  );

  if (active) {
    return (
      <ActiveRowWrapper>
        {rowContent}
        {/* <ActiveRowLinkList>
          {bridge && (
            <ExternalLink href={bridge}>
              <BridgeLabel chainId={chainId} />
              <CircleContainer>
                <LinkOutCircle />
              </CircleContainer>
            </ExternalLink>
          )}
          {faucet && (
            <ExternalLink href={faucet}>
              <FaucetLabel chainId={chainId} />
              <CircleContainer>
                <LinkOutCircle />
              </CircleContainer>
            </ExternalLink>
          )}
          {explorer && (
            <ExternalLink href={explorer}>
              <ExplorerLabel chainId={chainId} />
              <CircleContainer>
                <LinkOutCircle />
              </CircleContainer>
            </ExternalLink>
          )}
          {helpCenterUrl && (
            <ExternalLink href={helpCenterUrl}>
              <>Help Center</>
              <CircleContainer>
                <LinkOutCircle />
              </CircleContainer>
            </ExternalLink>
          )}
        </ActiveRowLinkList> */}
      </ActiveRowWrapper>
    );
  }
  return rowContent;
}

export default function NetworkSelector() {
  const {
    provider,
    chainId: chainIdConnected,
    account,
  } = useWeb3ReactWrapped();

  const chainId = useChainId();
  const viewMode = useViewMode();

  const [selectedLender, selectLender] = useSelectLenderState();

  const info = !provider
    ? undefined
    : chainId
    ? getRawChainInfo(chainId, viewMode)
    : undefined;

  const selectChain = useSelectChain();

  if (!chainId || !provider) {
    return null;
  }

  const onSupportedChain = account
    ? chainIdConnected === chainId
    : info !== undefined;

  return (
    <Menu closeOnBlur={true}>
      <SelectorControls supportedChain={onSupportedChain}>
        {onSupportedChain ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <LendingProtocolLogoStandalone
              chainId={chainId}
              protocol={selectedLender}
              isSelected
              width={"16px"}
              height={"16px"}
              marginRight="5px"
            />
            <SelectorLabel style={{ marginRight: "20px" }}>
              {toLenderText(selectedLender)}
            </SelectorLabel> */}
            <SelectorLogo src={info?.logoUrl} />
            <SelectorLabel>{info?.label}</SelectorLabel>
            <StyledChevronDown />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NetworkIcon />
            <NetworkAlertLabel>Switch Network</NetworkAlertLabel>
            <StyledChevronDown />
          </div>
        )}
      </SelectorControls>
      <MenuList
        padding={0}
        background="none"
        border="none"
        boxShadow={DarkerBoxShadow}
        width="280px"
      >
        <FlyoutMenuContents>
          <FlyoutHeader>
            <>
              Select a {!onSupportedChain ? " supported " : ""}
              Network
            </>
          </FlyoutHeader>
          <Row
            onSelectChain={async (targetChainId: SupportedChainId) =>
              await selectChain(targetChainId)
            }
            targetChain={chainId}
            key={chainId}
            onSelectLender={(lender: Lender) => selectLender(lender)}
            selectedLender={selectedLender}
          />
          {getAvailableNetworkChainIds(viewMode)
            .filter((i) => i !== chainId)
            .map((_chainId: SupportedChainId) => (
              <Row
                onSelectChain={async (targetChainId: SupportedChainId) =>
                  await selectChain(targetChainId)
                }
                targetChain={_chainId}
                key={_chainId}
                onSelectLender={(lender: Lender) => selectLender(lender)}
                selectedLender={selectedLender}
              />
            ))}
        </FlyoutMenuContents>
      </MenuList>
    </Menu>
  );
}
