// eslint-disable-next-line no-restricted-imports
import { Percent } from "@fusionx-finance/sdk";
import { isSupportedChainId } from "lib/hooks/routing/clientSideSmartOrderRouter";
import { ChangeEvent, RefObject, useCallback, useRef, useState } from "react";
import { Settings, X } from "react-feather";
import { Text } from "rebass";
import styled, { useTheme } from "styled-components";

import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  useDarkModeManager,
  useExpertModeManager,
  useSelectAggregators,
  useSelectedLender,
  useSetPreferPermits,
} from "state/user/hooks";
import { ExternalLink, ThemedText } from "theme";
import { ButtonError, ButtonTertiary } from "../Button";
import { AutoColumn } from "../Column";
import Modal from "../Modal";
import QuestionHelper from "../QuestionHelper";
import { RowBetween, RowFixed } from "../Row";
import TransactionSettings from "../TransactionSettings";
import {
  useChainId,
  useIsImpersonated,
  useSetImpersonatedAccount,
} from "state/globalNetwork/hooks";
import { TradeAggregator } from "types/fusionx";
import Toggle from "components/Toggle";
import { Lender } from "types/lenderData/base";
import {
  BasicColumn,
  BasicRow,
  DarkerBoxShadow,
} from "components/Basic/layout";
import { isAddress } from "utils";
import { isProductionEnv } from "utils/env";
import { SearchInput } from "components/SearchModal/styleds";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";

const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.textPrimary};
  }
`;

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.textPrimary};
  }
`;

const StyledMenuButton = styled(MenuButton)<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-radius: 0.5rem;

  ${({ disabled }) =>
    !disabled &&
    `
    :hover,
    :focus {
      cursor: pointer;
      outline: none;
      opacity: 0.7;
    }
  `}
`;
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
  height: 100%;
`;

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.backgroundDark};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.backgroundTertiary};
  border-radius: 20px;
`;

export default function SettingsTab({
  placeholderSlippage,
}: {
  placeholderSlippage: Percent;
}) {
  const chainId = useChainId();
  const node = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const theme = useTheme();

  const [expertMode, toggleExpertMode] = useExpertModeManager();

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false);

  useOnClickOutside(node, open ? toggle : undefined);

  const lender = useSelectedLender();

  const [selectedAggregators, activateAggregator] = useSelectAggregators();

  const [preferPermit, switchPreferPermit] = useSetPreferPermits();

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu>
      <Modal
        isOpen={showConfirmation}
        onDismiss={() => setShowConfirmation(false)}
        maxHeight={100}
      >
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: "0 2rem" }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: "0 2rem" }}>
              <Text fontWeight={500} fontSize={20}>
                Expert mode turns off the confirm transaction prompt and allows
                high slippage trades that often result in bad rates and lost
                funds.
              </Text>
              <Text fontWeight={600} fontSize={20}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={"12px"}
                onClick={() => {
                  const confirmWord = "confirm";
                  if (
                    window.prompt(
                      `Please type the word "${confirmWord}" to enable expert mode.`
                    ) === confirmWord
                  ) {
                    toggleExpertMode();
                    setShowConfirmation(false);
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <Menu>
        <StyledMenuButton
          disabled={!isSupportedChainId(chainId)}
          id="open-settings-dialog-button"
          aria-label={"Transaction Settings"}
        >
          <StyledMenuIcon />
          {expertMode ? (
            <EmojiWrapper>
              <span role="img" aria-label="wizard-icon">
                🧙
              </span>
            </EmojiWrapper>
          ) : null}
        </StyledMenuButton>
        <MenuList
          padding={0}
          background={theme.backgroundTertiary}
          boxShadow={DarkerBoxShadow}
          border={"1px solid " + theme.container_border}
        >
          <AutoColumn gap="md" style={{ padding: "1rem" }}>
            {/* TXNS */}
            <Text fontWeight={600} fontSize={14}>
              {"Transaction Settings"}
            </Text>
            {/* <RowBetween>
              <RowFixed>
                <ThemedText.Black
                  fontWeight={400}
                  fontSize={14}
                  color={theme.textSecondary}
                >
                  Prefer Permit
                </ThemedText.Black>
                <QuestionHelper
                  text={
                    "Permit allows you to sign messages instead of sending approve transactions for margin trades."
                  }
                />
              </RowFixed>
              <Toggle
                id="prefer-permit"
                isActive={preferPermit}
                toggle={switchPreferPermit}
              />
            </RowBetween> */}
            <TransactionSettings placeholderSlippage={placeholderSlippage} />

            {/* INTERFACE */}
            {/* <Text fontWeight={600} fontSize={14}>
              Interface Settings
            </Text>
            <RowBetween>
              <RowFixed>
                <ThemedText.Black
                  fontWeight={400}
                  fontSize={14}
                  color={theme.textSecondary}
                >
                  Expert Mode
                </ThemedText.Black>
                <QuestionHelper
                  text={
                    "Allow high price impact trades and skip the confirm screen. Use at your own risk."
                  }
                />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode();
                        setShowConfirmation(false);
                      }
                    : () => {
                        toggle();
                        setShowConfirmation(true);
                      }
                }
              />
            </RowBetween> */}
            {/* <RowBetween>
              <RowFixed>
                <ThemedText.Black fontWeight={400} fontSize={14} color={theme.textSecondary}>
                  Theme
                </ThemedText.Black>
                <QuestionHelper
                  text={
                    You can choose between Dark and Light mode
                  }
                />
              </RowFixed>
              <ToggleMenuItem onClick={() => toggleDarkMode()}>
                {darkMode ? <Moon opacity={0.6} size={18} stroke={theme.primaryButton} /> : <Sun opacity={0.6} size={18} />}
              </ToggleMenuItem>
            </RowBetween> */}

            {/* IMPERSONATION */}
            {/* {!isProductionEnv() && (
              <>
                <RowFixed>
                  <Text fontWeight={600} fontSize={14}>
                    Account Impersonation
                  </Text>
                </RowFixed>
                <Impersonator />
              </>
            )} */}
          </AutoColumn>
        </MenuList>
      </Menu>
    </StyledMenu>
  );
}

const Link = styled(ExternalLink)`
  color: ${({ theme }) => theme.subText};
`;

const AGGREGATOR_TEXT = {
  [TradeAggregator.Fusionx]: ["The classic.", ""],
  [TradeAggregator.Paraswap]: ["Paraswap", "https://app.paraswap.io/"],
  [TradeAggregator.OneInch]: ["1inch.", "https://app.1inch.io/"],
};
interface AggregatorRowProps {
  selectedAggregators: TradeAggregator[];
  aggregator: TradeAggregator;
  color: string;
  triggerChange: () => void;
}

const AggregatorRow = ({
  aggregator,
  color,
  selectedAggregators,
  triggerChange,
}: AggregatorRowProps) => {
  return (
    <RowBetween>
      <RowFixed>
        <ThemedText.Black fontWeight={400} fontSize={14} color={color}>
          <Link href={AGGREGATOR_TEXT[aggregator][1]}>{aggregator}</Link>
        </ThemedText.Black>
        {/* <QuestionHelper
        text={AGGREGATOR_TEXT[aggregator][0]}
      /> */}
      </RowFixed>
      <Toggle
        id="toggle-expert-mode-button"
        isActive={selectedAggregators?.includes(aggregator)}
        toggle={triggerChange}
      />
    </RowBetween>
  );
};

const ImpersonationBox = styled(BasicColumn)`
  padding-bottom: 10px;
`;

const ManageButton = styled(ButtonTertiary)`
  padding: 5px;
  margin: 3px;
`;

const ButtonRow = styled(BasicRow)`
  padding: 3px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Impersonator = () => {
  const { setAccount, disableAccount } = useSetImpersonatedAccount();
  const { impersontedAccount, useImpersonatedAccount } = useIsImpersonated();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>();

  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }, []);

  const validInput = isAddress(searchQuery);
  const impersonationActive = Boolean(
    useImpersonatedAccount && impersontedAccount
  );

  const showEnable =
    validInput &&
    Boolean(impersontedAccount?.toLowerCase() !== validInput?.toLowerCase());

  return (
    <ImpersonationBox>
      <SearchInput
        type="text"
        id="impersonate-address-input"
        placeholder={
          impersonationActive
            ? `Impersonating: ${impersontedAccount?.slice(
                0,
                5
              )}...${impersontedAccount?.slice(-5)}`
            : "Paste address to impersonate"
        }
        autoComplete="off"
        value={searchQuery}
        ref={inputRef as RefObject<HTMLInputElement>}
        onChange={handleInput}
      />
      <ButtonRow>
        <ManageButton
          onClick={() => setAccount(searchQuery)}
          disabled={!showEnable && Boolean(!validInput || impersonationActive)}
        >
          {showEnable
            ? "Activate"
            : impersonationActive
            ? "Active"
            : validInput
            ? "Set account"
            : "Invalid"}
        </ManageButton>
        <ManageButton
          onClick={disableAccount}
          disabled={!useImpersonatedAccount}
        >
          {useImpersonatedAccount ? "Disable" : "Inactive"}
        </ManageButton>
      </ButtonRow>
    </ImpersonationBox>
  );
};
