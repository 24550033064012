import { Fraction, JSBI, TradeType } from "@fusionx-finance/sdk";
import { MarginTradeType, QuickActionType } from "types/fusionx";

import { nativeOnChain } from "constants/tokens";
import { useCurrency, useToken } from "hooks/Tokens";
import {
  ApproveTransactionInfo,
  DirectMoneyMarketTransactionInfo,
  ExactInputMarginTradeTransactionInfo,
  ExactInputMoneyMarketTransactionInfo,
  ExactInputSingleSideTransactionInfo,
  ExactInputSwapTransactionInfo,
  ExactOutputMarginTradeTransactionInfo,
  ExactOutputMoneyMarketTransactionInfo,
  ExactOutputSingleSideTransactionInfo,
  ExactOutputSwapTransactionInfo,
  TransactionInfo,
  TransactionType,
  WrapTransactionInfo,
} from "state/transactions/types";
import { BigNumber } from "ethers";

function formatAmount(
  amountRaw: string,
  decimals: number,
  sigFigs: number
): string {
  return new Fraction(
    amountRaw,
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
  ).toSignificant(sigFigs);
}

function FormattedCurrencyAmount({
  rawAmount,
  symbol,
  decimals,
  sigFigs,
}: {
  rawAmount: string;
  symbol: string;
  decimals: number;
  sigFigs: number;
}) {
  return (
    <>
      {formatAmount(rawAmount, decimals, sigFigs)} {symbol}
    </>
  );
}

function FormattedCurrencyAmountManaged({
  rawAmount,
  currencyId,
  sigFigs = 6,
}: {
  rawAmount: string;
  currencyId: string;
  sigFigs: number;
}) {
  const currency = useCurrency(currencyId);
  return currency ? (
    <FormattedCurrencyAmount
      rawAmount={rawAmount}
      decimals={currency.decimals}
      sigFigs={sigFigs}
      symbol={currency.symbol ?? "???"}
    />
  ) : null;
}

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  const token = useToken(info.tokenAddress);

  return <>Approve {token?.symbol}</>;
}

function WrapSummary({
  info: { chainId, currencyAmountRaw, unwrapped },
}: {
  info: WrapTransactionInfo;
}) {
  const native = chainId ? nativeOnChain(chainId) : undefined;

  if (unwrapped) {
    return (
      <>
        Unwrap{" "}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.wrapped?.symbol ?? "WETH"}
          decimals={18}
          sigFigs={6}
        />{" "}
        to {native?.symbol ?? "ETH"}
      </>
    );
  } else {
    return (
      <>
        Wrap{" "}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.symbol ?? "ETH"}
          decimals={18}
          sigFigs={6}
        />{" "}
        to {native?.wrapped?.symbol ?? "WETH"}
      </>
    );
  }
}

function DirectMoneyMarketSummary({
  info,
}: {
  info: DirectMoneyMarketTransactionInfo;
}) {
  const accountText = info.account ? `${info.account}: ` : "";
  if (info.type === TransactionType.DIRECT_INTERACTION) {
    return (
      <>
        {`${accountText}${info.subType}`}{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.amount}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        {[QuickActionType.Repay, QuickActionType.Deposit].includes(info.subType)
          ? " to"
          : " from"}{" "}
        {info.protocol}
      </>
    );
  } else {
    return null;
  }
}

function MoneyMarketSwapSummary({
  info,
}: {
  info:
    | ExactInputMoneyMarketTransactionInfo
    | ExactOutputMoneyMarketTransactionInfo;
}) {
  const accountText = info.account ? `${info.account}: ` : "";
  if (
    info.subType === QuickActionType.Repay ||
    info.subType === QuickActionType.Deposit
  ) {
    if (info.tradeType === TradeType.EXACT_INPUT) {
      return (
        <>
          {`${accountText}Swap exactly`}{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.inputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedOutputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />{" "}
          {`and ${info.subType} to ${info.protocol}`}
        </>
      );
    } else {
      return (
        <>
          {`${accountText}Swap`}{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedInputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for exactly{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.outputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />{" "}
          {`and ${info.subType} to ${info.protocol}`}
        </>
      );
    }
  } else {
    if (info.tradeType === TradeType.EXACT_INPUT) {
      return (
        <>
          {`${accountText}${info.subType} from ${info.protocol} and Swap exactly `}
          <FormattedCurrencyAmountManaged
            rawAmount={info.inputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedOutputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />
        </>
      );
    } else {
      return (
        <>
          {`${accountText}${info.subType} from ${info.protocol} and Swap exactly `}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedInputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for exactly{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.outputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />
        </>
      );
    }
  }
}

function SingleSideSwapSummary({
  info,
}: {
  info:
    | ExactInputSingleSideTransactionInfo
    | ExactOutputSingleSideTransactionInfo;
}) {
  const accountText = info.account ? `${info.account}: ` : "";
  if (info.tradeType === TradeType.EXACT_INPUT) {
    return (
      <>
        {`${accountText}${info.subType}-Swap exactly`}{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.inputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedOutputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />{" "}
        {`on ${info.protocol}`}
      </>
    );
  } else {
    return (
      <>
        {`${accountText}${info.subType}-Swap`}{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedInputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for exactly{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.outputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />{" "}
        {`on ${info.protocol}`}
      </>
    );
  }
}

function MarginSwapSummary({
  info,
}: {
  info:
    | ExactInputMarginTradeTransactionInfo
    | ExactOutputMarginTradeTransactionInfo;
}) {
  const accountText = info.account ? `${info.account}: ` : "";
  if (info.tradeType === TradeType.EXACT_INPUT) {
    return (
      <>
        {`${accountText}${info.subType} exactly`}{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.inputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedOutputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />{" "}
        {`on ${info.protocol}`}
      </>
    );
  } else {
    return (
      <>
        {`${accountText}${info.subType}`}{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedInputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for exactly{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.outputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />{" "}
        {`on ${info.protocol}`}
      </>
    );
  }
}

function SwapSummary({
  info,
}: {
  info: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo;
}) {
  if (info.tradeType === TradeType.EXACT_INPUT) {
    return (
      <>
        Swap exactly{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.inputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedOutputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />
      </>
    );
  } else {
    return (
      <>
        Swap{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedInputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{" "}
        for exactly{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={info.outputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />
      </>
    );
  }
}

export function TransactionSummaryV1({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.SWAP:
      return <SwapSummary info={info} />;

    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />;

    case TransactionType.WRAP:
      return <WrapSummary info={info} />;

    case TransactionType.DIRECT_INTERACTION:
      return <DirectMoneyMarketSummary info={info} />;

    case TransactionType.MONEY_MARKET:
      return <MoneyMarketSwapSummary info={info} />;

    case TransactionType.SINGLE_SIDE:
      return <SingleSideSwapSummary info={info} />;

    case TransactionType.MARGIN_TRADE:
      return <MarginSwapSummary info={info} />;
    default:
      return null;
  }
}
