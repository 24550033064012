import { createReducer } from "@reduxjs/toolkit";
import { STATE_CHAINIDS, SupportedChainId } from "constants/chains";
import {
  flushAccount,
  resetState,
  setFusionxAccount,
  setFusionxAccountMetaLoading,
} from "./actions";

export interface FusionxAccount {
  accountAddress: string;
  accountOwner: string;
  accountName: string;
  creationTimestamp: number;
  compoundSummary?: {
    markets: string[];
    liquidity: number;
    shortfall: number;
  };
}
export interface FusionxState {
  userMeta: {
    [chainId: number]: {
      loaded: boolean;
      accountsFusionx: { [index: number]: FusionxAccount };
      selectedAccountData: {
        index: number;
        account: FusionxAccount | undefined;
      };
    };
  };
}

const userMetaDummy = {
  loaded: false,
  selectedAccountData: { account: undefined, index: -1 },
  accountsFusionx: {},
};

export const initialState: FusionxState = {
  userMeta: Object.assign(
    {},
    ...STATE_CHAINIDS.map((x) => {
      return { [x]: userMetaDummy };
    })
  ),
};

export default createReducer<FusionxState>(
  initialState,
  (builder) =>
    builder
      .addCase(resetState, () => initialState)
      // ==== fusionx User Account =====
      .addCase(setFusionxAccount, (state, action) => {
        state.userMeta[action.payload.chainId].selectedAccountData = {
          account:
            state.userMeta[action.payload.chainId].accountsFusionx[
              action.payload.index
            ],
          index: action.payload.index,
        };
      })
      .addCase(flushAccount, (state) => {
        state.userMeta = Object.assign(
          {},
          ...Object.values(SupportedChainId).map((x) => {
            return { [x]: userMetaDummy };
          })
        );
      })
      .addCase(setFusionxAccountMetaLoading, (state, action) => {
        state.userMeta[action.payload.chainId].loaded = action.payload.state;
      })
  // ==== Account Fetch
);
