import { ethers } from "ethers";
import { makerMulticallAddress } from "hooks/fusionx/addresses";
import MultiCallAbi from "abis/Multicall.json";
import {
  RPC_PROVIDERS,
  getFallbackProviderByIndex,
  getProviderByIndex,
} from "constants/providers";

export type MultiCallResponse<T> = T | null;

export const simpleRpcProvider = (chainId: number) => {
  return RPC_PROVIDERS[chainId];
};

const getContract = (
  chainId: number,
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider(chainId);
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getMulticallContract = (
  chainId: number,
  providerId = 0,
  fallback = false
) => {
  return getContract(
    chainId,
    MultiCallAbi,
    makerMulticallAddress[chainId],
    fallback
      ? getFallbackProviderByIndex(chainId, providerId)
      : getProviderByIndex(chainId, providerId)
  );
};
