// lender abis
import INIT_CORE_ABI from "abis/init/core.json";
import INIT_LENS_ABI from "abis/init/initLens.json";
import LENDING_POOL_ABI from "abis/init/lendingPool.json";
import MARGIN_TRADING_HOOK_ABI from "abis/init/marginTradingHook.json";
import POS_MANAGER_ABI from "abis/init/posManager.json";

// oracles
import MANTLE_ORACLE_ABI from "abis/Oracle.json";

// erc20 - the classic
import ERC20_ABI from "abis/erc20.json";

import {
  Core,
  Erc20,
  InitLens,
  LendingPool,
  MarginTradingHook,
  PosManager,
} from "abis/types";
import { SupportedChainId } from "constants/chains";
import { RPC_PROVIDERS, getProviderByIndex } from "constants/providers";
import { Contract } from "ethers";
import { useWeb3ReactWrapped } from "hooks/web3";
import { useMemo } from "react";
import { MarginTradeType, SupportedAssets } from "types/fusionx";
import { Lender } from "types/lenderData/base";
import { getContract } from "utils";
import { addressesInitCore } from "./addressesInit";
import { addressesLendleCore } from "./addressesLendle";

/**
 *
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type
 * @param relevantAccount wallet address for comet / aave, fusionx account address for compound v2
 * @param baseAsset base asset for compound V3
 * @param isDirect direct flag (signals direct interaction with lender), makes only difference for comet / aave
 * @returns the contract
 */
export function useGetTradeContract(
  chainId: number,
  protocol: Lender,
  tradeType: MarginTradeType,
  relevantAccount?: string,
  baseAsset = SupportedAssets.USDCE,
  isDirect = false
): Contract {
  const { provider, account } = useWeb3ReactWrapped();
  return useMemo(() => {
    switch (tradeType) {
      case MarginTradeType.Open:
      case MarginTradeType.Close:
      case MarginTradeType.CollateralSwap:
      case MarginTradeType.DebtSwap: {
        switch (protocol) {
          case Lender.INIT: {
            return getContract(
              addressesInitCore.marginTradingHook[chainId],
              [...MARGIN_TRADING_HOOK_ABI],
              provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
              account
            );
          }
          default: {
            return getContract(
              addressesInitCore.marginTradingHook[chainId],
              [...MARGIN_TRADING_HOOK_ABI],
              provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
              account
            );
          }
        }
      }
      default: {
        switch (protocol) {
          default: {
            return getContract(
              addressesInitCore.marginTradingHook[chainId],
              [...MARGIN_TRADING_HOOK_ABI],
              provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
              account
            );
          }
        }
      }
    }
  }, [
    chainId,
    protocol,
    tradeType,
    relevantAccount,
    baseAsset,
    isDirect,
    provider,
    account,
  ]);
}

/**
 *
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type (MarginTradeType)
 * @param relevantAccount wallet address for comet / aave, fusionx account address for compound v2
 * @param baseAsset base asset for compound V3
 * @param isDirect direct flag (signals direct interaction with lender), makes only difference for comet / aave
 * @returns the contract
 */
export function useGetMoneyMarketTradeContracts(
  chainId: number,
  protocol: Lender,
  relevantAccount?: string,
  baseAsset = SupportedAssets.USDCE,
  isDirect = false
): [Contract, Contract] {
  const { provider, account } = useWeb3ReactWrapped();
  return useMemo(() => {
    switch (protocol) {
      case Lender.INIT: {
        const contract = getContract(
          addressesInitCore.marginTradingHook[chainId],
          MARGIN_TRADING_HOOK_ABI,
          provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
          account
        );
        return [contract, contract];
      }
    }
  }, [
    chainId,
    protocol,
    relevantAccount,
    baseAsset,
    isDirect,
    provider,
    account,
  ]);
}

export function getTokenContractWithProvider(
  chainId?: number,
  address?: string
): Erc20 | undefined {
  if (!chainId || !address) return undefined;
  return getContract(
    address,
    ERC20_ABI, // the relevant functions are the same for stable and variable debt tokens
    RPC_PROVIDERS[chainId]
  ) as Erc20;
}

export function getAaveOracleContract(
  chainId: number,
  account?: string
): Contract {
  return getContract(
    addressesLendleCore.AaveOracle[chainId],
    MANTLE_ORACLE_ABI,
    RPC_PROVIDERS[chainId as SupportedChainId],
    account
  );
}

// ********** LENDLE ************

export function usePosManagerContract(chainId: number): PosManager {
  const { provider, account } = useWeb3ReactWrapped();
  return getContract(
    addressesInitCore.PosManager[chainId],
    POS_MANAGER_ABI,
    provider as any,
    account
  ) as PosManager;
}

export function getPosManagerContract(
  chainId: number,
  account?: string
): Contract & PosManager {
  return getContract(
    addressesInitCore.PosManager[chainId],
    POS_MANAGER_ABI,
    getProviderByIndex(chainId, 0),
    account
  ) as PosManager;
}

export function getInitLensContract(chainId: number): Contract & InitLens {
  return getContract(
    addressesInitCore.PublicLens[chainId],
    INIT_LENS_ABI,
    getProviderByIndex(chainId, 0)
  ) as InitLens;
}

// account is optional
export function getInitCoreContract(
  chainId: number,
  provider?: any
): Contract & Core {
  return getContract(
    addressesInitCore.Core[chainId],
    INIT_CORE_ABI,
    provider ?? getProviderByIndex(chainId, 0)
  ) as Core;
}

export function useMarginTradingHookContract(
  chainId: number,
  account?: string
): MarginTradingHook {
  const { provider } = useWeb3ReactWrapped();
  return getContract(
    addressesInitCore.marginTradingHook[chainId],
    [...MARGIN_TRADING_HOOK_ABI],
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as MarginTradingHook;
}

export function getMarginTradingHookContract(
  chainId: number,
  account?: string
): Contract & MarginTradingHook {
  return getContract(
    addressesInitCore.marginTradingHook[chainId],
    [...MARGIN_TRADING_HOOK_ABI],
    getProviderByIndex(chainId, 0),
    account
  ) as MarginTradingHook;
}

export function getLendingPoolContract(
  address: string,
  chainId: number,
  account?: string
): Contract & LendingPool {
  return getContract(
    address,
    LENDING_POOL_ABI,
    getProviderByIndex(chainId, 0),
    account
  ) as LendingPool;
}
