import React, { useMemo } from "react";
import { Text, TextProps as TextPropsOriginal } from "rebass";
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";

import { useIsDarkMode } from "state/user/hooks";
import { colorNames } from "./colors";
import { ColorNames } from "./styled";

export * from "./components";

type TextProps = Omit<TextPropsOriginal, "css">;

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 1024,
  upToLarge: 1280,
  upToExtraLarge: 1400,
  upToExtraExtraLarge: 1530,
};

const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
};

const transitions = {
  duration: {
    slow: "500ms",
    medium: "250ms",
    fast: "125ms",
  },
  timing: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out",
  },
};

const opacities = {
  hover: 0.6,
  click: 0.4,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

function themeColors(darkMode: boolean): ColorNames {
  return {
    white: colorNames.white[darkMode ? 0 : 1],
    black: colorNames.black[darkMode ? 0 : 1],

    userThemeColor: colorNames.userThemeColor[darkMode ? 0 : 1],

    // main bgs
    backgroundPrimary: colorNames.backgroundPrimary[darkMode ? 0 : 1], // brighter background
    backgroundSecondary: colorNames.backgroundSecondary[darkMode ? 0 : 1], // used for inputs
    backgroundTertiary: colorNames.backgroundTertiary[darkMode ? 0 : 1], // general bg for cards
    backgroundDark: colorNames.backgroundDark[darkMode ? 0 : 1],
    backgroundLight: colorNames.backgroundLight[darkMode ? 0 : 1],
    backgroundLighter: colorNames.backgroundLighter[darkMode ? 0 : 1],
    backgroundNavyGray: colorNames.backgroundNavyGray[darkMode ? 0 : 1],
    backgroundNavyGrayLight:
      colorNames.backgroundNavyGrayLight[darkMode ? 0 : 1],

    // other bgs
    backgroundSurface: colorNames.backgroundSurface[darkMode ? 0 : 1],
    backgroundModule: colorNames.backgroundModule[darkMode ? 0 : 1],
    backgroundInteractive: colorNames.backgroundInteractive[darkMode ? 0 : 1],
    backgroundOutline: colorNames.backgroundOutline[darkMode ? 0 : 1],
    backgroundScrim: colorNames.backgroundScrim[darkMode ? 0 : 1],
    backgroundEmphasized: colorNames.backgroundEmphasized[darkMode ? 0 : 1],

    textPrimary: colorNames.textPrimary[darkMode ? 0 : 1],
    textSecondary: colorNames.textSecondary[darkMode ? 0 : 1],
    textTertiary: colorNames.textTertiary[darkMode ? 0 : 1],
    textAccent: colorNames.textAccent[darkMode ? 0 : 1],
    textAccentTurquoise: colorNames.textAccentTurquoise[darkMode ? 0 : 1],
    textAccentMagenta: colorNames.textAccentMagenta[darkMode ? 0 : 1],

    accentAction: colorNames.accentAction[darkMode ? 0 : 1],
    accentActionSoft: colorNames.accentActionSoft[darkMode ? 0 : 1],
    accentActive: colorNames.accentActive[darkMode ? 0 : 1],
    accentSuccess: colorNames.accentSuccess[darkMode ? 0 : 1],
    accentWarning: colorNames.accentWarning[darkMode ? 0 : 1],
    accentFailure: colorNames.accentFailure[darkMode ? 0 : 1],

    modalBG: colorNames.modalBG[darkMode ? 0 : 1],
    subText: colorNames.subText[darkMode ? 0 : 1],

    // primary color
    accent: colorNames.accent[darkMode ? 0 : 1],
    accentLight: colorNames.accentLight[darkMode ? 0 : 1],
    accentDark: colorNames.accentDark[darkMode ? 0 : 1],
    accentDarker: colorNames.accentDarker[darkMode ? 0 : 1],
    accentDarkest: colorNames.accentDarkest[darkMode ? 0 : 1],

    iconInner: colorNames.iconInner[darkMode ? 0 : 1],
    iconOuter: colorNames.iconOuter[darkMode ? 0 : 1],

    changeRed: colorNames.changeRed[darkMode ? 0 : 1],
    changeGreen: colorNames.changeGreen[darkMode ? 0 : 1],

    primaryButton: colorNames.primaryButton[darkMode ? 0 : 1],
    primaryButtonDisabled: colorNames.primaryButtonDisabled[darkMode ? 0 : 1],
    primaryTextButton: colorNames.primaryTextButton[darkMode ? 0 : 1],
    primaryTextButtonDisabled:
      colorNames.primaryTextButtonDisabled[darkMode ? 0 : 1],
    primaryButtonHover: colorNames.primaryButtonHover[darkMode ? 0 : 1],
    primaryButtonActive: colorNames.primaryButtonActive[darkMode ? 0 : 1],

    secondaryButton: colorNames.secondaryButton[darkMode ? 0 : 1],
    secondaryButtonDisabled:
      colorNames.secondaryButtonDisabled[darkMode ? 0 : 1],
    secondaryTextButton: colorNames.secondaryTextButton[darkMode ? 0 : 1],
    secondaryButtonBorder: colorNames.secondaryButtonBorder[darkMode ? 0 : 1],

    tertiaryButton: colorNames.tertiaryButton[darkMode ? 0 : 1],
    tertiaryButtonDisabled: colorNames.tertiaryButtonDisabled[darkMode ? 0 : 1],
    tertiaryTextButton: colorNames.tertiaryTextButton[darkMode ? 0 : 1],
    tertiaryButtonBorder: colorNames.tertiaryButtonBorder[darkMode ? 0 : 1],

    // other
    red: colorNames.red[darkMode ? 0 : 1],
    redDark: colorNames.redDark[darkMode ? 0 : 1],
    green: colorNames.green[darkMode ? 0 : 1],
    yellow: colorNames.yellow[darkMode ? 0 : 1],

    error: colorNames.error[darkMode ? 0 : 1],
    errorTransparent: colorNames.errorTransparent[darkMode ? 0 : 1],
    success: colorNames.success[darkMode ? 0 : 1],
    successTransparent: colorNames.successTransparent[darkMode ? 0 : 1],
    warning: colorNames.warning[darkMode ? 0 : 1],

    container_border: colorNames.container_border[darkMode ? 0 : 1],
    dropdownSubheading: colorNames.dropdownSubheading[darkMode ? 0 : 1],

    hoverState: colorNames.hoverState[darkMode ? 0 : 1],
    shadow: colorNames.shadow[darkMode ? 0 : 1],
    deepShadow: colorNames.deepShadow[darkMode ? 0 : 1],

    lendleAccent: colorNames.lendleAccent[darkMode ? 0 : 1],
  };
}

function getTheme(darkMode: boolean): DefaultTheme {
  return {
    ...themeColors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    darkMode,

    // media queries
    mediaWidth: mediaWidthTemplates,

    // adding to the theme object
    breakpoint: BREAKPOINTS,
    transition: transitions,
    opacity: opacities,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const darkMode = useIsDarkMode();
  const themeObject = useMemo(() => getTheme(darkMode), [darkMode]);
  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

const TextWrapper = styled(Text)<{ color: keyof ColorNames }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`;

/**
 * Preset styles of the Rebass Text component
 */
export const ThemedText = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"text2"} {...props} />;
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"primary1"} {...props} />;
  },
  Label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={"text1"} {...props} />;
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"text1"} {...props} />;
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"white"} {...props} />;
  },
  Body(props: TextProps) {
    return (
      <TextWrapper fontWeight={400} fontSize={16} color={"text1"} {...props} />
    );
  },
  BodySecondary(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={16}
        color={"textSecondary"}
        {...props}
      />
    );
  },
  BodyPrimary(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={16}
        color={"textPrimary"}
        {...props}
      />
    );
  },
  SubText(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={14}
        color={"subText"}
        {...props}
      />
    );
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
  },
  MediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
  },
  Yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"yellow3"} {...props} />;
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"text3"} {...props} />;
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"background3"} {...props} />;
  },
  Italic(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={12}
        fontStyle={"italic"}
        color={"text2"}
        {...props}
      />
    );
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        color={error ? "red1" : "text2"}
        {...props}
      />
    );
  },
};

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.textPrimary};
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }
  a {
    color: ${({ theme }) => theme.accentDark}; 
  }
  // Pagination
  .datatablefooter{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center
  }
  
  .datatableshowing{
    color:#6b6c72;
  }
  
  .pagination {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
    & a {
      padding: 10px;
      border: 1px solid transparent;
      color: ${({ theme }) => theme.subText};
      &:hover{
        border-radius: 16px;
        color: ${({ theme }) => theme.textPrimary}!important;
        border: 1px solid ${({ theme }) => theme.container_border};
        background-color: ${({ theme }) => theme.backgroundDark};
      }
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 0.9em;
    `}
  }
  
  
  .paginationLinkLeft {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 400;
    width: 200px;
    &:hover{
      background-color: red;
    }
  }
  .paginationLinkRight {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 400;
    width: 200px;
    &:hover{
      background-color: red;
    }
  }
  

  .paginationActive {
    & a {
      color: ${({ theme }) => theme.textPrimary}!important;
      border: 1px solid ${({ theme }) => theme.container_border};
      border-radius: 16px;
      background-color: ${({ theme }) => theme.backgroundDark};
    }
  }
  
  .paginationDisabled{
    border-radius: 16px;
    & a {
      color: ${({ theme }) => theme.subText}!important;
    }
  }

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
`;
