import { Currency, Token } from "@fusionx-finance/sdk";
import { arrayify } from "@ethersproject/bytes";
import { parseBytes32String } from "@ethersproject/strings";
import { isSupportedChain } from "constants/chains";
import { useBytes32TokenContract, useTokenContract } from "hooks/useContract";
import { useViewMode } from "hooks/viewMode/useViewMode";
import { NEVER_RELOAD, useSingleCallResult } from "lib/hooks/multicall";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { useMemo } from "react";
import { useChainId } from "state/globalNetwork/hooks";
import { supportedChainId } from "utils/supportedChainId";
import { isAddress } from "../../utils";

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;

function parseStringOrBytes32(
  str: string | undefined,
  bytes32: string | undefined,
  defaultValue: string
): string {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
    ? parseBytes32String(bytes32)
    : defaultValue;
}

/**
 * Returns a Token from the tokenAddress.
 * Returns null if token is loading or null was passed.
 * Returns undefined if tokenAddress is invalid or token does not exist.
 */
export function useTokenFromNetwork(
  tokenAddress: string | null | undefined
): Token | null | undefined {
  const chainId = useChainId();
  const viewMode = useViewMode();
  const supportedChain = isSupportedChain(chainId, viewMode);

  const formattedAddress = isAddress(tokenAddress);

  const tokenContract = useTokenContract(
    formattedAddress ? formattedAddress : undefined,
    false
  );
  const tokenContractBytes32 = useBytes32TokenContract(
    formattedAddress ? formattedAddress : undefined,
    false
  );
  const tokenName = useSingleCallResult(
    tokenContract,
    "name",
    undefined,
    NEVER_RELOAD
  );
  const tokenNameBytes32 = useSingleCallResult(
    tokenContractBytes32,
    "name",
    undefined,
    NEVER_RELOAD
  );
  const symbol = useSingleCallResult(
    tokenContract,
    "symbol",
    undefined,
    NEVER_RELOAD
  );
  const symbolBytes32 = useSingleCallResult(
    tokenContractBytes32,
    "symbol",
    undefined,
    NEVER_RELOAD
  );
  const decimals = useSingleCallResult(
    tokenContract,
    "decimals",
    undefined,
    NEVER_RELOAD
  );

  return useMemo(() => {
    if (
      typeof tokenAddress !== "string" ||
      !supportedChain ||
      !formattedAddress
    )
      return undefined;
    if (decimals.loading || symbol.loading || tokenName.loading) return null;
    if (decimals.result) {
      return new Token(
        chainId,
        formattedAddress as any,
        decimals.result[0],
        parseStringOrBytes32(
          symbol.result?.[0],
          symbolBytes32.result?.[0],
          "UNKNOWN"
        ),
        parseStringOrBytes32(
          tokenName.result?.[0],
          tokenNameBytes32.result?.[0],
          "Unknown Token"
        )
      );
    }
    return undefined;
  }, [
    formattedAddress,
    chainId,
    supportedChain,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    tokenAddress,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ]);
}

type TokenMap = { [address: string]: Token };

/**
 * Returns a Token from the tokenAddress.
 * Returns null if token is loading or null was passed.
 * Returns undefined if tokenAddress is invalid or token does not exist.
 */
export function useTokenFromMapOrNetwork(
  tokens: TokenMap,
  tokenAddress?: string | null
): Token | null | undefined {
  const address = isAddress(tokenAddress);
  const token: Token | undefined = address ? tokens[address] : undefined;
  const tokenFromNetwork = useTokenFromNetwork(
    token ? undefined : address ? address : undefined
  );

  return tokenFromNetwork ?? token;
}

/**
 * Returns a Currency from the currencyId.
 * Returns null if currency is loading or null was passed.
 * Returns undefined if currencyId is invalid or token does not exist.
 */
export function useCurrencyFromMap(
  tokens: TokenMap,
  currencyId?: string | null
): Currency | null | undefined {
  const nativeCurrency = useNativeCurrency();
  const chainId = useChainId();
  const viewMode = useViewMode();
  const isNative = Boolean(
    nativeCurrency &&
      (currencyId?.toUpperCase() === "ETH" ||
        currencyId?.toUpperCase() === "MNT" ||
        currencyId?.toUpperCase() === "MATIC")
  );

  const token = useTokenFromMapOrNetwork(
    tokens,
    isNative ? undefined : currencyId
  );
  const supportedChain = isSupportedChain(chainId, viewMode);
  if (currencyId === null || currencyId === undefined || !supportedChain)
    return null;

  // this case so we use our builtin wrapped token instead of wrapped tokens on token lists
  const wrappedNative = nativeCurrency?.wrapped;
  if (wrappedNative?.address?.toUpperCase() === currencyId?.toUpperCase())
    return wrappedNative;

  return isNative ? nativeCurrency : token;
}
