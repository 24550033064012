import { parseUnits } from "@ethersproject/units";
import {
  Currency,
  CurrencyAmount,
  Token,
} from "@fusionx-finance/swap-sdk-core";
import { SupportedChainId } from "constants/chains";
import { safeGetToken } from "hooks/fusionx/tokens";
import { SupportedAssets } from "types/fusionx";
import JSBI from "jsbi";

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export default function tryParseCurrencyAmount<T extends Currency>(
  value?: string,
  currency?: T
): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(
        currency,
        JSBI.BigInt(typedValueParsed)
      );
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return undefined;
}

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export function tryParseTokenAmount<T extends Token>(
  value?: string,
  currency?: Token
): CurrencyAmount<Token> | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(
        currency,
        JSBI.BigInt(typedValueParsed)
      );
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return undefined;
}

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export function getCurrencyAmount<T extends Currency>(
  currency?: T,
  value?: string | number
): CurrencyAmount<T> | undefined {
  // if value is undefined, but currency given, return zero
  if (currency && !value) return CurrencyAmount.fromRawAmount(currency, "0");
  // no ccy results in undefined
  if (!currency) {
    return undefined;
  }
  try {
    const valString = String(
      typeof value === "number" ? value.toFixed(currency.decimals) : value
    );
    const typedValueParsed = parseUnits(
      String(valString),
      currency.decimals
    ).toString();
    return CurrencyAmount.fromRawAmount(
      currency,
      JSBI.BigInt(typedValueParsed)
    );
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return undefined;
}

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export function getAssetAmount(
  asset?: SupportedAssets,
  chainId?: SupportedChainId,
  value?: string | number
): CurrencyAmount<Currency> | undefined {
  if (!asset || !chainId) return undefined;
  const currency = safeGetToken(chainId, asset);
  // if value is undefined, but currency given, return zero
  if (currency && !value) return CurrencyAmount.fromRawAmount(currency, "0");
  // no ccy results in undefined
  if (!currency) {
    return undefined;
  }
  try {
    const valString = String(
      typeof value === "number" ? value.toFixed(currency.decimals) : value
    );
    const typedValueParsed = parseUnits(
      String(valString),
      currency.decimals
    ).toString();
    return CurrencyAmount.fromRawAmount(
      currency,
      JSBI.BigInt(typedValueParsed)
    );
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return undefined;
}
