// eslint-disable-next-line no-restricted-imports
import { darken, lighten } from "polished";
import { useMemo } from "react";
import { AlertTriangle, ChevronDown, ChevronUp } from "react-feather";
import { useAppSelector } from "state/hooks";
import styled, { css, useTheme } from "styled-components";
import {
  isTransactionRecent,
  useAllTransactions,
} from "state/transactions/hooks";
import { TransactionDetails } from "state/transactions/types";
import { shortenAddress } from "utils";
import { ButtonSecondary } from "../Button";
import StatusIcon from "../Identicon/StatusIcon";
import Loader from "../Loader";
import { RowBetween } from "../Row";
import { getConnection } from "connection";
import { useIsMobile } from "hooks/useIsMobile";
import { useWeb3ReactWrapped } from "hooks/web3";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { DarkerBoxShadow } from "components/Basic/layout";
import WalletMenu from "components/WalletMenu";
import { useWalletMenuDisclosure } from "state/application/hooks";

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 40px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  :hover,
  :focus {
    border: none;
    outline: none;
  }
`;
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red};
  border: 1px solid ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red)};
  }
`;

const Web3StatusConnectButton = styled.button<{ faded?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  background-color: ${({ theme }) => theme.accentActionSoft};
  border-radius: 40px;
  border: none;
  cursor: pointer;
  padding: 0 12px;
  height: 40px;

  :hover,
  :active,
  :focus {
    border: none;
  }
`;

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.primaryButton};
  border: none;
  color: ${({ theme }) => theme.primaryTextButton};
  font-weight: 500;

  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.08, theme.primaryButton)};
    color: ${({ theme }) => lighten(0.1, theme.primaryTextButton)};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primaryButton};
      color: ${({ theme }) => theme.primaryTextButton};
      :hover,
      :focus {
        color: ${({ theme }) => darken(0.05, theme.primaryTextButton)};
      }
    `}
`;

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) =>
    pending ? theme.accent : theme.secondaryButton};
  border: none;
  border-radius: 40px;
  color: ${({ pending, theme }) => (pending ? theme.white : theme.textPrimary)};
  font-weight: 500;
  :hover,
  :focus {
    opacity: 0.7;
    :focus {
      opacity: 0.7;
      border: none;
    }
  }
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`;

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime;
}

const VerticalDivider = styled.div`
  height: 20px;
  margin: 0px 4px;
  width: 1px;
  background-color: ${({ theme }) => theme.accentAction};
`;

const StyledConnect = styled.div`
  color: ${({ theme }) => theme.accentAction};
  font-weight: 600;
  font-size: 16px;
  margin-right: 8px;

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.primaryTextButton)};
    transition: ${({
      theme: {
        transition: { duration, timing },
      },
    }) => `${duration.fast} color ${timing.in}`};
  }
`;

const CHEVRON_PROPS = {
  height: 20,
  width: 20,
};

function Web3StatusInner({ isOpen }: { isOpen: boolean }) {
  const { account, connector, chainId, ENSName } = useWeb3ReactWrapped();
  const connectionType = getConnection(connector).type;
  const navbarFlagEnabled = false;
  const theme = useTheme();

  const error = useAppSelector(
    (state) =>
      state.connection.errorByConnectionType[getConnection(connector).type]
  );

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);

  const hasPendingTransactions = !!pending.length;
  const isMobile = useIsMobile();

  if (!chainId) {
    return null;
  } else if (error) {
    return (
      <Web3StatusError>
        <NetworkIcon />
        <Text>Error</Text>
      </Web3StatusError>
    );
  } else if (account) {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: theme.textSecondary,
    };
    return (
      <Web3StatusConnected
        data-testid="web3-status-connected"
        pending={hasPendingTransactions}
      >
        {navbarFlagEnabled && !hasPendingTransactions && (
          <StatusIcon size={24} connectionType={connectionType} />
        )}
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>
              <div style={{ color: theme.primaryTextButton }}>
                {pending?.length} Pending
              </div>
            </Text>{" "}
            <Loader stroke={`${theme.primaryTextButton}`} />
          </RowBetween>
        ) : (
          <>
            <Text>{ENSName || shortenAddress(account, 4, isMobile)}</Text>
            {navbarFlagEnabled ? (
              isOpen ? (
                <ChevronUp {...chevronProps} />
              ) : (
                <ChevronDown {...chevronProps} />
              )
            ) : null}
          </>
        )}
        {!navbarFlagEnabled && !hasPendingTransactions && (
          <StatusIcon connectionType={connectionType} />
        )}
      </Web3StatusConnected>
    );
  } else {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: theme.accentAction,
      "data-testid": "navbar-wallet-dropdown",
    };
    return (
      <>
        {navbarFlagEnabled ? (
          <Web3StatusConnectButton faded={!account}>
            <StyledConnect data-testid="navbar-connect-wallet">
              Connect
            </StyledConnect>
            <VerticalDivider />
            {isOpen ? (
              <ChevronUp {...chevronProps} />
            ) : (
              <ChevronDown {...chevronProps} />
            )}
          </Web3StatusConnectButton>
        ) : (
          <Web3StatusConnect faded={!account}>
            <Text>Connect Wallet</Text>
          </Web3StatusConnect>
        )}
      </>
    );
  }
}

export default function Web3Status() {
  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter((tx) => tx.receipt)
    .map((tx) => tx.hash);

  const { walletMenuOpen, onCloseWalletMenu, onToggleWalletMenu } =
    useWalletMenuDisclosure();

  return (
    <Menu isOpen={walletMenuOpen} onClose={onCloseWalletMenu}>
      {({ isOpen }) => (
        <>
          <MenuButton onClick={onToggleWalletMenu}>
            <Web3StatusInner isOpen={isOpen} />
          </MenuButton>
          <MenuList
            padding={0}
            border="none"
            background="none"
            boxShadow={DarkerBoxShadow}
          >
            <WalletMenu
              pendingTransactions={pending}
              confirmedTransactions={confirmed}
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
}
