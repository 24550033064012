import { createReducer } from "@reduxjs/toolkit";
import {
  resetInitUserState,
  resetState,
  selectPosId,
  setPMode,
} from "./actions";
import { fetchInitPublicData } from "./fetchPublicData";
import { InitState } from "types/lenderData/init";
import { fetchInitUserData } from "./fetchUserData";
import { STATE_CHAINIDS } from "constants/chains";

export const DEFAULT_MODE = 4;

const emptyData = {
  publicLoaded: false,
  lenderData: {},
  userConfigs: {},
  userData: {},
  balanceData: {},
  aprData: {},
  selectedConfig: {
    id: "",
    mode: DEFAULT_MODE,
  },
};

export const initialState: InitState = Object.assign(
  {},
  ...STATE_CHAINIDS.map((cId) => {
    return { [cId]: emptyData };
  })
);

export default createReducer<InitState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(resetInitUserState, (state, action) => {
      const chainId = action.payload.chainId;
      if (state[chainId]) state[chainId].userData = {};
    })
    .addCase(selectPosId, (state, action) => {
      const { posId, chainId } = action.payload;
      state[chainId].selectedConfig.id = posId;
      state[chainId].selectedConfig.mode =
        state[chainId].userConfigs?.[posId]?.selectedMode;
    })
    .addCase(setPMode, (state, action) => {
      const { pMode, chainId } = action.payload;
      state[chainId].pMode = pMode ?? state[chainId].pMode ?? DEFAULT_MODE;
    })
    // new reserve data-fetch using aave data provider
    .addCase(fetchInitPublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data);
      const chainId = action.payload.chainId;
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData;
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        state[chainId].lenderData[assetKeys[i]] = {
          ...state[chainId].lenderData[assetKeys[i]],
          ...action.payload.data[assetKeys[i]],
        };
      }
      state[chainId].publicLoaded = true;
    })
    .addCase(fetchInitPublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchInitUserData.fulfilled, (state, action) => {
      const positionKeys = Object.keys(action.payload.positions);
      let assetKeys = action.payload.assets;
      const { chainId, account } = action.payload;
      if (!state[chainId].lenderData) return;
      if (!state[chainId]) state[chainId] = emptyData;
      // main user data
      for (let j = 0; j < positionKeys.length; j++) {
        const posId = positionKeys[j];
        const position = action.payload.positions[posId];
        const { mode } = position;

        // organic yields
        let depositInterest = 0;
        let borrowInterest = 0;
        // rewards
        let rewardDepositAccrual = 0;
        let rewardBorrowAccrual = 0;
        // staking
        let stakingDepositAccrual = 0;
        let stakingBorrowAccrual = 0;
        // amountrs
        let deposits = 0;
        let debt = 0;
        let collateral = 0;
        let borrowDiscountedCollateral = 0;
        let adjustedDebt = 0;

        const asset = assetKeys[j];
        const { amountUSD: depositsUSD } =
          action.payload.positions[posId].collateralInfo;
        const { amountUSD: debtUSD } = action.payload.positions[posId].debtInfo;
        if (!state[chainId].lenderData[asset]) continue;
        const {
          depositRate,
          stakingYield,
          variableBorrowRate,
          borrowRewards,
          collateralRewards,
        } = state[chainId].lenderData[asset];

        // amounts
        deposits += depositsUSD;
        debt += debtUSD;
        // rewards
        rewardDepositAccrual += (collateralRewards ?? 0) * depositsUSD;
        rewardBorrowAccrual += (borrowRewards ?? 0) * debtUSD;
        // staking
        stakingDepositAccrual += (stakingYield ?? 0) * depositsUSD;
        stakingBorrowAccrual += (stakingYield ?? 0) * debtUSD;

        const config = state[chainId].lenderData[asset].config[mode];

        // risk adjusted
        collateral += (config?.collateralFactor ?? 1) * depositsUSD;
        borrowDiscountedCollateral +=
          (config?.borrowCollateralFactor ?? 1) * depositsUSD;
        adjustedDebt += (config?.borrowFactor ?? 1) * debtUSD;

        // IRs
        depositInterest += depositRate * depositsUSD;
        borrowInterest += debtUSD * variableBorrowRate;

        // *************** ADD_POSITION_DATA **************
        state[chainId].userData = action.payload.positions;
        // ************************************************

        const nav = deposits - debt;
        // aggregated balance data
        state[chainId].balanceData[posId] = {
          borrowDiscountedCollateral,
          collateral,
          deposits,
          debt,
          adjustedDebt,
          nav,
        };
        if (!state[chainId].aprData) state[chainId].aprData = {};
        // aggregated apr data
        state[chainId].aprData[posId] = {
          apr: (depositInterest - borrowInterest) / nav,
          borrowApr: borrowInterest / debt,
          depositApr: depositInterest / deposits,
          rewardApr: (rewardDepositAccrual + rewardBorrowAccrual) / nav,
          rewardDepositApr: rewardDepositAccrual / deposits,
          rewardBorrowApr: rewardBorrowAccrual / debt,
          stakingApr: (stakingDepositAccrual + stakingBorrowAccrual) / nav,
          stakingDepositApr: stakingDepositAccrual / deposits,
          stakingBorrowApr: stakingBorrowAccrual / debt,
        };
      }
      if (!state[chainId].selectedConfig.id && positionKeys.length > 0) {
        const posId = positionKeys[0];
        state[chainId].selectedConfig.id = posId;
        state[chainId].selectedConfig.mode =
          action.payload.positions[posId].mode;
      }
    })
    .addCase(fetchInitUserData.pending, (state) => {
      //
    })
);
