import { Percent } from "@fusionx-finance/sdk";
import { DEFAULT_DEADLINE_FROM_NOW } from "constants/misc";
import ms from "ms.macro";
import { darken } from "polished";
import { useState } from "react";
import {
  useUserSlippageTolerance,
  useUserTransactionTTL,
} from "state/user/hooks";
import styled, { useTheme } from "styled-components";

import { ThemedText } from "theme";
import { AutoColumn } from "../Column";
import QuestionHelper from "../QuestionHelper";
import { RowBetween, RowFixed } from "../Row";

enum SlippageError {
  InvalidInput = "InvalidInput",
}

enum DeadlineError {
  InvalidInput = "InvalidInput",
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.textPrimary};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.backgroundSecondary};
  outline: none;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) =>
    active ? theme.accent : theme.backgroundInteractive};
  color: ${({ active, theme }) =>
    active ? theme.primaryTextButton : theme.textPrimary};
`;

const Input = styled.input`
  background-color: transparent;
  font-size: 16px;
  padding-right: 0.2em;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) =>
    color === "red" ? theme.red : theme.textPrimary};
  text-align: right;

  ::placeholder {
  }
`;

const OptionCustom = styled(FancyButton)<{
  active?: boolean;
  warning?: boolean;
}>`
  height: 2rem;
  position: relative;
  padding: 0 0.75rem;
  flex: 1;
  border-color: ${({ theme }) => theme.tertiaryButtonBorder};
  background-color: transparent;
  border: ${({ theme, active, warning }) =>
    active
      ? `1px solid ${warning ? theme.red : theme.accent}`
      : warning && `1px solid ${theme.red}`};
  :hover {
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`;

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`;

interface TransactionSettingsProps {
  placeholderSlippage: Percent; // varies according to the context in which the settings dialog is placed
}

const THREE_DAYS_IN_SECONDS = ms`3 days` / 1000;

export default function TransactionSettings({
  placeholderSlippage,
}: TransactionSettingsProps) {
  const theme = useTheme();

  const [userSlippageTolerance, setUserSlippageTolerance] =
    useUserSlippageTolerance();

  const [deadline, setDeadline] = useUserTransactionTTL();

  const [slippageInput, setSlippageInput] = useState("");
  const [slippageError, setSlippageError] = useState<SlippageError | false>(
    false
  );

  const [deadlineInput, setDeadlineInput] = useState("");
  const [deadlineError, setDeadlineError] = useState<DeadlineError | false>(
    false
  );

  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    setSlippageInput(value);
    setSlippageError(false);

    if (value.length === 0) {
      setUserSlippageTolerance("auto");
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100);

      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance("auto");
        if (value !== ".") {
          setSlippageError(SlippageError.InvalidInput);
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000));
      }
    }
  }

  const tooLow =
    userSlippageTolerance !== "auto" &&
    userSlippageTolerance.lessThan(new Percent(5, 10_000));
  const tooHigh =
    userSlippageTolerance !== "auto" &&
    userSlippageTolerance.greaterThan(new Percent(1, 100));

  function parseCustomDeadline(value: string) {
    // populate what the user typed and clear the error
    setDeadlineInput(value);
    setDeadlineError(false);

    if (value.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW);
    } else {
      try {
        const parsed: number = Math.floor(Number.parseFloat(value) * 60);
        if (
          !Number.isInteger(parsed) ||
          parsed < 60 ||
          parsed > THREE_DAYS_IN_SECONDS
        ) {
          setDeadlineError(DeadlineError.InvalidInput);
        } else {
          setDeadline(parsed);
        }
      } catch (error) {
        console.error(error);
        setDeadlineError(DeadlineError.InvalidInput);
      }
    }
  }

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <ThemedText.Black
            fontWeight={400}
            fontSize={14}
            color={theme.textSecondary}
          >
            Slippage tolerance
          </ThemedText.Black>
          <QuestionHelper
            text={
              <>
                Your transaction will revert if the price changes unfavorably by
                more than this percentage.
              </>
            }
          />
        </RowFixed>
        <RowBetween>
          <Option
            onClick={() => {
              parseSlippageInput("");
            }}
            active={userSlippageTolerance === "auto"}
          >
            Auto
          </Option>
          <OptionCustom
            active={userSlippageTolerance !== "auto"}
            warning={!!slippageError}
            tabIndex={-1}
          >
            <RowBetween>
              {tooLow || tooHigh ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              <Input
                placeholder={placeholderSlippage.toFixed(2)}
                value={
                  slippageInput.length > 0
                    ? slippageInput
                    : userSlippageTolerance === "auto"
                    ? ""
                    : userSlippageTolerance.toFixed(2)
                }
                onChange={(e) => parseSlippageInput(e.target.value)}
                onBlur={() => {
                  setSlippageInput("");
                  setSlippageError(false);
                }}
                color={slippageError ? "red" : ""}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowBetween>
        {slippageError || tooLow || tooHigh ? (
          <RowBetween
            style={{
              fontSize: "14px",
              paddingTop: "7px",
              color: slippageError ? "red" : "#F3841E",
            }}
          >
            {slippageError ? (
              <>Enter a valid slippage percentage</>
            ) : tooLow ? (
              <>Your transaction may fail</>
            ) : (
              <>Your transaction may be frontrun</>
            )}
          </RowBetween>
        ) : null}
      </AutoColumn>
    </AutoColumn>
  );
}
