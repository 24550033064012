import { getChainInfoOrDefault, L2ChainInfo } from "constants/chainInfo";
import { SupportedChainId } from "constants/chains";
import { AlertOctagon, AlertTriangle } from "react-feather";
import { useChainId } from "state/globalNetwork/hooks";
import styled from "styled-components";
import { ExternalLink, MEDIA_WIDTHS } from "theme";

const BodyRow = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 12px;
  font-size: 12px;
`;
const CautionOctagon = styled(AlertOctagon)`
  color: ${({ theme }) => theme.black};
`;

const CautionTriangle = styled(AlertTriangle)`
  color: ${({ theme }) => theme.accentWarning};
`;
const Link = styled(ExternalLink)`
  color: ${({ theme }) => theme.black};
  text-decoration: underline;
`;
const TitleRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
`;
const TitleText = styled.div`
  color: ${({ theme }) => theme.black};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 12px;
`;
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.yellow};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  bottom: 60px;
  display: none;
  max-width: 348px;
  padding: 16px 20px;
  position: fixed;
  right: 16px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToMedium}px) {
    display: block;
  }
`;

export function ChainConnectivityWarning() {
  const chainId = useChainId();
  const info = getChainInfoOrDefault(chainId);
  const label = info?.label;

  return (
    <Wrapper>
      <TitleRow>
        <CautionOctagon />
        <TitleText>Network Warning</TitleText>
      </TitleRow>
      <BodyRow>
        <>
          {label} might be down right now, or you may have lost your network
          connection.
        </>
        {(info as L2ChainInfo).statusPage !== undefined && (
          <span>
            Check network status{" "}
            <Link href={(info as L2ChainInfo).statusPage || ""}>here.</Link>
          </span>
        )}
      </BodyRow>
    </Wrapper>
  );
}
