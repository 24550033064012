import { useMemo } from "react";
import { useLocation } from "react-router";

export enum ViewMode {
  BETA = "BETA",
  RESTRICTED = "RESTRICTED",
  ALL = "ALL",
}

export const useViewMode = () => {
  const pathname = useLocation().pathname;
  return useMemo(() => {
    return ViewMode.ALL;
  }, [pathname]);
};
