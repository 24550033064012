import { SupportedChainId } from "constants/chains";
import { getLenderAssets } from "constants/getAssets";
import { Lender } from "types/lenderData/base";

export type AddressDictionary = {
  [prop: string]: { [chainId: number]: `0x${string}` };
};
export type FlatAddressDictionary = { [prop: string]: string };

export function getAddressesForChainIdFromAssetDict(
  dict: AddressDictionary,
  chainId: number,
  lending = Lender.INIT
): FlatAddressDictionary {
  const ob = Object.assign(
    {},
    ...getLenderAssets(chainId, lending).map((a) => {
      return {
        [a]: dict?.[a]?.[chainId],
      };
    })
  );
  return ob;
}

/**
 * Maker's Multicall V2
 * @param chainId chainId for address
 * @returns address as string
 */
export const getMulticallV2Address = (chainId: number): string => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return "0xBC0341C40e6AbFb918770D1eb1892aed40D0000c";
    default:
      return "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696";
  }
};

export const multicallAddressGeneral: { [chainId: number]: string } = {};

/**
 * Maker's Multicall V1 address
 */
export const makerMulticallAddress: { [chainId: number]: string } = {
  [SupportedChainId.MANTLE]: "0x765f8A1411e6Be23A03B7A7C7957E997f3a0a4a4",
};

export const chainlinkOracles: AddressDictionary = {};

export const getChainLinkKeys = (chainId: number): string[] => {
  return Object.keys(chainlinkOracles).filter(
    (x) => chainlinkOracles[x][chainId] !== undefined
  );
};

export const chainlinkOraclesEthereum: AddressDictionary = {};
