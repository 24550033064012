import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { SerializedBigNumber } from "types/fusionx";
import multicall, { Call } from "utils/multicall";
import INIT_PUBLIC_LENS_ABI from "abis/init/initLens.json";
import { addressesInitCore } from "hooks/fusionx/addressesInit";
import { INIT_MODES, SupportedChainId } from "constants/chains";
import { addressToAsset } from "hooks/fusionx/addressesTokens";
import { TOKEN_META } from "constants/fusionx";
import { parseRawAmount } from "utils/tableUtils/prices";

interface InitPoolReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // reserve market data
      unbacked?: number;
      depositRate: number;
      averageStableBorrowRate?: number;
      liquidityIndex?: SerializedBigNumber;
      variableBorrowIndex?: SerializedBigNumber;
      lastUpdateTimestamp?: number;

      config: {
        [modeId: number]: {
          modeId: number;
          // collateral factors
          borrowCollateralFactor: number;
          collateralFactor: number;
          borrowFactor: number;
        };
      };
      // reserve config
      decimals?: number;
      reserveFactor?: SerializedBigNumber;
      usageAsCollateralEnabled?: boolean;
      stableBorrowRateEnabled?: boolean;

      // frozen
      isActive?: boolean;
      isFrozen?: boolean;
    };
  };
  chainId: number;
}

interface InitReservesQueryParams {
  chainId: number;
  prices: { [asset: string]: number };
  stakingYields: { [asset: string]: number };
}

export const fetchInitPublicData: AsyncThunk<
  InitPoolReserveResponse,
  InitReservesQueryParams,
  any
> = createAsyncThunk<InitPoolReserveResponse, InitReservesQueryParams>(
  "lender-init/fetchInitPublicData",
  async ({ chainId, prices, stakingYields }) => {
    try {
      if (chainId !== SupportedChainId.MANTLE)
        return {
          data: {},
          chainId,
        };

      const lensAddress = addressesInitCore.PublicLens[chainId];
      const calls: Call[] = INIT_MODES.map((mode) => {
        return {
          address: lensAddress,
          name: "getMarketData",
          params: [mode],
        };
      });

      let multicallResult: any[];
      try {
        if (calls.length > 0)
          multicallResult = await multicall(
            chainId,
            [...INIT_PUBLIC_LENS_ABI],
            [...calls],
            1 // secondary
          );
        else multicallResult = [];
      } catch (err) {
        console.log("fetchInitPublicDataerror", err);
        multicallResult = [];
      }

      const modeOne = 0;
      let initData = Object.assign(
        {},
        ...multicallResult[modeOne]?.[0]
          ?.map((dat) => {
            const asset = addressToAsset(chainId, dat?.underlying);
            if (asset) {
              const meta = TOKEN_META[asset];
              const totalDeposits = parseRawAmount(
                dat?.totalSupply.toString(),
                meta.decimals
              );
              const totalDebt = parseRawAmount(
                dat?.totalDebt.toString(),
                meta.decimals
              );
              const price = prices[asset];
              const liquidity = totalDeposits - totalDebt;
              return {
                [asset]: {
                  borrowCap: parseRawAmount(
                    dat?.borrowCap.toString(),
                    meta.decimals
                  ),
                  canBurn: Boolean(dat?.canBurn),
                  canFlash: Boolean(dat?.canFlash),
                  canMint: Boolean(dat?.canMint),
                  canRepay: Boolean(dat?.canRepay),
                  underlying: parseData(dat?.underlying),
                  totalDeposits,
                  totalDebt,
                  totalDepositsUSD: price * totalDeposits,
                  totalDebtUSD: price * totalDebt,
                  totalLiquidity: liquidity,
                  totalLiquidityUSD: liquidity * price,
                  depositRate: parseRawAmount(dat?.supplyRate.toString(), 18),
                  variableBorrowRate: parseRawAmount(
                    dat?.borrowRate.toString(),
                    18
                  ),
                  stableBorrowRate: 0,
                  stakingYield: stakingYields[asset] ?? 0,
                  config: {},
                  borrowingEnabled: totalDebt !== 0,
                },
              };
            }
          })
          .filter((data) => data)
      );

      INIT_MODES.forEach((mode, index) => {
        multicallResult[index]?.data?.forEach((multicallData) => {
          const asset = addressToAsset(chainId, multicallData?.underlying);
          if (asset) {
            const cf = parseRawAmount(multicallData.collateralFactor, 18);
            initData[asset].config[mode] = {
              modeId: mode,
              borrowCollateralFactor: cf,
              collateralFactor: cf,
              borrowFactor: parseRawAmount(multicallData.borrowFactor, 18),
              maxHealthAfterLiq: parseRawAmount(
                multicallData.maxHealthAfterLiq,
                18
              ),
            };
          }
        });
      });

      return {
        data: initData,
        chainId,
      };
    } catch (e) {
      console.log("fetchInitPublicDataERROR:", e);
      return {
        data: {},
        chainId,
      };
    }
  }
);

// converts rate per second to USD per year
const convertRateToApr = (ratePerSecond: number) => {
  return ratePerSecond * 3600 * 24 * 365 * 100;
};

const parseData = (d: any) => {
  return d?.toString();
};
