import { Contract } from "@ethersproject/contracts";
import EIP_2612 from "abis/eip_2612.json";
import ENS_PUBLIC_RESOLVER_ABI from "abis/ens-public-resolver.json";
import ERC1155_ABI from "abis/erc1155.json";
import ERC20_ABI from "abis/erc20.json";
import ERC20_BYTES32_ABI from "abis/erc20_bytes32.json";
import ERC721_ABI from "abis/erc721.json";
import { EnsPublicResolver, Erc1155, Erc20, Erc721, Weth } from "abis/types";
import WETH_ABI from "abis/weth.json";
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  MULTICALL_ADDRESS,
} from "constants/addresses";
import { WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import { useMemo } from "react";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import MulticallABI from "abis/uniswap/interface-multicall.json";
import { getProviderByIndex } from "constants/providers";
import { useNetworkState } from "state/globalNetwork/hooks";
import { getContract } from "utils";
import { simpleRpcProvider } from "utils/fusionx/contractHelper";
import { useWeb3ReactWrapped } from "./web3";

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider } = useWeb3ReactWrapped();
  const { account, chainId, connectionIsSupported } = useNetworkState();
  let _currentProvider: JsonRpcProvider | Web3Provider | undefined = provider;
  if (!provider || !connectionIsSupported)
    _currentProvider = simpleRpcProvider(chainId);

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3ReactWrapped();
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  );
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false);
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false);
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean
) {
  return useContract<EnsPublicResolver>(
    address,
    ENS_PUBLIC_RESOLVER_ABI,
    withSignerIfPossible
  );
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, [...ERC20_ABI, ...EIP_2612], false);
}

// returns null on errors
export function useContractMultiProvider<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
  id = 0
): T | null {
  const { account, chainId, connectionIsSupported } = useNetworkState();
  const _currentProvider: JsonRpcProvider | Web3Provider | undefined =
    getProviderByIndex(chainId, id);

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !_currentProvider || !chainId)
      return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        _currentProvider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    _currentProvider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useInterfaceMulticall(id = 0) {
  return useContractMultiProvider<InterfaceMulticall>(
    MULTICALL_ADDRESS,
    MulticallABI,
    false,
    id
  ) as InterfaceMulticall;
}
