import { Currency } from "@fusionx-finance/sdk";
import { TOKEN_SVGS } from "constants/fusionx";
import { SupportedChainId } from "constants/chains";
import useHttpLocations from "hooks/useHttpLocations";
import { useMemo } from "react";
import { WrappedTokenInfo } from "state/lists/wrappedTokenInfo";

import EthereumLogo from "assets/images/ethereum-logo.png";
import CeloLogo from "assets/svg/celo_logo.svg";
import MaticLogo from "assets/svg/matic-token-icon.svg";
import MantleLogo from "assets/svg/mnt-logo.svg";
import { nativeOnChain } from "constants/tokens";

type Network = "ethereum" | "arbitrum" | "optimism" | "polygon";

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    default:
      return "ethereum";
  }
}

export function getNativeLogoURI(
  chainId: SupportedChainId = SupportedChainId.MANTLE
): string {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return MantleLogo;
    default:
      return EthereumLogo;
  }
}

export function getTokenLogoURI(
  address: string,
  chainId: SupportedChainId = SupportedChainId.MANTLE
): string | void {
  const networkName = chainIdToNetworkName(chainId);
  const networksWithUrls: SupportedChainId[] = [];
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`;
  }
  3;
}

export function getTokenLogoURIBySymbol(
  symbol?: string,
  chainId: SupportedChainId = SupportedChainId.MANTLE
): string | undefined {
  const networksWithUrls = [SupportedChainId.MANTLE];
  if (networksWithUrls.includes(chainId) && symbol) {
    return TOKEN_SVGS[
      symbol.toUpperCase() === "MIMATIC" ? "MAI" : symbol.toUpperCase()
    ];
  }
  return undefined;
}

export default function useCurrencyLogoURIs(
  currency?: Currency | null
): string[] {
  const locations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );
  return useMemo(() => {
    const logoURIs = [...locations];
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId));
      } else if (currency.isToken) {
        let logoURI = getTokenLogoURI(currency.address, currency.chainId);
        if (!logoURI) {
          logoURI = getTokenLogoURIBySymbol(
            currency.symbol ?? "WETH",
            currency.chainId
          );
        }
        if (logoURI) {
          logoURIs.push(logoURI);
        }
      }
    }
    return logoURIs;
  }, [currency, locations]);
}
