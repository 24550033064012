import { ChainId } from "@fusionx-finance/sdk";
import { ViewMode } from "hooks/viewMode/useViewMode";
import { Lender } from "types/lenderData/base";

/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MANTLE = 5000,
}

export const CHAIN_IDS_TO_NAMES: any = {
  [SupportedChainId.MANTLE]: "mantle",
};
const NEW_CHAINS: any[] = [];

/**
 * All compatible chainIds
 */
const CHAIN_IDS: any = [
  SupportedChainId.MANTLE,
  ...(process.env.REACT_APP_ENVIRONMENT === "production" ? [] : NEW_CHAINS),
];

export const STATE_CHAINIDS = [
  ...CHAIN_IDS,
  ...NEW_CHAINS,
  SupportedChainId.MANTLE,
];

/**
 * All compatible chainIds
 */
export const BETA_CHAINIDS = [SupportedChainId.MANTLE];

const NEW_LENDERS = [Lender.INIT];

const BETA_LENDERS = [Lender.INIT];

/**
 * All compatible lenders
 */
export const LENDERS = [
  Lender.INIT,
  ...(process.env.REACT_APP_ENVIRONMENT === "production" ? [] : NEW_LENDERS),
];

const VIEW_LENDERS: { [vieMode in ViewMode]: Lender[] } = {
  [ViewMode.BETA]: BETA_LENDERS,
  [ViewMode.RESTRICTED]: LENDERS,
  [ViewMode.ALL]: [...LENDERS, ...BETA_LENDERS],
};

const VIEW_CHAIN_IDS: { [vieMode in ViewMode]: number[] } = {
  [ViewMode.BETA]: BETA_CHAINIDS,
  [ViewMode.RESTRICTED]: CHAIN_IDS,
  [ViewMode.ALL]: [...BETA_CHAINIDS, ...CHAIN_IDS],
};

/**
 * Gets the chainIds based on verison
 * @param viewMode
 * @returns chainId array
 */
export const getAvailableChainIds = (viewMode: ViewMode) =>
  VIEW_CHAIN_IDS[viewMode];

/**
 * Gets the lenders based on verison
 * @param viewMode
 * @returns chainId array
 */
export const getAvailableLenders = (viewMode = ViewMode.ALL) =>
  VIEW_LENDERS[viewMode];

/**
 * ChainIds shown in network/lender selector
 */
const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.MANTLE,
  ...(process.env.REACT_APP_ENVIRONMENT === "production" ? [] : NEW_CHAINS),
];

/**
 * ChainIds shown in network/lender selector
 */
const BETA_NETWORK_SELECTOR_CHAINS = [SupportedChainId.MANTLE];

const VIEW_NETWORK_CHAIN_IDS: { [vieMode in ViewMode]: number[] } = {
  [ViewMode.BETA]: BETA_NETWORK_SELECTOR_CHAINS,
  [ViewMode.RESTRICTED]: NETWORK_SELECTOR_CHAINS,
  [ViewMode.ALL]: [...BETA_NETWORK_SELECTOR_CHAINS, ...NETWORK_SELECTOR_CHAINS],
};

/**
 * Get networks for the respective version to be shown on the selector
 * @param viewMode
 * @returns array of chainIds
 */
export const getAvailableNetworkChainIds = (viewMode = ViewMode.ALL) =>
  VIEW_NETWORK_CHAIN_IDS[viewMode];

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
  SupportedChainId
).filter((id) => typeof id === "number") as SupportedChainId[];

export function isSupportedChain(
  chainId: number | null | undefined,
  viewMode = ViewMode.ALL
): chainId is SupportedChainId {
  return getAvailableChainIds(viewMode).includes(Number(chainId));
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS: number[] = [];

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS: any[] = [];

export const TESTNET_CHAIN_IDS: any[] = [];

export type SupportedTestnetChainId = (typeof TESTNET_CHAIN_IDS)[number];

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [] as const;

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [] as const;

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number];

export const FALLBACK_CHAINID = SupportedChainId.MANTLE;

export const DEFAULT_CHAINID = SupportedChainId.MANTLE;

/**
 * Fetch the default chainId given the app mode
 * @param isBeta
 * @returns chainId
 */
export const getDefaultChainId = (isBeta: boolean) => SupportedChainId.MANTLE;

export const COMPOUND_CHAINIDS: any[] = [];

export const COMPOUNDV3_CHAINIDS: any[] = [];

export const AAVE_CHAINIDS: any[] = [];

export const COMPOUND_VIEW_CHAINIDS: any[] = [];

export const AAVE_VIEW_CHAINIDS = [SupportedChainId.MANTLE];

export const AAVE_ORACLE_PREFERENCE = [SupportedChainId.MANTLE];

export const PREFERRED_LENDERS: { [chainId: number]: Lender } = {
  [SupportedChainId.MANTLE]: Lender.INIT,
};

export const LENDERS_PER_CHAIN: { [chainId: number]: any } = {
  [SupportedChainId.MANTLE]: [Lender.INIT],
};

export const lenderIsOnChain = (chainId: number, lender?: Lender) =>
  lender ? LENDERS_PER_CHAIN[chainId].includes(lender) : false;

export const CHAIN_TO_ROUTE: { [chainId: number]: string } = {
  [SupportedChainId.MANTLE]: "mantle",
};

export const ROUTE_TO_CHAIN: { [cid: string]: number } = {
  mantle: SupportedChainId.MANTLE,
};

export const validateRouteToChain = (
  route: string,
  viewMode = ViewMode.ALL
) => {
  let chainId = ROUTE_TO_CHAIN[route];
  if (!chainId) chainId = preValidateChain(route);
  if (chainId && getAvailableNetworkChainIds(viewMode).includes(chainId))
    return chainId;
  return 0;
};

const preValidateChain = (query: string) => {
  const lowerCase = query.toLowerCase();

  if (
    lowerCase.includes("ma") ||
    lowerCase.includes("tl") ||
    lowerCase.includes("tle")
  )
    return SupportedChainId.MANTLE;

  return 0;
};

export const LENDER_TO_ROUTE: { [lender in Lender]: string } = {
  [Lender.INIT]: "init",
};

export const ROUTE_TO_LENDER: { [lender: string]: Lender } = {};

export const validateRouteToLender = (
  route: string,
  viewMode = ViewMode.ALL
) => {
  let lender: Lender | undefined = ROUTE_TO_LENDER[route];
  if (!lender) lender = preValidateLender(route);
  if (lender && VIEW_LENDERS[viewMode].includes(lender)) return lender;
  return Lender.INIT;
};

export const preValidateLender = (query: string) => {
  const lowerCase = query.toLowerCase();

  return undefined;
};

export const INIT_MODES = [1, 2, 3, 4];

export const INIT_EMODE_LABELS: { [mode: number]: string } = {
  [1]: "Default",
  [2]: "Blue Chip",
  [3]: "Stablecoins",
  [4]: "LST",
};

const mantleNetwork = {
  id: 5000,
  name: "Mantle",
  network: "mantle",
  nativeCurrency: {
    name: "Mantle",
    symbol: "MNT",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.mantle.xyz"],
    },
    public: {
      http: ["https://rpc.mantle.xyz"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Mantle Explorer",
      url: "https://explorer.mantle.xyz",
    },
    default: {
      name: "Mantle Explorer",
      url: "https://explorer.mantle.xyz",
    },
  },
  contracts: {
    multicall3: {
      address: "0xb55cc6B5B402437b66c13c0CEd0EF367aa7c26da",
      blockCreated: 2638,
    },
  },
  testnet: false,
};
export const CHAINS = [mantleNetwork] as any;
export const PUBLIC_NODES = {
  [ChainId.MANTLE]: ["https://rpc.mantle.xyz/"],
};

/**
 * Get the hard-coded label for a given mode per lender and chainId
 * @returns the label or mode number
 */
export const getModeLabel = (chainId: number, lender: Lender, mode: number) => {
  if (lender === Lender.INIT) return INIT_EMODE_LABELS[mode] ?? mode;
  return null;
};
