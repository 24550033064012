import { AlertCircle, CheckCircle } from "react-feather";
import { useChainId } from "state/globalNetwork/hooks";
import styled, { useTheme } from "styled-components";

import { useTransaction } from "state/transactions/hooks";
import { ThemedText } from "theme";
import { ExternalLink } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { TransactionSummaryV1 } from "../AccountDetails/TransactionSummary";
import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({ hash }: { hash: string }) {
  const chainId = useChainId();

  const tx = useTransaction(hash);
  const theme = useTheme();

  if (!tx) return null;
  const success = Boolean(tx.receipt && tx.receipt.status === 1);

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <CheckCircle color={theme.green} size={24} />
        ) : (
          <AlertCircle color={theme.red} size={24} />
        )}
      </div>
      <AutoColumn gap="8px">
        <ThemedText.Body fontWeight={500}>
          <TransactionSummaryV1 info={tx.info} />
        </ThemedText.Body>
        {chainId && (
          <ExternalLink
            href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
          >
            View on Explorer
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}
