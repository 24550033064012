// import { outboundLink } from "components/analytics";
import useCopyClipboard from "hooks/useCopyClipboard";
import React, { HTMLProps, useCallback } from "react";
import {
  ArrowLeft,
  Copy,
  ExternalLink as ExternalLinkIconFeather,
  Trash,
  X,
} from "react-feather";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { Z_INDEX } from "theme/zIndex";

import { ReactComponent as TooltipTriangle } from "assets/svg/tooltip_triangle.svg";
import { anonymizeLink } from "utils/anonymizeLink";

// TODO: Break this file into a components folder

export const CloseIcon = styled(X)<{ onClick: () => void }>`
  cursor: pointer;
`;

// for wrapper react feather icons
export const IconWrapper = styled.div<{
  stroke?: string;
  size?: string;
  marginRight?: string;
  marginLeft?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size ?? "20px"};
  height: ${({ size }) => size ?? "20px"};
  margin-right: ${({ marginRight }) => marginRight ?? 0};
  margin-left: ${({ marginLeft }) => marginLeft ?? 0};
  & > * {
    stroke: ${({ theme, stroke }) => stroke ?? theme.accentActive};
  }
`;

// A button that triggers some onClick result, but looks like a link.
export const LinkStyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  text-decoration: none;
  background: none;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ theme, disabled }) =>
    disabled ? theme.textSecondary : theme.accent};
  font-weight: 500;

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? null : "underline")};
  }

  :focus {
    outline: none;
    text-decoration: ${({ disabled }) => (disabled ? null : "underline")};
  }

  :active {
    text-decoration: none;
  }
`;

export const ButtonText = styled.button`
  outline: none;
  border: none;
  font-size: inherit;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }

  :focus {
    text-decoration: underline;
  }
`;

export const ClickableStyle = css`
  text-decoration: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`;

export const LinkStyle = css`
  color: ${({ theme }) => theme.subText};
  stroke: ${({ theme }) => theme.subText};
  font-weight: 300;
`;

// An internal link from the react-router-dom library that is correctly styled
export const StyledInternalLink = styled(Link)`
  ${ClickableStyle}
  ${LinkStyle}
`;

const LinkIconWrapper = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
`;

const IconStyle = css`
  height: 16px;
  width: 18px;
  margin-left: 10px;
`;

const LinkIcon = styled(ExternalLinkIconFeather)`
  ${IconStyle}
  ${ClickableStyle}
  ${LinkStyle}
`;

const CopyIcon = styled(Copy)`
  ${IconStyle}
  ${ClickableStyle}
  ${LinkStyle}
  stroke: ${({ theme }) => theme.accentAction};
`;

export const TrashIcon = styled(Trash)`
  ${ClickableStyle}
  ${IconStyle}
  stroke: ${({ theme }) => theme.textTertiary};

  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const rotateImg = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`;

export const UniTokenAnimated = styled.img`
  animation: ${rotateImg} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  padding: 2rem 0 0 0;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
`;

export function handleClickExternalLink(
  event: React.MouseEvent<HTMLAnchorElement>
) {
  const { target, href } = event.currentTarget;

  const anonymizedHref = anonymizeLink(href);

  // don't prevent default, don't redirect if it's a new tab
  if (target === "_blank" || event.ctrlKey || event.metaKey) {
    // outboundLink({ label: anonymizedHref }, () => {
    //   console.debug("Fired outbound link event", anonymizedHref);
    // });
  } else {
    event.preventDefault();
    // send a ReactGA event and then trigger a location change
    // outboundLink({ label: anonymizedHref }, () => {
    //   window.location.href = anonymizedHref;
    // });
  }
}

const StyledLink = styled.a`
  ${ClickableStyle}
  ${LinkStyle}
`;
/**
 * Outbound link that handles firing google analytics events
 */
export function ExternalLink({
  target = "_blank",
  href,
  rel = "noopener noreferrer",
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, "as" | "ref" | "onClick"> & {
  href: string;
}) {
  return (
    <StyledLink
      target={target}
      rel={rel}
      href={href}
      onClick={handleClickExternalLink}
      {...rest}
    />
  );
}

export function ExternalLinkIcon({
  target = "_blank",
  href,
  rel = "noopener noreferrer",
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, "as" | "ref" | "onClick"> & {
  href: string;
}) {
  return (
    <LinkIconWrapper
      target={target}
      rel={rel}
      href={href}
      onClick={handleClickExternalLink}
      {...rest}
    >
      <LinkIcon />
    </LinkIconWrapper>
  );
}

const TOOLTIP_WIDTH = 60;

const ToolTipWrapper = styled.div<{
  isCopyContractTooltip?: boolean;
  tooltipX?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: ${({ isCopyContractTooltip, tooltipX }) =>
    isCopyContractTooltip &&
    (tooltipX ? `${tooltipX - TOOLTIP_WIDTH / 2}px` : "50%")};
  transform: translate(5px, 32px);
  z-index: ${Z_INDEX.tooltip};
`;

const StyledTooltipTriangle = styled(TooltipTriangle)`
  path {
    fill: ${({ theme }) => theme.black};
  }
`;

const CopiedTooltip = styled.div<{ isCopyContractTooltip?: boolean }>`
  background-color: ${({ theme }) => theme.black};
  text-align: center;
  justify-content: center;
  width: ${({ isCopyContractTooltip }) =>
    !isCopyContractTooltip && `${TOOLTIP_WIDTH}px`};
  height: ${({ isCopyContractTooltip }) => !isCopyContractTooltip && "32px"};
  line-height: ${({ isCopyContractTooltip }) =>
    !isCopyContractTooltip && "32px"};

  padding: ${({ isCopyContractTooltip }) => isCopyContractTooltip && "8px"};
  border-radius: 8px;

  color: ${({ theme }) => theme.white};
  font-size: 12px;
`;

function Tooltip({
  isCopyContractTooltip,
  tooltipX,
}: {
  isCopyContractTooltip: boolean;
  tooltipX?: number;
}) {
  return (
    <ToolTipWrapper
      isCopyContractTooltip={isCopyContractTooltip}
      tooltipX={tooltipX}
    >
      <StyledTooltipTriangle />
      <CopiedTooltip isCopyContractTooltip={isCopyContractTooltip}>
        Copied!
      </CopiedTooltip>
    </ToolTipWrapper>
  );
}

const CopyIconWrapper = styled.div`
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export function CopyLinkIcon({ toCopy }: { toCopy: string }) {
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(toCopy);
  }, [toCopy, setCopied]);
  return (
    <CopyIconWrapper onClick={copy}>
      <CopyIcon />
      {isCopied && <Tooltip isCopyContractTooltip={false} />}
    </CopyIconWrapper>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerCss = css`
  animation: 2s ${rotate} linear infinite;
`;

const Spinner = styled.img`
  ${SpinnerCss}
  width: 16px;
  height: 16px;
`;

const BackArrowLink = styled(StyledInternalLink)`
  color: ${({ theme }) => theme.textPrimary};
`;
export function BackArrow({ to }: { to: string }) {
  return (
    <BackArrowLink to={to}>
      <ArrowLeft />
    </BackArrowLink>
  );
}

export const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  filter: invert(80%) sepia(56%) saturate(514%) hue-rotate(45deg)
    brightness(102%) contrast(84%);
`;

export const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

export const HideExtraSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

export const SmallOnly = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;
