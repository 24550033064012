import { MAINNET_CHAINS } from "constants/fusionx";
import { getLenderAssets } from "constants/getAssets";
import { SupportedAssets } from "types/fusionx";
import { Lender } from "types/lenderData/base";
import { FlatAddressDictionary } from "../fusionx/addresses";
import { addressesTokens } from "../fusionx/addressesTokens";

export const getLenderTokenAddresses = (
  chainId: number,
  protocol = Lender.INIT
): FlatAddressDictionary => {
  if (MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...getLenderAssets(chainId, protocol)
        .filter((a) => a !== SupportedAssets.ETH && a !== SupportedAssets.MATIC)
        .map((asset) => {
          return { [asset]: addressesTokens[asset][chainId] };
        })
    );
  }
};

export const getAllTokenAddresses = (
  chainId: number
): FlatAddressDictionary => {
  if (MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...Object.values(SupportedAssets)
        .filter(
          (a) =>
            a !== SupportedAssets.ETH &&
            a !== SupportedAssets.MATIC &&
            Boolean(addressesTokens[a]?.[chainId])
        )
        .map((asset) => {
          return { [asset]: addressesTokens[asset][chainId] };
        })
    );
  }
  return {};
};

export const getAllLenderTokenAddresses = (
  chainId: number
): FlatAddressDictionary => {
  if (MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...Object.values(SupportedAssets)
        .filter((a) => a !== SupportedAssets.ETH && a !== SupportedAssets.MATIC)
        .filter(
          (a) => Boolean(addressesTokens[a]?.[chainId]) // filter assets without addresses
        )
        .map((asset) => {
          return { [asset]: addressesTokens[asset]?.[chainId] };
        })
    );
  } else return {};
};
