import { SupportedChainId } from "constants/chains";
import { SupportedAssets } from "types/fusionx";

export const api3OracleAddresses: {
  [chainId: number]: { [asset: string]: string };
} = {
  [SupportedChainId.MANTLE]: {
    [SupportedAssets.WETH]: "0x009E9B1eec955E9Fe7FE64f80aE868e661cb4729",
    [SupportedAssets.METH]: "0xF55faBDf4C4F19D48d12A94209c735ca5AC43c78",
  },
};
