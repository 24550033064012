import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import multicall from "lib/state/multicall";
import { load, save } from "redux-localstorage-simple";
import { isTestEnv } from "utils/env";
import fusionx from "./fusionx/reducer";
import init from "./lenders/init/reducer";

// spot
// import spot from "./spot/reducer";
import application from "./application/reducer";
import connection from "./connection/reducer";
import globalNetwork from "./globalNetwork/reducer";
import oracles from "./oracles/reducer";
// import { updateVersion } from "./global/actions";
import multicallAlt from "lib/state/multicallAlt";
import lists from "./lists/reducer";
import margin from "./margin/reducer";
import moneyMarket from "./moneyMarket/reducer";
import transactions from "./transactions/reducer";
import user from "./user/reducer";
import wallets from "./wallets/reducer";

const PERSISTED_KEYS: string[] = ["user", "transactions", "lists"];

const store = configureStore({
  reducer: {
    application,
    user,
    connection,
    transactions,
    wallets,
    globalNetwork,

    // lenders
    init,

    fusionx,
    oracles,
    margin,
    moneyMarket,
    multicall: multicall.reducer,
    multicallAlt: multicallAlt.reducer,
    lists,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false }).concat(
      save({ states: PERSISTED_KEYS, debounce: 1000 })
    ),
  preloadedState: load({
    states: PERSISTED_KEYS,
    disableWarnings: isTestEnv(),
  }),
});

// store.dispatch(updateVersion());

setupListeners(store.dispatch);

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
