import { darken } from "polished";
import { Check, ChevronDown } from "react-feather";
import {
  Button as RebassButton,
  ButtonProps as ButtonPropsOriginal,
} from "rebass/styled-components";
import styled, { useTheme } from "styled-components";

import { RowBetween } from "../Row";

type ButtonProps = Omit<ButtonPropsOriginal, "css">;

export const BaseButton = styled(RebassButton)<
  {
    padding?: string;
    width?: string;
    $borderRadius?: string;
    altDisabledStyle?: boolean;
  } & ButtonProps
>`
  padding: ${({ padding }) => padding ?? "16px"};
  width: ${({ width }) => width ?? "100%"};
  font-weight: 500;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "40px"};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.textPrimary};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primaryButton};
  color: ${({ theme }) => theme.primaryTextButton};
  border: 1px solid transparent;
  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.accent)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primaryButtonHover)};
  }
  &:active {
    background-color: ${({ theme }) => darken(0.1, theme.primaryButtonActive)};
  }
  &:disabled {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.primaryButtonDisabled : theme.primaryButton};
    color: ${({ disabled, theme }) =>
      disabled ? theme.primaryTextButtonDisabled : theme.textSecondary};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`;

export const ButtonLight = styled(BaseButton)`
  background-color: ${({ theme }) => theme.accentDarkest};
  color: ${({ theme }) => theme.textAccent};
  font-size: 16px;
  font-weight: 500;

  &:focus {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.03, theme.accentDarkest)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.03, theme.accentDarkest)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.03, theme.accentDarkest)};
  }
  &:active {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.05, theme.accentDarkest)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.05, theme.accentDarkest)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.accentDarkest};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;

export const ButtonLightBoring = styled(BaseButton)`
  background-color: ${({ theme }) => theme.accentDarkest};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 16px;

  :disabled {
    opacity: 0.4;
    :hover {
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;

export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.05, theme.backgroundTertiary)};
  }
  &:active {
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.1, theme.backgroundTertiary)};
  }
`;

export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.tertiaryButtonBorder};
  color: ${({ theme }) => theme.secondaryTextButton};
  background-color: transparent;
  font-size: 16px;
  padding: ${({ padding }) => (padding ? padding : "10px")};

  &:focus {
    background-color: ${({ theme }) => theme.secondaryButton};
    border: 1px solid ${({ theme }) => theme.secondaryButtonBorder};
  }
  &:hover {
    background-color: ${({ theme }) => theme.secondaryButton};
    border: 1px solid ${({ theme }) => theme.secondaryButtonBorder};
  }
  &:active {
    background-color: ${({ theme }) => theme.backgroundTertiary};
    border: 1px solid ${({ theme }) => theme.tertiaryButtonBorder};
  }
  &:disabled {
    opacity: 20%;
    cursor: auto;
    background-color: ${({ theme }) => theme.secondaryButtonDisabled};
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ButtonTertiary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.accentDarker};
  color: ${({ theme }) => theme.tertiaryTextButton};
  background-color: ${({ theme }) => theme.tertiaryButton};
  border: none;
  font-size: 16px;
  padding: ${({ padding }) => (padding ? padding : "10px")};

  &:focus {
  }
  &:hover {
    opacity: 0.6;
  }
  &:active {
  }
  &:disabled {
    opacity: 20%;
    cursor: auto;
    background-color: ${({ theme }) => theme.tertiaryButtonDisabled};
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ButtonOutlined = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.backgroundTertiary};
  background-color: transparent;
  color: ${({ theme }) => theme.textPrimary};
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.backgroundLight};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.backgroundLight};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.backgroundLight};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonYellow = styled(BaseButton)`
  background-color: ${({ theme }) => theme.yellow};
  color: white;
  &:focus {
    box-shadow: ${({ theme }) => `0 0 0 1pt ${theme.yellow}`};
    background-color: ${({ theme }) => darken(0.05, theme.yellow)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.yellow)};
  }
  &:active {
    box-shadow: ${({ theme }) => `0 0 0 1pt ${darken(0.1, theme.yellow)}`};
    background-color: ${({ theme }) => darken(0.1, theme.yellow)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.yellow};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonEmpty = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.accent};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonText = styled(BaseButton)`
  padding: 0;
  width: fit-content;
  background: none;
  text-decoration: none;
  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text-decoration: underline;
  }
  &:hover {
    // text-decoration: underline;
    opacity: 0.9;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

const ButtonConfirmedStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.backgroundDark};
  color: ${({ theme }) => theme.textPrimary};
  /* border: 1px solid ${({ theme }) => theme.green}; */

  &:disabled {
    opacity: 50%;
    background-color: ${({ theme }) => theme.backgroundTertiary};
    color: ${({ theme }) => theme.textSecondary};
    cursor: auto;
  }
`;

const ButtonErrorStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.red};
  border: 1px solid ${({ theme }) => theme.red};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red)};
    background-color: ${({ theme }) => darken(0.05, theme.red)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red)};
    background-color: ${({ theme }) => darken(0.1, theme.red)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red};
    border: 1px solid ${({ theme }) => theme.red};
  }
`;

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />;
  }
}

export function ButtonError({
  error,
  ...rest
}: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}

export function ButtonDropdown({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  );
}

export function ButtonDropdownLight({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  );
}

const ActiveOutlined = styled(ButtonOutlined)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.accent};
`;

const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.accent};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  width: 20px;
  padding: 0 10px;
  position: absolute;
  top: 11px;
  right: 15px;
`;

const ResponsiveCheck = styled(Check)`
  size: 13px;
`;

export function ButtonRadioChecked({
  active = false,
  children,
  ...rest
}: { active?: boolean } & ButtonProps) {
  const theme = useTheme();

  if (!active) {
    return (
      <ButtonOutlined $borderRadius="12px" padding="12px 8px" {...rest}>
        {<RowBetween>{children}</RowBetween>}
      </ButtonOutlined>
    );
  } else {
    return (
      <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="12px">
        {
          <RowBetween>
            {children}
            <CheckboxWrapper>
              <Circle>
                <ResponsiveCheck size={13} stroke={theme.white} />
              </Circle>
            </CheckboxWrapper>
          </RowBetween>
        }
      </ActiveOutlined>
    );
  }
}
