import { createAction } from "@reduxjs/toolkit";
import { PositionType } from "constants/fusionx";
import { Field, SupportedAssets } from "types/fusionx";

export const selectMoneyMarketCurrency = createAction<{
  field: Field;
  currencyId: string;
}>("moneyMarket/selectMoneyMarketCurrency");
export const switchCurrencies = createAction<void>(
  "moneyMarket/switchCurrencies"
);
export const typeInput = createAction<{ field: Field; typedValue: string }>(
  "moneyMarket/typeInput"
);
export const replaceMoneyMarketState = createAction<{
  field: Field;
  typedValue: string;
  inputCurrencyId?: string;
  outputCurrencyId?: string;
  recipient: string | null;
  lenderAsset: SupportedAssets | null;
  positionType: PositionType | null;
  txHash: string;
}>("moneyMarket/replaceMoneyMarketState");
export const setRecipient = createAction<{ recipient: string | null }>(
  "moneyMarket/setRecipient"
);
export const setLenderAsset = createAction<{
  lenderAsset: SupportedAssets | null;
}>("moneyMarket/setLenderAsset");
export const setTxHash = createAction<{ txHash: string }>(
  "moneyMarket/setTxHash"
);
export const setPositionType = createAction<{
  positionType: PositionType | null;
}>("moneyMarket/setPositionType");
