import styled, { useTheme } from "styled-components";
import { BasicColumn, BasicRow } from "components/Basic/layout";
import { Check, ChevronDown } from "react-feather";
import { darken, lighten } from "polished";
import { MEDIA_WIDTHS } from "theme";
import { Button, MenuButton, MenuItem } from "@chakra-ui/react";
import React, { Children } from "react";

export const ActiveRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border: 1px solid ${({ theme }) => theme.container_border};
  border-radius: 40px;
  cursor: pointer;
  width: 100%;
`;
export const FlyoutHeader = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  cursor: default;
  font-weight: 400;
  text-wrap: pretty;
`;
export const FlyoutMenu = styled.div`
  position: absolute;
  top: 54px;
  width: 272px;
  z-index: 99;
  padding-top: 10px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    top: 40px;
  }
`;
export const FlyoutMenuContents = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundTertiary};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.container_border};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
export const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.backgroundPrimary : theme.backgroundDark};
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
  text-align: left;
  width: 100%;
  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.backgroundPrimary : theme.backgroundModule};
  }
`;
export const FlyoutRowActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.green};
  border-radius: 50%;
  height: 9px;
  width: 9px;
`;

export const CircleContainer = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;
export const NetworkLabel = styled.div`
  font-size: 14px;
  flex: 1 1 auto;
`;
export const SelectorLabel = styled(NetworkLabel)`
  display: none;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
    margin-right: 8px;
  }
`;

export const SelectorControls = ({
  children,
  supportedChain,
}: {
  children: React.ReactNode;
  supportedChain: boolean;
}) => {
  const theme = useTheme();

  return (
    <MenuButton
      alignItems="center"
      borderRadius="40px"
      color={supportedChain ? theme.textPrimary : theme.white}
      fontWeight={500}
      justifyContent="space-between"
      padding="6px 8px"
      cursor="pointer"
      w={"100%"}
      backgroundColor={supportedChain ? theme.backgroundInteractive : theme.red}
      border={supportedChain ? `transparent` : `2px solid ${theme.red}`}
      display="flex"
      _hover={{
        backgroundColor: theme.secondaryButton,
      }}
      _active={{
        backgroundColor: theme.secondaryButton,
      }}
    >
      {children}
    </MenuButton>
  );
};

export const SelectorLogo = styled(Logo)`
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 8px;
  }
`;
export const SelectorWrapper = styled.div`
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    position: relative;
  }
`;
export const StyledChevronDown = styled(ChevronDown)`
  width: 16px;
`;

export const LenderRow = ({
  active,
  onClick,
  children,
}: {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <MenuItem
      onClick={onClick}
      justifyContent="space-between"
      alignItems="center"
      borderRadius="10px"
      padding="3px"
      margin="2px"
      backgroundColor={
        active
          ? theme.darkMode
            ? lighten(0.05, theme.backgroundTertiary)
            : darken(0.05, theme.backgroundTertiary)
          : "transparent"
      }
      _hover={{
        cursor: "pointer",
        backgroundColor: theme.darkMode
          ? lighten(0.05, theme.backgroundTertiary)
          : darken(0.05, theme.backgroundTertiary),
        filter: "none",
        opacity: 1,
        border: "",
      }}
      filter={active ? "none" : "grayscale(100%)"}
      transition="100ms ease-in"
      opacity={active ? 1 : 0.6}
    >
      {children}
    </MenuItem>
  );
};

export const InnerRow = styled(BasicRow)`
  justify-content: flex-start;
  align-items: center;
`;

export const LenderColumn = styled(BasicColumn)`
  width: 90%;
  margin: 1px;
  margin-left: 10px;
`;

export const RowContainer = styled(BasicColumn)`
  width: 100%;
`;

export const LenderText = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  font-weight: 350;
  font-size: 12px;
  letter-spacing: 0px;
`;

export const Circle = styled.div<{ active: boolean; size: number }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 50%;
  transition: 100ms ease-in;
  opacity: ${({ active }) => (active ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.accentAction};
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
`;

export const CheckboxWrapper = styled.div<{ size: number }>`
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.accentActionSoft};
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  display: flex;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
  transition: 100ms ease-in;
  &:hover {
    border: 2px solid ${({ theme }) => theme.accentAction};
    cursor: pointer;
  }
`;

export const ResponsiveCheck = styled(Check)``;
