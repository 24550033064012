import { BasicRow } from "components/Basic/layout";
import { useCurrency } from "hooks/Tokens";
import { CheckCircle, Triangle } from "react-feather";
import styled from "styled-components";

import { useAllTransactions } from "state/transactions/hooks";
import { ExternalLink } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import Loader from "../Loader";
import { TransactionSummaryV1 } from "./TransactionSummary";
import { useSelectedLender } from "state/user/hooks";
import CurrencyLogo from "components/CurrencyLogo";
import { useWeb3ReactWrapped } from "hooks/web3";

const TransactionStatusText = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.subText};
  text-wrap: wrap;
`;

const Image = styled.img`
  width: 18px;
  height: 18px;
`;

const TransactionState = styled(ExternalLink)<{
  pending: boolean;
  success?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.accent};
`;

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) =>
    pending ? theme.accent : success ? theme.green : theme.red};
`;

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useWeb3ReactWrapped();
  const allTransactions = useAllTransactions();

  const tx = allTransactions?.[hash];
  const info: any = tx?.info;
  const pending = !tx?.receipt;
  const success =
    !pending &&
    tx &&
    (tx.receipt?.status === 1 || typeof tx.receipt?.status === "undefined");

  const lender = useSelectedLender();
  const ccyIn = useCurrency(info?.["inputCurrencyId"]);
  const ccyOut = useCurrency(info?.["outputCurrencyId"]);

  if (!chainId) return null;
  else
    return (
      <TransactionRow>
        <TransactionState
          href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
          pending={pending}
          success={success}
        >
          <FlexStartRow>
            {(ccyIn || ccyOut) && (
              <IconContainer>
                {ccyIn && <CurrencyLogo currency={ccyIn} size={"24px"} />}
                {ccyOut && (
                  <CurrencyLogo
                    currency={ccyOut}
                    size={"24px"}
                    style={{ marginLeft: "-5px" }}
                  />
                )}
              </IconContainer>
            )}
            <TransactionStatusText>
              {/* TODO fix */}
              {/* <TransactionSummaryV1 info={info} /> ↗ */}
            </TransactionStatusText>
          </FlexStartRow>
        </TransactionState>
        <IconWrapper pending={pending} success={success}>
          {pending ? (
            <Loader />
          ) : success ? (
            <CheckCircle size="16" />
          ) : (
            <Triangle size="16" />
          )}
        </IconWrapper>
      </TransactionRow>
    );
}

const IconContainer = styled(BasicRow)`
  align-items: center;
  justify-content: flex-start;
`;

const FlexStartRow = styled(BasicRow)`
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
`;

const TransactionRow = styled(BasicRow)`
  align-items: center;
  justify-content: space-between;
`;
