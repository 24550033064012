import { BigNumber } from "ethers";
import { AaveInterestMode, SupportedAssets, TokenMeta } from "types/fusionx";

import { default as initSVG } from "assets/svg/init.svg";
import { default as wethLogoUrl } from "assets/svg/tokens/weth.svg";
import { default as usdyLogoUrl } from "assets/svg/tokens/usdy.svg";
import { default as daiLogoUrl } from "assets/svg/tokens/dai.svg";
import { default as linkLogoUrl } from "assets/svg/tokens/link.svg";
import { default as usdcLogoUrl } from "assets/svg/tokens/usdc.svg";
import { default as wbtcLogoUrl } from "assets/svg/tokens/wbtc.svg";
import { default as usdtLogoUrl } from "assets/svg/tokens/usdt.svg";
import { default as aaveLogoUrl } from "assets/svg/tokens/aave.svg";
import { default as ethLogoUrl } from "assets/svg/tokens/eth.svg";
import { default as uniLogoUrl } from "assets/svg/tokens/uni.svg";
import { default as eursLogoUrl } from "assets/svg/tokens/eurs.svg";
import { default as compLogoUrl } from "assets/svg/tokens/comp.svg";
import { default as wmaticLogoUrl } from "assets/svg/tokens/wmatic.svg";
import { default as ghstLogoUrl } from "assets/svg/tokens/ghst.svg";
import { default as jeurLogoUrl } from "assets/svg/tokens/jeur.svg";
import { default as dpiLogoUrl } from "assets/svg/tokens/dpi.svg";
import { default as sushiLogoUrl } from "assets/svg/tokens/sushi.svg";
import { default as balLogoUrl } from "assets/svg/tokens/bal.svg";
import { default as ageurLogoUrl } from "assets/svg/tokens/ageur.svg";
import { default as crvLogoUrl } from "assets/svg/tokens/crv.svg";
import { default as batLogoUrl } from "assets/svg/tokens/bat.svg";
import { default as feiLogoUrl } from "assets/svg/tokens/fei.svg";
import { default as usdpLogoUrl } from "assets/svg/tokens/usdp.svg";
import { default as zrxLogoUrl } from "assets/svg/tokens/zrx.svg";
import { default as mkrLogoUrl } from "assets/svg/tokens/mkr.svg";
import { default as saiLogoUrl } from "assets/svg/tokens/sai.svg";
import { default as repLogoUrl } from "assets/svg/tokens/rep.svg";
import { default as yfiLogoUrl } from "assets/svg/tokens/yfi.svg";
import { default as tusdLogoUrl } from "assets/svg/tokens/tusd.svg";
import { default as stmaticLogoUrl } from "assets/svg/tokens/stmatic.svg";
import { default as maticxLogoUrl } from "assets/svg/tokens/maticx.svg";
import { default as mimaticLogoUrl } from "assets/svg/tokens/mimatic.svg";
import { default as gdaiLogoUrl } from "assets/svg/tokens/gdai.svg";
import { default as wstethLogoUrl } from "assets/svg/tokens/wsteth.svg";
import { default as vghstLogoUrl } from "assets/svg/tokens/vghst.svg";
import { default as ghoLogoUrl } from "assets/svg/tokens/gho.svg";

import { default as dogeLogoUrl } from "assets/svg/tokens/doge.svg";
import { default as bnbLogoUrl } from "assets/svg/tokens/bnb.svg";
import { default as bchLogoUrl } from "assets/svg/tokens/bch.svg";
import { default as xrpLogoUrl } from "assets/svg/tokens/xrp.svg";
import { default as ltcLogoUrl } from "assets/svg/tokens/ltc.svg";
import { default as lunaLogoUrl } from "assets/svg/tokens/luna.svg";
import { default as vaiLogoUrl } from "assets/svg/tokens/vai.svg";
import { default as xvsLogoUrl } from "assets/svg/tokens/xvs.svg";
import { default as wbethLogoUrl } from "assets/svg/tokens/wbeth.svg";
import { default as vrtLogoUrl } from "assets/svg/tokens/vrt.svg";
import { default as trxLogoUrl } from "assets/svg/tokens/trx.svg";
import { default as sxpLogoUrl } from "assets/svg/tokens/sxp.svg";
import { default as ustLogoUrl } from "assets/svg/tokens/ust.svg";
import { default as filLogoUrl } from "assets/svg/tokens/fil.svg";
import { default as dotLogoUrl } from "assets/svg/tokens/dot.svg";
import { default as adaLogoUrl } from "assets/svg/tokens/ada.svg";
import { default as bethLogoUrl } from "assets/svg/tokens/beth.svg";
import { default as cakeLogoUrl } from "assets/svg/tokens/cake.svg";
// import { default as canLogoUrl } from 'assets/svg/tokens/can.svg'
import { default as btcbLogoUrl } from "assets/svg/tokens/btcb.svg";
import { default as busdLogoUrl } from "assets/svg/tokens/busd.svg";
import { default as mntLogoUrl } from "assets/svg/tokens/mnt.svg";
import { default as methLogoUrl } from "assets/svg/tokens/meth.svg";
import { default as lendLogoUrl } from "assets/svg/tokens/lend.svg";

import { default as ovix } from "assets/svg/ovix-logo.svg";
import { default as aave } from "assets/svg/aave-logo.svg";
import { default as compoundV3 } from "assets/svg/compound-v3-logo.svg";
import { default as lendle } from "assets/svg/lendle-logo.svg";
import { default as compound } from "assets/svg/compound-logo.svg";
import { default as fusionxAccount } from "assets/svg/logo_light.svg";

import { default as ovixStandalone } from "assets/svg/logos/logo-0vix.svg";
import { default as aaveStandalone } from "assets/svg/logos/logo-aave.svg";
import { default as compoundV3Standalone } from "assets/svg/logos/logo-compound.svg";
import { default as compoundStandalone } from "assets/svg/logos/logo-compound-2.svg";

import { default as eMatic } from "assets/svg/e-matic.svg";
import { default as eDefault } from "assets/svg/e-default.svg";
import { default as eEth } from "assets/svg/e-eth.svg";
import { default as eStable } from "assets/svg/e-stable.svg";
import { default as deactivated } from "assets/svg/deactivated.svg";

import { SupportedChainId } from "./chains";
import { Lender } from "types/lenderData/base";

export const TEN = BigNumber.from(10);

export const ZERO_BN = BigNumber.from(0);

export const LTV_PRECISION = TEN.pow(18);

export const AAVE_PRICE_PRECISION = TEN.pow(8);

export const CHAINLINK_PRICE_PRECISION = TEN.pow(8);

export const ONE_18 = TEN.pow(18);

export const BPS_BN = BigNumber.from(10000);

export const BPS = 10000;

export const C_TOKEN_DECIMALS = 8;

export const C_TOKEN_PRICE_DECIMALS = 18;

export const RESERVE_MANTISSA_DECIMALS = 18;

//decimals for collateral and debt factor
export const COMET_RISK_DECIMALS = 18;

export const COMET_PRICE_DECIMALS = 18;

export const TOKEN_META: { [k: string]: TokenMeta } = {
  [SupportedAssets.USDY]: {
    symbol: "USDY",
    name: "Ondo USD Yield",
    decimals: 18,
  },
  [SupportedAssets.WETH]: {
    symbol: "WETH",
    name: "Wrapped Ether",
    decimals: 18,
  },
  [SupportedAssets.USDCE]: {
    symbol: "USDC.E",
    name: "Bridged USD Coin",
    decimals: 6,
  },
  [SupportedAssets.USDC]: { symbol: "USDC", name: "USD Coin", decimals: 6 },
  [SupportedAssets.WBTC]: {
    symbol: "WBTC",
    name: "Wrapped Bitcoin",
    decimals: 8,
  },
  [SupportedAssets.USDT]: { symbol: "USDT", name: "Tether", decimals: 6 },

  [SupportedAssets.AAVE]: { symbol: "AAVE", name: "AAVE Token", decimals: 18 },
  [SupportedAssets.EURS]: { symbol: "EURS", name: "Stasis Euro", decimals: 2 },
  [SupportedAssets.WMATIC]: {
    symbol: "WMATIC",
    name: "Wrapped Matic",
    decimals: 18,
  },
  [SupportedAssets.AGEUR]: {
    symbol: "AGEUR",
    name: "Angle Euro",
    decimals: 18,
  },
  [SupportedAssets.BAL]: { symbol: "BAL", name: "Balancer", decimals: 18 },
  [SupportedAssets.CRV]: { symbol: "CRV", name: "Curve", decimals: 18 },
  [SupportedAssets.DPI]: { symbol: "DPI", name: "DeFi Pulse", decimals: 18 },
  [SupportedAssets.GHST]: { symbol: "GHST", name: "Aavegotchi", decimals: 18 },
  [SupportedAssets.JEUR]: { symbol: "JEUR", name: "Jarvis Euro", decimals: 18 },
  [SupportedAssets.SUSHI]: {
    symbol: "SUSHI",
    name: "Sushi Token",
    decimals: 18,
  },
  [SupportedAssets.ETH]: { symbol: "ETH", name: "Ether", decimals: 18 },
  [SupportedAssets.MATIC]: { symbol: "MATIC", name: "Matic", decimals: 18 },
  [SupportedAssets.COMP]: { symbol: "COMP", name: "Compound", decimals: 18 },
  [SupportedAssets.BAT]: {
    symbol: "BAT",
    name: "Basic Attention Token",
    decimals: 18,
  },
  [SupportedAssets.FEI]: { symbol: "FEI", name: "Fei USD", decimals: 18 },
  [SupportedAssets.MKR]: { symbol: "MKR", name: "Maker", decimals: 18 },
  [SupportedAssets.ZRX]: { symbol: "ZRX", name: "ZRX", decimals: 18 },
  [SupportedAssets.YFI]: { symbol: "YFI", name: "Yearn", decimals: 18 },
  [SupportedAssets.WBTC2]: {
    symbol: "WBTC2",
    name: "Wrapped Bitcoin",
    decimals: 8,
  },
  [SupportedAssets.USDP]: {
    symbol: "USDP",
    name: "USDP Stablecoin",
    decimals: 18,
  },
  [SupportedAssets.TUSD]: { symbol: "TUSD", name: "True USDC", decimals: 18 },
  [SupportedAssets.SAI]: { symbol: "SAI", name: "Sai", decimals: 18 },
  [SupportedAssets.REP]: { symbol: "REP", name: "REP", decimals: 18 },
  [SupportedAssets.MATICX]: {
    symbol: "MATICX",
    name: "Stader MaticX",
    decimals: 18,
  },
  [SupportedAssets.MAI]: { symbol: "MAI", name: "MAI", decimals: 18 },
  MIMATIC: { symbol: "MAI", name: "MAI", decimals: 18 },
  miMATIC: { symbol: "MAI", name: "MAI", decimals: 18 },
  [SupportedAssets.STMATIC]: {
    symbol: "STMATIC",
    name: "Lido Staked Matic",
    decimals: 18,
  },
  [SupportedAssets.VGHST]: {
    symbol: "VGHST",
    name: "Gotchi Vault",
    decimals: 18,
  },
  [SupportedAssets.GDAI]: { symbol: "GDAI", name: "Gains DAI", decimals: 18 },
  [SupportedAssets.WSTETH]: {
    symbol: "WSTETH",
    name: "Wrapped stETH",
    decimals: 18,
  },
  [SupportedAssets.GHO]: {
    symbol: "GHO",
    name: "GHO Stablecoin",
    decimals: 18,
  },
  [SupportedAssets.USD]: { symbol: "USD", name: "Dollar", decimals: 18 },
  [SupportedAssets.EUR]: { symbol: "EUR", name: "Euro", decimals: 18 },
  // Venus additions
  [SupportedAssets.ADA]: { symbol: "ADA", name: "Cardano", decimals: 18 },
  [SupportedAssets.BCH]: { symbol: "BCH", name: "Bitcoin Cash", decimals: 18 },
  [SupportedAssets.BETH]: { symbol: "BETH", name: "Beacon ETH", decimals: 18 },
  [SupportedAssets.BTCB]: {
    symbol: "BTCB",
    name: "Binance-peg BTC",
    decimals: 18,
  },
  [SupportedAssets.BUSD]: { symbol: "BUSD", name: "Binance USD", decimals: 18 },
  [SupportedAssets.CAKE]: { symbol: "CAKE", name: "Pancakeswap", decimals: 18 },
  [SupportedAssets.CAN]: { symbol: "CAN", name: "CAN", decimals: 18 },
  [SupportedAssets.DOGE]: { symbol: "DOGE", name: "Dogecin", decimals: 8 },
  [SupportedAssets.DOT]: { symbol: "DOT", name: "Polkadot", decimals: 18 },
  [SupportedAssets.FIL]: { symbol: "FIL", name: "Filecoin", decimals: 18 },
  [SupportedAssets.LTC]: { symbol: "LTC", name: "Litecoin", decimals: 18 },
  [SupportedAssets.LUNA]: { symbol: "LUNA", name: "Terra", decimals: 18 },
  [SupportedAssets.SXP]: { symbol: "SXP", name: "Swipe", decimals: 18 },
  [SupportedAssets.TRX]: { symbol: "TRX", name: "Tron Token", decimals: 6 },
  [SupportedAssets.TRXOLD]: {
    symbol: "TRXOLD",
    name: "Tron Token",
    decimals: 18,
  },
  [SupportedAssets.TUSDOLD]: {
    symbol: "TUSDOLD",
    name: "True USD",
    decimals: 18,
  },
  [SupportedAssets.UST]: { symbol: "UST", name: "Terra USD", decimals: 18 },
  [SupportedAssets.VAI]: { symbol: "VAI", name: "VAI", decimals: 18 },
  [SupportedAssets.VRT]: {
    symbol: "VRT",
    name: "Venus Reward Token",
    decimals: 18,
  },
  [SupportedAssets.WBETH]: {
    symbol: "WBETH",
    name: "Wrapped Beacon ETH",
    decimals: 18,
  },
  [SupportedAssets.XRP]: { symbol: "XRP", name: "Ripple", decimals: 18 },
  [SupportedAssets.XVS]: { symbol: "XVS", name: "Venus Token", decimals: 18 },
  [SupportedAssets.BNB]: { symbol: "BNB", name: "Binance Coin", decimals: 18 },
  [SupportedAssets.WBNB]: { symbol: "WBNB", name: "Wrapped BNB", decimals: 18 },
  [SupportedAssets.BUSDC]: {
    symbol: "BUSDC",
    name: "Binance-peg USDC",
    decimals: 18,
  },
  [SupportedAssets.BUSDT]: {
    symbol: "BUSDT",
    name: "Binance-peg USDT",
    decimals: 18,
  },
  [SupportedAssets.WMNT]: { symbol: "WMNT", name: "Wrapped MNT", decimals: 18 },
  [SupportedAssets.MNT]: { symbol: "MNT", name: "Mantle", decimals: 18 },
  [SupportedAssets.METH]: {
    symbol: "METH",
    name: "Mantle Staked Ether",
    decimals: 18,
  },
  LEND: { symbol: "LEND", name: "Lendle", decimals: 18 },
};

export enum OtherAssets {
  ETH = "ETH",
  BNB = "BNB",
}

export const MAINNET_CHAINS = [SupportedChainId.MANTLE];

export const TOKEN_SVGS: { [asset: string]: string } = {
  [SupportedAssets.WETH]: wethLogoUrl,
  [SupportedAssets.USDY]: usdyLogoUrl,
  [SupportedAssets.DAI]: daiLogoUrl,
  [SupportedAssets.LINK]: linkLogoUrl,
  [SupportedAssets.USDCE]: usdcLogoUrl,
  [SupportedAssets.USDC]: usdcLogoUrl,
  [SupportedAssets.WBTC]: wbtcLogoUrl,
  [SupportedAssets.USDT]: usdtLogoUrl,
  [SupportedAssets.AAVE]: aaveLogoUrl,
  [SupportedAssets.EURS]: eursLogoUrl,
  [SupportedAssets.WMATIC]: wmaticLogoUrl,
  [SupportedAssets.AGEUR]: ageurLogoUrl,
  [SupportedAssets.BAL]: balLogoUrl,
  [SupportedAssets.CRV]: crvLogoUrl,
  [SupportedAssets.DPI]: dpiLogoUrl,
  [SupportedAssets.GHST]: ghstLogoUrl,
  [SupportedAssets.JEUR]: jeurLogoUrl,
  [SupportedAssets.SUSHI]: sushiLogoUrl,
  [SupportedAssets.UNI]: uniLogoUrl,
  [SupportedAssets.ETH]: ethLogoUrl,
  [SupportedAssets.MATIC]: wmaticLogoUrl,
  [SupportedAssets.COMP]: compLogoUrl,
  [SupportedAssets.BAT]: batLogoUrl,
  [SupportedAssets.FEI]: feiLogoUrl,
  [SupportedAssets.MKR]: mkrLogoUrl,
  [SupportedAssets.ZRX]: zrxLogoUrl,
  [SupportedAssets.YFI]: yfiLogoUrl,
  [SupportedAssets.WBTC2]: wbtcLogoUrl,
  [SupportedAssets.USDP]: usdpLogoUrl,
  [SupportedAssets.TUSD]: tusdLogoUrl,
  [SupportedAssets.SAI]: saiLogoUrl,
  [SupportedAssets.REP]: repLogoUrl,
  [SupportedAssets.MATICX]: maticxLogoUrl,
  [SupportedAssets.MAI]: mimaticLogoUrl,
  MIMATIC: mimaticLogoUrl,
  [SupportedAssets.STMATIC]: stmaticLogoUrl,
  [SupportedAssets.VGHST]: vghstLogoUrl,
  [SupportedAssets.GDAI]: gdaiLogoUrl,
  [SupportedAssets.WSTETH]: wstethLogoUrl,
  [SupportedAssets.GHO]: ghoLogoUrl,
  // venus assets
  [SupportedAssets.ADA]: adaLogoUrl,
  [SupportedAssets.BCH]: bchLogoUrl,
  [SupportedAssets.BETH]: bethLogoUrl,
  [SupportedAssets.BTCB]: btcbLogoUrl,
  [SupportedAssets.BUSD]: busdLogoUrl,
  [SupportedAssets.CAKE]: cakeLogoUrl,
  [SupportedAssets.CAN]: "",
  [SupportedAssets.DOGE]: dogeLogoUrl,
  [SupportedAssets.DOT]: dotLogoUrl,
  [SupportedAssets.FIL]: filLogoUrl,
  [SupportedAssets.LTC]: ltcLogoUrl,
  [SupportedAssets.LUNA]: lunaLogoUrl,
  [SupportedAssets.SXP]: sxpLogoUrl,
  [SupportedAssets.TRX]: trxLogoUrl,
  [SupportedAssets.TRXOLD]: trxLogoUrl,
  [SupportedAssets.TUSDOLD]: tusdLogoUrl,
  [SupportedAssets.UST]: ustLogoUrl,
  [SupportedAssets.VAI]: vaiLogoUrl,
  [SupportedAssets.VRT]: vrtLogoUrl,
  [SupportedAssets.WBETH]: wbethLogoUrl,
  [SupportedAssets.XRP]: xrpLogoUrl,
  [SupportedAssets.XVS]: xvsLogoUrl,
  [SupportedAssets.BNB]: bnbLogoUrl,
  [SupportedAssets.WBNB]: bnbLogoUrl,
  [SupportedAssets.BUSDC]: usdcLogoUrl,
  [SupportedAssets.BUSDT]: usdtLogoUrl,
  [SupportedAssets.WMNT]: mntLogoUrl,
  [SupportedAssets.MNT]: mntLogoUrl,
  [SupportedAssets.METH]: methLogoUrl,
  LEND: lendLogoUrl,
};

export const EMODE_DEFAULT_SVG = eDefault;

export const EMODE_SVGS: { [chainId: number]: { [eMode: number]: string } } = {
  // [SupportedChainId.POLYGON]: {
  //   0: deactivated,
  //   1: eStable,
  //   2: eMatic,
  //   3: eEth,
  // },
};

export const getEmodeSvg = (
  chainId: number,
  id: number,
  lender = Lender.INIT
) => {
  return EMODE_SVGS[chainId]?.[id] ?? EMODE_DEFAULT_SVG;
};

export const VENUS_SVG = xvsLogoUrl;

export const AAVE_SVG = aave;

export const LENDLE_SVG = lendle;

export const COMPOUNDV3_SVG = compoundV3;

export const LOGO_ACCOUNT_SVG = fusionxAccount;

export const AAVE_SVG_STANDALONE = aaveStandalone;

export const OVIX_SVG_STANDALONE = ovixStandalone;

export const COMPOUNDV3_SVG_STANDALONE = compoundV3Standalone;

export const COMPOUNDV2_SVG_STANDALONE = compoundStandalone;

export const INIT_SVG = initSVG;

export const getTokenIcon = (asset: SupportedAssets | string): string => {
  return (
    TOKEN_SVGS[String(asset).toLocaleUpperCase()] || TOKEN_SVGS[String(asset)]
  );
};

export const getVenusCoreAssets = () => {
  return [
    SupportedAssets.AAVE,
    SupportedAssets.ADA,
    SupportedAssets.BCH,
    SupportedAssets.BNB,
    SupportedAssets.BETH,
    SupportedAssets.BTCB,
    SupportedAssets.BUSD,
    SupportedAssets.CAKE,
    SupportedAssets.DAI,
    SupportedAssets.DOGE,
    SupportedAssets.DOT,
    SupportedAssets.WETH,
    SupportedAssets.FIL,
    SupportedAssets.LINK,
    SupportedAssets.LTC,
    SupportedAssets.LUNA,
    SupportedAssets.WMATIC,
    SupportedAssets.SXP,
    SupportedAssets.TRX,
    SupportedAssets.TRXOLD,
    SupportedAssets.TUSD,
    SupportedAssets.TUSDOLD,
    SupportedAssets.BUSDC,
    SupportedAssets.BUSDT,
    SupportedAssets.UST,
    SupportedAssets.WBETH,
    SupportedAssets.XRP,
    SupportedAssets.XVS,
  ];
};

export const GREEKS = [
  "alpha",
  "beta",
  "gamma",
  "Fusionx",
  "fusionx",
  "epsilon",
  "zeta",
  "eta",
  "Theta",
  "theta",
  "iota",
  "kappa",
  "Lambda",
  "lambda",
  "mu",
  "nu",
  "Xi",
  "xi",
  "Pi",
  "pi",
  "varpi",
  "Sigma",
  "sigma",
  "tau",
  "Phi",
  "phi",
  "varphi",
  "chi",
  "Psi",
  "psi",
  "Omega",
  "omega",
];

export const WRAPPED_NATIVE_SYMBOL: { [key: number]: string } = {
  [SupportedChainId.MANTLE]: "WMNT",
};

export const NATIVE_SYMBOL: { [key: number]: string } = {
  [SupportedChainId.MANTLE]: "MNT",
};

export const COMET_MARKET_MAPPING: {
  [chainId: number]: { [asset: string]: SupportedAssets[] };
} = {};

/**
 * Get the lender text for the input mask
 * @param lender lender enum
 * @param chainId chainId
 * @returns the name of the correct lender
 */
export const toLenderText = (lender: Lender) => {
  return lender;
};

/**
 * Get the short lender text for the input mask
 * @param lender lender enum
 * @param chainId chainId
 * @returns the name of the correct lender
 */
export const toLenderTextAbbreviated = (lender: Lender) => {
  return lender;
};

export enum PositionType {
  LONG = "Long",
  SHORT = "Short",
  SHORT_STABLE = "Short Stable",
}

export const PosTypeMap: { [p in PositionType]: AaveInterestMode } = {
  [PositionType.LONG]: AaveInterestMode.NONE,
  [PositionType.SHORT]: AaveInterestMode.VARIABLE,
  [PositionType.SHORT_STABLE]: AaveInterestMode.STABLE,
};

/**
 * Get the reward logo for lender on chain
 * @param chainId network enum
 * @param lender lender enum
 * @returns logo svg
 */
export const getRewardLogo = (lender: Lender) => {
  return compLogoUrl;
};
