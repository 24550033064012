import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { ThemedText } from "theme";

interface LogoProps extends HTMLAttributes<HTMLImageElement> {
  size?: string;
}

const Row = styled.div`
  width: fit-content !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify: center;
  gap: 3px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      display: flex !important;
  `};
`;

const ResponsiveLogo = styled.img<{ size?: string }>`
  width: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  height: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  min-width: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  min-height: ${({ size }) => (size ? `calc(${size} + 20px)` : "56px")};
  max-width: auto;
`;

const GradientSpanText = styled.span`
  background: var(--g-1, linear-gradient(180deg, #fab823 0%, #ec630f 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: normal;
`;

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  size,
  ...rest
}) => {
  return (
    <Row {...rest}>
      <ResponsiveLogo
        src="https://fusionx.finance/logo.png"
        size={size}
        style={{ maxWidth: "auto" }}
      />
      <ThemedText.Label
        textAlign="center"
        fontSize={size ?? "32px"}
        fontWeight={600}
        color="#FFFFFF"
      >
        Fusion<GradientSpanText>X</GradientSpanText>
      </ThemedText.Label>
    </Row>
  );
};

export default Logo;
