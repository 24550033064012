import { transparentize } from "polished";
import { ReactNode, useCallback, useState } from "react";
import styled from "styled-components";

import Popover, { PopoverProps } from "../Popover";

export const TooltipContainer = styled.div`
  max-width: 300px;
  padding: 0.6rem 1rem;
  font-weight: 400;
  word-break: break-word;

  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundTertiary};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow)};
`;

interface TooltipProps extends Omit<PopoverProps, "content"> {
  text: ReactNode;
  disableHover?: boolean; // disable the hover and content display
  style?: React.CSSProperties;
  refStyle?: React.CSSProperties;
}

interface TooltipContentProps extends Omit<PopoverProps, "content"> {
  content: ReactNode;
  onOpen?: () => void;
  // whether to wrap the content in a `TooltipContainer`
  wrap?: boolean;
  disableHover?: boolean; // disable the hover and content display
  style?: React.CSSProperties;
}

export default function Tooltip({ text, refStyle, ...rest }: TooltipProps) {
  return (
    <Popover
      content={text && <TooltipContainer>{text}</TooltipContainer>}
      refStyle={refStyle}
      {...rest}
    />
  );
}

function TooltipContent({
  content,
  refStyle,
  wrap = false,
  ...rest
}: TooltipContentProps) {
  return (
    <Popover
      content={wrap ? <TooltipContainer>{content}</TooltipContainer> : content}
      refStyle={refStyle}
      {...rest}
    />
  );
}

/** Standard text tooltip. */
export function MouseoverTooltip({
  text,
  disableHover,
  children,
  style,
  refStyle,
  ...rest
}: Omit<TooltipProps, "show">) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <Tooltip
      {...rest}
      show={show}
      text={disableHover ? null : text}
      refStyle={refStyle}
    >
      <div
        onMouseEnter={open}
        onMouseLeave={close}
        style={{
          cursor: "default",
          ...style,
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}

/** Tooltip that displays custom content. */
export function MouseoverTooltipContent({
  content,
  children,
  onOpen: openCallback = undefined,
  disableHover,
  style,
  refStyle,
  ...rest
}: Omit<TooltipContentProps, "show">) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => {
    setShow(true);
    openCallback?.();
  }, [openCallback]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <TooltipContent
      {...rest}
      show={show}
      content={disableHover ? null : content}
      refStyle={refStyle}
    >
      <div
        style={{
          cursor: "default",
          ...style,
        }}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        {children}
      </div>
    </TooltipContent>
  );
}
