import {
  Route as OffChainRoute,
  Route as OnChainRoute,
  Trade,
} from "@fusionx-finance/v3-sdk";
import {
  Currency,
  CurrencyAmount,
  Token,
  TradeType,
} from "@fusionx-finance/sdk";

export enum TradeState {
  LOADING,
  INVALID,
  NO_ROUTE_FOUND,
  VALID,
}

// from https://github.com/Uniswap/routing-api/blob/main/lib/handlers/schema.ts

type TokenInRoute = Pick<Token, "address" | "chainId" | "symbol" | "decimals">;

export type OnChainQuotablePoolInRoute = {
  type: "on-chain-quote-pool";
  tokenIn: TokenInRoute;
  tokenOut: TokenInRoute;
  sqrtRatioX96: string;
  liquidity: string;
  tickCurrent: string;
  protocol?: string;
  fee: string;
  amountIn?: string;
  amountOut?: string;

  // not used in the interface
  address?: string;
};

export type MultiAssetPoolInRoute = {
  type: "multi-asset-pool";
  address: string;
  tokenIn: TokenInRoute;
  tokenOut: TokenInRoute;
  fee: string;
  protocol: string;
  amountIn?: string;
  amountOut?: string;
};

type V2Reserve = {
  token: TokenInRoute;
  quotient: string;
};

export type OffChainQuotablePoolInRoute = {
  type: "off-chain-quote-pool";
  tokenIn: TokenInRoute;
  tokenOut: TokenInRoute;
  protocol?: string;
  reserve0: V2Reserve;
  reserve1: V2Reserve;
  amountIn?: string;
  amountOut?: string;
  fee?: string;
  // not used in the interface
  // avoid returning it from the client-side smart-order-router
  address?: string;
};

export interface QuoteData {
  quoteId?: string;
  blockNumber: string;
  amount: string;
  amountDecimals: string;
  gasPriceWei: string;
  gasUseEstimate: string;
  gasUseEstimateQuote: string;
  gasUseEstimateQuoteDecimals: string;
  gasUseEstimateUSD: string;
  methodParameters?: { calldata: string; value: string };
  quote: string;
  quoteDecimals: string;
  quoteGasAdjusted: string;
  quoteGasAdjustedDecimals: string;
  route: Array<
    (
      | OnChainQuotablePoolInRoute
      | OffChainQuotablePoolInRoute
      | MultiAssetPoolInRoute
    )[]
  >;
  routeString: string;
}

export class ClassicTrade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType
> extends Trade<TInput, TOutput, TTradeType> {
  gasUseEstimateUSD: string | null | undefined;
  blockNumber: string | null | undefined;
  stringified: string | null | undefined;
  tradeType: TradeType;
  constructor({
    gasUseEstimateUSD,
    blockNumber,
    stringified,
    offChainRoutes,
    tradeType,
    onChainRoutes,
  }: {
    offChainRoutes: {
      route: OffChainRoute<TInput, TOutput>;
      inputAmount: CurrencyAmount<TInput>;
      outputAmount: CurrencyAmount<TOutput>;
    }[];
    tradeType: TTradeType;
    onChainRoutes: {
      route: OnChainRoute<TInput, TOutput>;
      inputAmount: CurrencyAmount<TInput>;
      outputAmount: CurrencyAmount<TOutput>;
    }[];
    gasUseEstimateUSD: string | null | undefined;
    blockNumber: string | null | undefined;
    stringified: string | null | undefined;
  }) {
    super({ offChainRoutes, onChainRoutes, tradeType });
    this.blockNumber = blockNumber;
    this.gasUseEstimateUSD = gasUseEstimateUSD;
    this.stringified = stringified;
  }
}

export type InterfaceTrade = ClassicTrade<Currency, Currency, TradeType>;

export enum QuoteState {
  SUCCESS = "Success",
  NOT_FOUND = "Not found",
}

export type QuoteResult =
  | {
      state: QuoteState.NOT_FOUND;
      data?: undefined;
    }
  | {
      state: QuoteState.SUCCESS;
      data: QuoteData;
    };

export type TradeResult =
  | {
      state: QuoteState.NOT_FOUND;
      trade?: undefined;
    }
  | {
      state: QuoteState.SUCCESS;
      trade: InterfaceTrade;
    };

export enum PoolType {
  OffChainPool = "off-chain-quote-pool",
  OnChainPool = "on-chain-quote-pool",
  MultiAssetPool = "multi-asset-pool",
}

// swap router API special cases these strings to represent native currencies
// all chains have "ETH" as native currency symbol except for polygon
export enum SwapRouterNativeAssets {
  MATIC = "MATIC",
  ETH = "ETH",
  MNT = "MNT",
}

export interface GetQuoteArgs {
  amount: string;
  tokenInAddress: string;
  tokenInChainId: number;
  tokenInDecimals: number;
  tokenInSymbol: string;
  tokenOutAddress: string;
  tokenOutChainId: number;
  tokenOutDecimals: number;
  tokenOutSymbol: string;
  routerPreference: string;
  tradeType: TradeType;
  flashSwap: boolean;
}
